
export const AV_PROD_BIN_HEADER_LENGTH: number = 22;
export const AV_PROD_FRAME_ID_OUPUT_OFFSET: number = 32000;
export const AV_PROD_URL_TYPE_JPEG: string = 'image/jpeg';

export const AV_PROD_INPUT_LIST_MODE_MAIN: string = 'main';
export const AV_PROD_INPUT_LIST_MODE_ALL: string = 'all';

export const AV_PROD_WEBRTC_MAX_INPUTS: number = 16;
export const AV_PROD_WEBRTC_MAX_OUTPUTS: number = 1;

export const AV_PROD_SUBSCRIPTIONS_BASIC: string[] = [
//  'input/#/frame',
//  'input/#/audiolevel',
  'input/#/settings',
  'input/#/status',
//  'overlay/#/settings',
//  'output/#/frame',
//  'output/#/audiolevel',
  'composer/#/settings',
  'composer/#/status',
  'output/#/settings',
  'server/#/status',
  'server/#/components',
  'interface/#/status',
  'layoutmanager/1/settings',
  'msg/1/status'
//  'output/#/audio'
]

export const AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL: string[] = [
    'input/#/audiolevel',
    'output/0/audiolevel',
]

export const AV_PROD_INITIAL_REQUESTS: string[] = [
  'server/1/components',
  'server/1/settings',
  'server/1/status',
  'layoutmanager/1/settings',
  'composer/1/options',
  'composer/1/settings',
  'composer/1/status',
  'output/1/settings',
//  'input/0/settings',
//  'input/1/settings',
//  'input/2/settings',
//  'input/3/settings',
//  'input/0/status',
//  'input/1/status',
//  'input/2/status',
//  'input/3/status',
  'interface/1/status',
  'msg/1/status'
]

export enum AvProdToastKeys {
  homePage = 'HomePage',
  producerPage = 'ProducerPage',
  publisherPage = 'PublisherPage',
  watchPage = 'WatchPage',
  eventCreationPage = 'EventCreatePage',
  layout = 'Layout'
}

export enum AvProdDeviceType {
  none = '',
  server = 'server',
  input = 'input',
  output = 'output',
  composer = 'composer',
  interface = 'interface',
  layoutmanager = 'layoutmanager',
  overlay = 'overlay',
  overlayTemplate = 'overlayTemplate',
  client = 'client',
  msg = 'msg'
}

export enum AvProdItemSection {
  none = '',
  settings = 'settings',
  status = 'status',
  components = 'components',
  audiolevel = 'audiolevel',
  client = 'client',
  commands = 'commands',
  options = 'options',
  publish = 'publish'
}

export enum AvProdStatus {
  notinitialized = 'notinitialized',
  closed = 'closed',
  connecting = 'connecting',
  connected = 'connected',
  receiving = 'receiving',
  closing = 'closing',
  error = 'error',
  destroyed = 'destroyed'
}

export enum AvProdRequests {
  hello = 'hello',
  helloSync = 'helloSync',
  bye = 'bye',
  ping = 'ping',
  get = 'get',
  set = 'set',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  command = 'command',
  publish = 'publish'
}

export enum AvProdInputTypeNumber {
  none = 0,
  videoStream = 1,
  audioStream = 2,
  videoAudioStream = 3,
  videoClip = 4,
  audioClip = 5,
  videoAudioClip = 6,
  imageTile = 7,
  highlight = 8,
  document = 9,
  imageOverlay = 10,
  profilePicture = 11,
  profileBanner = 12,
}

export enum AvProdInputType {
  none = '',
  videoStream = 'VideoStream',
  audioStream = 'AudioStream',
  videoAudioStream = 'VideoAudioStream',
  videoClip = 'VideoClip',
  audioClip = 'AudioClip',
  videoAudioClip = 'VideoAudioClip',
  imageTile = 'ImageTile',
  highlight = 'Highlight',
  document = 'Document',
  imageOverlay = 'ImageOverlay',
  profilePicture = 'ProfilePicture',
  profileBanner = 'ProfileBanner',
}

export enum AvProdInputPlayingState {
  none = 0,
  stopped = 1,
  playing = 2,
  paused = 3,
  finished = 4
}

export enum AvProdInputPlayingMode {
  normal = 0,
  loop = 1
}

export enum AvProdMsgAsciiType {
  request = 'R',
  answer = 'A',
  notification = 'N'
}

export enum AvProdMsgBinType {
  frame = 'f',    // Frame: Raw video frame
  audioraw = 'a',    // Audio: Raw audio data
  media = 'm',    // Media: Encoded video and audio data
  video = 'v',    // Video: Encoded video data
  audioencoded = 's',  // Sound: Encoded audio data
  audiocancel = 'c',  // Audio cancelled: Ouput raw audio with cancelled input
}

export enum AvProdMsgAsciiRequest {
  get = 'get',
  set = 'set',
  command = 'command',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  hello = 'hello',
  helloSync = 'helloSync',
  ping = 'ping',
  bye = 'bye',
  publish = 'publish',
  notification = 'notification'
}

export enum AvProdClientType {
  none = 0,           // None or not initialized yet
  publisher = 1,      //
  producer = 2,       //
  assistantScore = 3, //
  assistantOsd = 4,   //
  assistantAudio = 5, //
  viewer = 6,          //
  internal = 7          //
}

export enum AvProdClientDeviceType {
  none = 0,           // None or not initialized yet
  ipad = 1,
  tablet = 2,
  iphone = 3,
  phone = 4,
  web = 5,
  webphone = 6,
  webtablet = 7,
  webipad = 8,
  internal = 9
}

export enum AvProdTileSelectionState {
  idle = 'none',
  selecting = 'selecting',
  finished = 'finished',
  cancelled = 'cancelled'
}

export enum AvProdTileSelectionAction {
  start = 'start',
  stop = 'stop'
}

export enum AvProdSettingsMenuItem {
  none = 'None',
  video = 'Video',
  audio = 'Audio',
  overlay = 'Overlay',
  highlights = 'Highlights',
  output = 'Output',
  configuration = 'Configuration'
}

export enum AvProdSettingsType {
  none = 'none',
  switchBoolean = 'switchBoolean',
  //switchNumber = 'switchNumber',
  selectButton = 'selectButton',
  selectComboNumber = 'selectComboNumber',
  selectComboString = 'selectComboString',
  check = 'check',
  radio = 'radio',
  text = 'text',
  textArea = 'textArea',
  password = 'password',
  numberText = 'numberText',
  numberButtons = 'numberButtons',
  numberSlider = 'numberSlider',
  numberKnob = 'numberKnob',
  calendar = 'calendar',
  color = 'color'
}

export enum AvProdInputSourcePushProtocol {
  none = 0,
  rtmp = 1,
  srt = 2
}

export enum AvProdInputSourceType {
  none = 0,
  native = 1,
  pull = 2,
  push = 3
}

export enum AvProdInputTileFillMode {
  fill = 0,
  fit = 1
}

export enum AvProducerModeType {
  full = 'full',
  overlay = 'overlay',
  audio = 'audio',
  highlights = 'highlights'
}

export enum AvProdLowBandwidthConfig {
  high = 0,
  medium = 1,
  low = 2,
  verylow = 3,
  byRequest = 4
}

export enum AvProdOverlayHtmlProviderType {
  custom = 0,
  singular = 1,
  flexyscore = 2
}

export enum AvProdOverlayType {
  none = 0,
  template = 1,
  browser = 2
}

export enum AvProdOverlayItemType {
  none = 0,
  group = 1,
  htmlGraphics = 2,
  image = 3,
  score = 4,
  dateTime = 5,
  chrono = 6,
  label = 7,
  ticker = 8
}

export enum AvProdOverlaySettingsSizeMode {
  none = 0,
  screenPercentage = 1,
  scaleOrigin = 2
}

export enum AvProdWebRtcConnectionType {
  none = 0,
  viewer = 1,
  broadcaster = 2,
}

export enum AvProdVideoLayoutModeType {
  none = 0,
  manual = 1,
  auto = 2,
}

export enum AvProdOverlayScoreSetsMode {
  none = 0,
  global = 1,
  all = 2,
}

export enum AvProdOverlayScoreSport {
  generic = 0,
  soccer = 1,
  basketball = 2,
  tennis = 3,
  volleyball = 4,
  football = 5,
  baseball = 6,
  hockey = 7,
  paddle = 8,
  badminton = 9,
  softball = 10,
}

export enum AvProdConnectionStatus {
  none = 0,
  ok = 1,
  commsError = 2,
  tooManyClients = 3,
  eventNotReady = 4,
  eventFinished = 5
}