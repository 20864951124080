import { Subscription } from 'rxjs';

import { IUserOrganizationInfo } from '../interfaces/user/user.interface';
import { UserService } from '../services/user/user.service';


export class OrganizationSelectClass {
  protected orgList: IUserOrganizationInfo[] = [];
  protected orgId: number = 0;
  protected userSubscription: Subscription | undefined;
  protected userOrgSubscription: Subscription | undefined;

  //select style
  protected backgroundColor: string = '';
  protected color: string = '';

  constructor(protected userService: UserService) {}

  protected init() {
    this.updateUserOrganizationList();
    this.updateSelectStyle();
    this.userSubscription?.unsubscribe();
    this.userSubscription = this.userService.user$.subscribe(() =>
      this.updateUserOrganizationList()
    );
    this.userOrgSubscription?.unsubscribe();
    this.userOrgSubscription = this.userService.orgId$.subscribe(() =>
      this.updateUserOrganizationList()
    );
  }

  protected close(): void {
    this.userOrgSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  protected navigateToManager(): void {
    // Must override
  }

  protected updateSelectStyle(): void {
    if (this.orgId !== null) {
      switch (this.orgId) {
        case 0:
          this.backgroundColor = 'var(--bs-white)';
          this.color = 'var(--bs-dark)';
          console.log(
            '[ORGANIZATION SELECT COMPONENT] updateSelectStyle - Case 1'
          );
          break;

        default:
          this.backgroundColor = 'var(--bs-dark)';
          this.color = 'var(--bs-light)';
          console.log(
            '[ORGANIZATION SELECT COMPONENT] updateSelectStyle - Case default'
          );
          break;
      }
    }
  }

  protected updateUserOrganizationList() {
    this.orgId = this.userService.orgId;
    console.log(
      '[OrganizationSelectClass] updateUserOrganizationList' +
        JSON.stringify(this.userService.user)
    );
    if (this.userService.user.organizations !== undefined) {
      this.orgList = this.userService.user.organizations;
    } else {
      this.orgList = [];
    }
  }

  protected onProfileChange(event: any): void {
    console.log('[OrganizationSelectClass] onProfileChange ' + this.orgId);
    if (this.userService.orgId !== this.orgId) {
      this.userService.orgId = this.orgId;
      this.updateSelectStyle();
      // Reload manager
      this.navigateToManager();
    }
  }
}
