import { TranslateService } from '@ngx-translate/core';
import { Subscription, first } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EventStatusLayoutMode } from '../../enums/utils.enum';
import { IEvent, IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { EVENTS } from '../../const/events.const';
import { UserService } from '../../services/user/user.service';
import { EventsService } from '../../services/events/events.service';


export class EventStatusListClass {
  protected events: IEvent[] = [];
  protected eventsReceived: boolean = false;
  protected _layoutMode: EventStatusLayoutMode = EventStatusLayoutMode.list;
  protected _onlyActiveEvents: boolean = false;
  protected _maxEvents: number = 100;
  protected userInitializedSubscription: Subscription | undefined;
  protected userChangesSubscription: Subscription | undefined;
  protected userOrgIdSubscription: Subscription | undefined;

  protected _userStreamingTime: number = -1;
  protected _streamingTimeExceeded: boolean = false;

  protected setOnlyActiveEvents(param: boolean): void {
    this._onlyActiveEvents = param;
    this.readEvents();
  }

  protected setMaxEvents(param: number): void {
    this._maxEvents = param;
    this.readEvents();
  }

  protected setLayoutMode(value: EventStatusLayoutMode): void {
    this._layoutMode = value;
  }

  constructor(protected users: UserService,
              protected eventsService: EventsService,
              protected translate: TranslateService) { }

  init(): void {
    console.log('[EventStatusListClass] init');
    this.userInitializedSubscription?.unsubscribe();
    this.userInitializedSubscription = this.users.onInitialized$.subscribe(() => this.initialize());
    this.userOrgIdSubscription?.unsubscribe();
    this.userOrgIdSubscription = this.users.orgId$.subscribe(() => this.initialize());
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.eventsService.userChanges$.subscribe(data => this.checkUserChanges(data));
    this.initialize();
    this.eventsService.addPollingClient('EventStatusListClass');
    console.log('[EventStatusListClass] init END');
  }

  destroy(): void {
    if (this.userInitializedSubscription !== undefined) {
      this.userInitializedSubscription.unsubscribe();
    }
    this.userOrgIdSubscription?.unsubscribe();
    this.userChangesSubscription?.unsubscribe();
    this.eventsService.removePollingClient('EventStatusListClass');
    console.log('[EventStatusListClass] destroy');
  }

  protected initialize(): void {
    //if ((this._initialized === false)&&(this.users.isInitialized === true)) {
    if (this.users.isInitialized) {
      this.readEvents();
      this.updateStreamingTime();
      //this._initialized = true;
    }
  }

  protected checkUserChanges(data: IEventUserLastChanges): void {
    console.log('[EventStatusListClass] checkUserChanges');
    if (data.eventList === true){
      this.readEvents();
    }
    else if (data.events !== undefined) {
      data.events.forEach((id: number) => {
        let event: IEvent | undefined = this.events.find(element => element.id === id)
        // if ((event !== undefined)&&(event.producerToken !== undefined)){
        //   this.eventsService.getEventInfo('producer', event.producerToken)
        if ((event !== undefined)&&(event.id !== undefined)){
          this.eventsService.getEvent(event.id)
            .subscribe((eventRefreshed: IEvent | null) => {
              if ((eventRefreshed !== undefined)&&(event !== undefined)) {
                Object.assign(event, eventRefreshed);
              }
            });
        }
      });
    }
    this.updateStreamingTime();
  }

  protected updateStreamingTime(): void {
    if (this.users.user?.streamingTime !== undefined && this.users.user?.plan?.maxStreamingTime !== undefined){
      this._userStreamingTime = this.users.user.streamingTime;
      this._streamingTimeExceeded = (this._userStreamingTime >= this.users.user.plan?.maxStreamingTime);
    }
  }

  protected readEvents(): void {
    console.log('[EventStatusListClass] readEvents');
    if (this.users.user?.id !== undefined) {
      this.eventsService.getUserEvents(this.users.orgId)
        .pipe(
          first(),
          tap((eventList: IEvent[]) => {
            if (this._onlyActiveEvents) {
              this.events = eventList.filter( (event: IEvent) => event.status === EVENTS.status.running);
            } else {
              this.events = eventList;
            }
            this.events = this.events.slice(0, this._maxEvents);
          }))
        .subscribe(() => {
          this.eventsReceived = true;
        });
    }
  }

  protected onEventDeleted(event: IEvent): void {
    console.log('[EventStatusListClass] Event deleted. Refresh all');
    this.readEvents();
  }
}
