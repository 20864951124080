import { ITopBar } from '../interfaces/web.interface';

export const VERSION_NAME: string = '2.1.15-2';
export const VERSION_CODE: number = 2011502;
export const COOKIE_EXP_DAYS: number = 365;
export const DEFAULT_TOP_BAR: ITopBar = {
  mode: 'primary',
  fluid: false,
  borderBottom: false,
  scrollValue: 860
}

export const APP = {
  key: 'w',

  logoPath: '/assets/shared/img/logo/',

  storageKeys: {
    cookieConsent: '_cookieConsentStatus',
    appSuggested: '_appSuggested'
  },
  fragments: {
    home: 'home',
    useCases: 'useCases',
    howItWorks: 'howItWorks',
    features: 'features',
    prices: 'pricing',
    contact: 'contact',
    userPanel: '#userPanel',
    tutorials: 'tutorials'
  },
  metaInfo: {
    aboutUs: {
      title: 'metaInfo.aboutUs.title',
      description: 'metaInfo.aboutUs.description',
      canonical: 'metaInfo.aboutUs.canonical'
    },
    blog: {
      title: 'metaInfo.blog.title',
      description: 'metaInfo.blog.description',
      canonical: 'metaInfo.blog.canonical'
    },
    contact: {
      title: 'metaInfo.contact.title',
      description: 'metaInfo.contact.description',
      canonical: 'metaInfo.contact.canonical'
    },
    cookiesPolicy: {
      title: 'metaInfo.cookiesPolicy.title',
      description: 'metaInfo.cookiesPolicy.description',
      canonical: 'metaInfo.cookiesPolicy.canonical'
    },
    developers: {
      title: 'metaInfo.developers.title',
      description: 'metaInfo.developers.description',
      canonical: 'metaInfo.developers.canonical'
    },
    features: {
      title: 'metaInfo.features.title',
      description: 'metaInfo.features.description',
      canonical: 'metaInfo.features.canonical'
    },
    helpCenter: {
      title: 'metaInfo.helpCenter.title',
      description: 'metaInfo.helpCenter.description',
      canonical: 'metaInfo.helpCenter.canonical'
    },
    home: {
      title: 'metaInfo.home.title',
      description: 'metaInfo.home.description',
      canonical: 'metaInfo.home.canonical'
    },
    howItWorks: {
      title: 'metaInfo.howItWorks.title',
      description: 'metaInfo.howItWorks.description',
      canonical: 'metaInfo.howItWorks.canonical'
    },
    liveInput: {
      title: 'metaInfo.liveInput.title',
      description: 'metaInfo.liveInput.description',
      canonical: 'metaInfo.liveInput.canonical'
    },
    manager: {
      title: 'metaInfo.manager.title',
      description: 'metaInfo.manager.description',
      canonical: 'metaInfo.manager.canonical'
    },
    prices: {
      title: 'metaInfo.prices.title',
      description: 'metaInfo.prices.description',
      canonical: 'metaInfo.prices.canonical'
    },
    privacyPolicy: {
      title: 'metaInfo.privacyPolicy.title',
      description: 'metaInfo.privacyPolicy.description',
      canonical: 'metaInfo.privacyPolicy.canonical'
    },
    studio: {
      title: 'metaInfo.studio.title',
      description: 'metaInfo.studio.description',
      canonical: 'metaInfo.studio.canonical'
    },
    termsAndConditions: {
      title: 'metaInfo.termsAndConditions.title',
      description: 'metaInfo.termsAndConditions.description',
      canonical: 'metaInfo.termsAndConditions.canonical'
    },
    useCases: {
      title: 'metaInfo.useCases.title',
      description: 'metaInfo.useCases.description',
      canonical: 'metaInfo.useCases.canonical'
    },
    watch: {
      title: 'metaInfo.watch.title',
      description: 'metaInfo.watch.description',
      canonical: 'metaInfo.watch.canonical'
    },
  },
  // skipSectionsFromChangeLangRedirection
  skipSections: [
    'manager',
    'studio',
    'liveinput',
    'watch'
  ],
  nativeMarket: {
    android: 'https://play.google.com/store/apps/details?id=com.azzulei.azztv',
    ios: 'https://apps.apple.com/en/app/azzuleitv/id1617642941'
  },
}
