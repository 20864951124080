import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  interval,
  map,
  Observable,
  partition,
  Subject,
  Subscription,
} from 'rxjs';

import {
  AvProdClientDeviceType,
  AvProdClientType,
  AvProdDeviceType,
  AvProdItemSection,
  AvProdLowBandwidthConfig,
  AvProdMsgAsciiRequest,
  AvProdMsgAsciiType,
  AvProdMsgBinType,
  AvProdRequests,
  AvProdStatus,
  AvProdWebRtcConnectionType,
  AV_PROD_BIN_HEADER_LENGTH,
  AV_PROD_FRAME_ID_OUPUT_OFFSET,
  AV_PROD_INITIAL_REQUESTS,
  AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL,
  AV_PROD_SUBSCRIPTIONS_BASIC,
  AV_PROD_URL_TYPE_JPEG,
  AvProdInputType
} from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_FONTS, AV_PROD_OPTIONS_LIST } from '../../const/av-producer-options';
import { WS, WSStatus } from '../../const/web-socket.const';
import { IAvProdComponents } from '../../interfaces/av-producer/components.interface';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';
import {
  IAvAudioLevel,
  IAvClientInfo,
  IAvMsgAscii,
  IAvMsgAsciiCommand,
  IAvMsgBin,
  IAvMsgDataHello,
  IAvMsgDataHelloAns,
  IAvMsgDataHelloSync,
  IAvMsgDataHelloSyncAns,
  IAvMsgDataPing,
  IAvMsgDataSubscription,
  IAvMsgItem,
  IAvOnNewData,
  IAvProdCommsStatus,
  IAvProdInput,
  IAvProdInputDropInfo,
  IAvProdInputTileSwapInfo,
  IAvProdMessagingStatus,
  IAvProdOverlay,
  IAvProdWebRtcInputData,
  IAvProdWebRtcOutputData,
  IAvProdWebRtcViewerData,
  IAvSettingSelectOption
} from '../../interfaces/av-producer/event-av-producer.interface';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import { IAvProdInputStatus } from '../../interfaces/av-producer/input-status.interface';
import { IAvProdInterfaceClientDeviceStatus, IAvProdInterfaceClientStatus } from '../../interfaces/av-producer/interface-client-status.interface';
import {
  IAvProdInterfaceStatus,
  IAvProdInterfaceStatusPublish
} from '../../interfaces/av-producer/interface-status.interface';
import { IAvProdLayoutMngrSettings, IAvProdVideoLayout } from '../../interfaces/av-producer/layoutmanager-settings.interface';
import { IAvProdOutputSettings } from '../../interfaces/av-producer/output-settings.interface';
import { IAvProdOverlaySettings } from '../../interfaces/av-producer/overlay-settings.interface';
import { IAvProdComposerStatus } from '../../interfaces/av-producer/composer-status.interface';
import { AvProdServerStatusDefault, IAvProdServerStatus } from '../../interfaces/av-producer/server-status.interface';
import { IAvProdServerSettings } from '../../interfaces/av-producer/server-settings.interface';
import { IEvent } from '../../interfaces/events/event.interface';
import { DeviceService } from '../device/device.service';
import { UserService } from '../user/user.service';
import { WebSocketService } from '../web-socket/web-socket.service';


@Injectable({
  providedIn: 'root'
})
export class AvProducerService {

  public commsStatus: IAvProdCommsStatus = {
    ok: false,
    status: AvProdStatus.notinitialized,
    stats: {
      rxBytes: 0,
      rxRate: 0,
      rxRateStr: '0',
      txBytes: 0,
      txRate: 0,
      txRateStr: '0',
      lastTimestamp: 0
    },
    videoReceptionStatus: {
      lastDelays: [],
      lastRxTimestamp: 0,
      lastRxTimestampMsg: 0
    },
    lowBandwidthConfig: AvProdLowBandwidthConfig.medium,
    lowBandwidthStatus: AvProdLowBandwidthConfig.medium,
    lowBandwidthStatusTS: 0,
    lowBandwidthServerRestarting: false
  }
  public inputs: IAvProdInput[] = [];
  public overlays: IAvProdOverlay[] = [];
  public layoutManager: IAvProdLayoutMngrSettings = {videoLayouts: [], favorites: []};
  public composerSettings: IAvProdComposerSettings = {}
  public composerStatus: IAvProdComposerStatus = {}
  public serverStatus: IAvProdServerStatus = AvProdServerStatusDefault;
  public serverSettings: IAvProdServerSettings | undefined;
  public outputSettings: IAvProdOutputSettings = {};
  public messagingChatStatus: IAvProdMessagingStatus = {};

  public webRtcInputs: IAvProdWebRtcInputData[] = [];
  public webRtcOutputs: IAvProdWebRtcOutputData[] = [];

  public frameData$: Observable<IAvMsgBin> = new Observable();
  public audioData$: Observable<IAvMsgBin> = new Observable();

  public components$: Observable<IAvProdComponents> = new Observable();
  public onNewData$: Observable<IAvOnNewData> = new Observable();
  public onNewComposerSettings$: Observable<IAvProdComposerSettings> = new Observable();
  public onNewComposerStatus$: Observable<IAvProdComposerStatus> = new Observable();
  public onNewOutputSettings$: Observable<IAvProdOutputSettings> = new Observable();
  public onNewInputSettings$: Observable<number> = new Observable();
  public onNewInputStatus$: Observable<number> = new Observable();
  public onNewMessagingChatStatus$: Observable<number> = new Observable();
  public onNewOverlaySettings$: Observable<number> = new Observable();
  public onNewInterfaceClientStatus$: Observable<IAvProdInterfaceClientStatus> = new Observable();
  public onNewInterfaceStatus$: Observable<IAvProdInterfaceStatus> = new Observable();
  public onAnswerErrorPublish$: Observable<IAvMsgAscii> = new Observable();
  public onCmdPublishSettingsChange$: Subject<IAvMsgAsciiCommand> = new Subject();
  public onLowBandwidthChange$: Subject<AvProdLowBandwidthConfig> = new Subject();
  public onCommsStatusChange$: Subject<IAvProdCommsStatus> = new Subject();
  public onCommsTooManyClientsChange$: Subject<boolean> = new Subject();
  public onRequestMediaStreamIdResponse$: Subject<IAvMsgAscii> = new Subject();
  public onNewLayoutManagerSettings$: Observable<IAvProdLayoutMngrSettings> = new Observable();
  public onNewServerSettings$: Observable<IAvProdServerSettings> = new Observable();
  public onInterfaceCommandResponse$: Subject<IAvMsgAscii> = new Subject();

  public audioLevelInputs: IAvAudioLevel[] = [];
  public audioLevelOut: IAvAudioLevel = {audiolevels: [0, 0]};

  // Temporarily use just one id. Need to implement options for several publication streams
  public mediaStreamId: number = -1;
  private mediaPublishing: boolean = false;  // To do Joaquin: Need to check this

  // Joaquin: Queda por leer bien y definir el deviceId y un default Name
  public clientInfo: IAvClientInfo = {
    clientId: 0,
    clientType: AvProdClientType.none,
    clientDeviceType: AvProdClientDeviceType.web,
    name: 'Unknown',
    token: '',
    user: 'User',
    deviceId: 'Rnd' + (Math.round(Math.random() * 1000000)).toString(),
    tsDiff: 0
  };
  public clientStatus: IAvProdInterfaceClientStatus = {
    clientId: 0,
    registration: '',
    tsDiff: 0,
    publish: undefined
  }
  public interfaceStatus: IAvProdInterfaceStatus = {
    clients: [],
    liveStreams: []
  }

  public videoStreamOutput$: Subject<SafeUrl> = new Subject();
  public videoStreamInputs$: Subject<SafeUrl>[] = [];

  public deviceStatusInfo: Subject<IAvProdInterfaceClientDeviceStatus> = new Subject<IAvProdInterfaceClientDeviceStatus>();

  private clientStatusTimestamp: number = 0;
  private batteryUpdateTimestamp: number = 0;

  private urlStreamOutput: string | undefined;
  private urlStreamInputs: string[] = [];

  private frameDataSource: Subject<IAvMsgBin> = new Subject();
  private audioDataSource: Subject<IAvMsgBin> = new Subject();

  private audioLevelOutSource: BehaviorSubject<IAvAudioLevel> = new BehaviorSubject({audiolevels: [0, 0]});
  private componentsSource: Subject<IAvProdComponents> = new Subject();
  private onNewDataSource: Subject<IAvOnNewData> = new Subject();
  private onNewComposerSettingsSource: Subject<IAvProdComposerSettings> = new Subject();
  private onNewComposerStatusSource: Subject<IAvProdComposerStatus> = new Subject();
  private onNewOutputSettingsSource: Subject<IAvProdOutputSettings> = new Subject();
  private onNewInputSettingsSource: Subject<number> = new Subject();
  private onNewInputStatusSource: Subject<number> = new Subject();
  private onNewMessagingChatStatusSource: Subject<number> = new Subject();
  private onNewOverlaySettingsSource: Subject<number> = new Subject();
  private onNewInterfaceClientStatusSource: Subject<IAvProdInterfaceClientStatus> = new Subject();
  private onNewInterfaceStatusSource: Subject<IAvProdInterfaceStatus> = new Subject();
  private onAnswerErrorPublishSource: Subject<IAvMsgAscii> = new Subject();
  private onNewLayoutManagerSettingsSource: Subject<IAvProdLayoutMngrSettings> = new Subject();
  private onNewServerSettingsSource: Subject<IAvProdServerSettings> = new Subject();

  private videoFramesActive: number[] = [];   // List of video channels with active subscriptions
  private audioChannelActive = -1;    // Identifier for active audio channel
  private audioLevelActive = false;  // Audio level reception is active
  private audioOwnInputCancellation = true;  // Flag to cancel audio echo for own publication

  private wsMessages$: Observable<any> = new Observable();
  private binaryData$: Observable<any> = new Observable();
  private asciiData$: Observable<any> = new Observable();
  private wsStatusSubscription: Subscription | undefined;
  private wsMsgAsciiSubscription: Subscription | undefined;
  private wsMsgBinarySubscription: Subscription | undefined;
  private languageSubscription: Subscription | undefined;
  private timerStatusSubscription: Subscription | undefined;
  private webRtcCheckSubscription: Subscription | undefined;

  private event: IEvent | undefined;

  constructor(private wsService: WebSocketService,
              private translate: TranslateService,
              private sanitizer: DomSanitizer,
              private userService: UserService,
              private device: DeviceService) {

    this.frameData$ = this.frameDataSource.asObservable();
    this.audioData$ = this.audioDataSource.asObservable();
    this.components$ = this.componentsSource.asObservable();
    this.onNewData$ = this.onNewDataSource.asObservable();
    this.onNewComposerSettings$ = this.onNewComposerSettingsSource.asObservable();
    this.onNewComposerStatus$ = this.onNewComposerStatusSource.asObservable();
    this.onNewOutputSettings$ = this.onNewOutputSettingsSource.asObservable();
    this.onNewInputSettings$ = this.onNewInputSettingsSource.asObservable();
    this.onNewInputStatus$ = this.onNewInputStatusSource.asObservable();
    this.onNewMessagingChatStatus$ = this.onNewMessagingChatStatusSource.asObservable();
    this.onNewOverlaySettings$ = this.onNewOverlaySettingsSource.asObservable();
    this.onNewInterfaceClientStatus$ = this.onNewInterfaceClientStatusSource.asObservable();
    this.onNewInterfaceStatus$ = this.onNewInterfaceStatusSource.asObservable();
    this.onAnswerErrorPublish$ = this.onAnswerErrorPublishSource.asObservable();
    this.onNewLayoutManagerSettings$ = this.onNewLayoutManagerSettingsSource.asObservable();
    this.onNewServerSettings$ = this.onNewServerSettingsSource.asObservable();
  }

  private onLanguageChanged(value: any) {
    //console.log('[EventAvProducer] Language changed: ' + JSON.stringify(value));
    // Update avOptions translations
    AV_PROD_OPTIONS_LIST.forEach(element => {
      element.forEach(options => {
        options.labelTranslate = this.translate.instant(options.label);
      });
    });
  }

  /**
   * Initializes this service object
   * @returns void
   */
  public init() {

    this.onLanguageChanged(null);
    if (this.languageSubscription !== undefined) this.languageSubscription.unsubscribe();
    this.languageSubscription = this.translate.onLangChange.subscribe(value => this.onLanguageChanged(value));

    this.closeComms();

    this.commsStatus.status = AvProdStatus.closed;
    this.wsMessages$ = this.wsService.messages$.pipe(
      map((data: any) => new Uint8Array(data)),
      catchError(err => {
        throw err;
      }));

    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);

    // Separating binary from ascii data messages
    [this.binaryData$, this.asciiData$] = partition(this.wsMessages$, (data) => (data[0] >= 0x61 && data[0] <= 0x7A));

    this.wsMsgAsciiSubscription = this.asciiData$.subscribe(data => this.receiveWsMsgAscii(data));
    this.wsMsgBinarySubscription = this.binaryData$.subscribe(data => this.receiveWsMsgBinary(data));

    this.wsStatusSubscription = this.wsService.socketStatus$.subscribe(status => this.receiveWsStatus(status));

    this.webRtcCheckSubscription = interval(500).subscribe(() => this.tickWebRtcCheck());
  }

  /**
   * Destroys and closes this service object
   * @returns void
   */
  public destroy() {

    this.closeComms();

    if (this.languageSubscription !== undefined) this.languageSubscription.unsubscribe();

    if (this.wsStatusSubscription !== undefined) {
      this.wsStatusSubscription.unsubscribe();
    }
    if (this.wsMsgAsciiSubscription !== undefined) {
      this.wsMsgAsciiSubscription.unsubscribe();
    }
    if (this.wsMsgBinarySubscription !== undefined) {
      this.wsMsgBinarySubscription.unsubscribe();
    }
    if (this.timerStatusSubscription !== undefined) {
      this.timerStatusSubscription.unsubscribe();
    }
    if (this.webRtcCheckSubscription !== undefined) {
      this.webRtcCheckSubscription.unsubscribe();
    }
    this.commsStatus.status = AvProdStatus.destroyed;
    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);
  }

  /**
   * Checks event information and opens websocket communications
   *
   * @param event Includes all event information available
   *
   * @return boolean (true for OK and false for Error)
   */
  public openComms(event: IEvent): boolean {
    let ret = false;

    if (this.commsStatus.status !== AvProdStatus.closed) {
      this.closeComms();
    }

    if (this.userService.user?.name !== undefined) {
      this.clientInfo.name = this.userService.user.name;
    } else {
      this.clientInfo.name = 'AzzuleiTV';
    }
    if (this.userService.user?.id !== undefined) {
      this.clientInfo.user = this.userService.user.id.toString();
    } else {
      this.clientInfo.user = 'User';
    }
    if (this.device.device?.identifier !== undefined) {
      this.clientInfo.deviceId = this.device.device.identifier.toString();
    }

    if (this.clientInfo.clientType === AvProdClientType.producer){
      this.clientInfo.deviceId += '-p';
    }
    else if (this.clientInfo.clientType === AvProdClientType.publisher){
      this.clientInfo.deviceId += '-t';
    }

    if (event.host !== undefined) {
      this.wsService.connect(WS.protocol + event.host + WS.path, {reconnect: true});
      ret = true;
      this.event = event;
      console.log('[AvProducerService] Open Comms');
    }
    return ret;
  }

  /**
   * Closes websocket communications with avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  public closeComms(): boolean {
    const RET = true;
    this.webRtcConnectionCloseAll();
    this.wsService.close();

    // Initialize component lists
    this.inputs = [];
    this.overlays = [];
    this.layoutManager = {videoLayouts: [], favorites: []};
    this.composerSettings = {}
    this.composerStatus = {}
    this.serverStatus = AvProdServerStatusDefault;
    this.outputSettings = {};
    this.clientInfo.clientId = 0;
    this.clientInfo.tsDiff = 0;
    this.commsStatus.status = AvProdStatus.closed;
    this.commsStatus.ok = false;
    this.onCommsStatusChange$.next(this.commsStatus);
    return RET;
  }

  /**
   * Function called periodically by an interval to check communications
   */
  private tickStatusCheck() {
    const TIME_NOW: number = (new Date()).getTime();
    if ((this.commsStatus.status === AvProdStatus.receiving) &&
      (this.clientStatusTimestamp != 0)) {
      const DIFF: number = (TIME_NOW - this.clientStatusTimestamp);
      if (DIFF > 3000) {
        this.commsStatus.status = AvProdStatus.error;
        this.commsStatus.ok = false;
        this.mediaStreamId = -1;
        console.log('[EventAvProducer] Communications error Timeout');
        // Trigger status change event
        this.onCommsStatusChange$.next(this.commsStatus);
      }
    }

    // Calculate comms stats
    if (this.commsStatus.stats.lastTimestamp === 0) {
      this.commsStatus.stats.lastTimestamp = TIME_NOW;
    } else if (TIME_NOW - this.commsStatus.stats.lastTimestamp >= 2000) {
      this.commsStatus.stats.rxRate = this.commsStatus.stats.rxBytes * 8 / 2;
      this.commsStatus.stats.txRate = this.commsStatus.stats.txBytes * 8 / 2;
      this.commsStatus.stats.rxBytes = 0;
      this.commsStatus.stats.txBytes = 0;
      if (this.commsStatus.stats.rxRate > 1000000) this.commsStatus.stats.rxRateStr = (this.commsStatus.stats.rxRate / 1000000).toFixed(2) + 'Mbps';
      else this.commsStatus.stats.rxRateStr = (this.commsStatus.stats.rxRate / 1000).toFixed(2) + 'Kbps';
      if (this.commsStatus.stats.txRate > 1000000) this.commsStatus.stats.txRateStr = (this.commsStatus.stats.txRate / 1000000).toFixed(2) + 'Mbps';
      else this.commsStatus.stats.txRateStr = (this.commsStatus.stats.txRate / 1000).toFixed(2) + 'Kbps';
      this.commsStatus.stats.lastTimestamp = TIME_NOW;
    }

    // Check communications reception
    let maxDelay: number = 0;
    let isHigh: number = 0;
    if (this.commsStatus.videoReceptionStatus.lastDelays.length >= 5) {
      for (let i = 0; i < this.commsStatus.videoReceptionStatus.lastDelays.length; i++) {
        if (this.commsStatus.videoReceptionStatus.lastDelays[i] > 500) isHigh++;
        if (this.commsStatus.videoReceptionStatus.lastDelays[i] > maxDelay) maxDelay = this.commsStatus.videoReceptionStatus.lastDelays[i];
      }
    }

    if (this.commsStatus.lowBandwidthServerRestarting === true) {
      this.azzRemoveSubscriptions();
      console.log('Check comms, resend subscriptions again?: ' + (TIME_NOW - this.commsStatus.videoReceptionStatus.lastRxTimestamp) + ',' + JSON.stringify(this.commsStatus.videoReceptionStatus.lastDelays));
      if (TIME_NOW - this.commsStatus.videoReceptionStatus.lastRxTimestamp > 1000) {
        this.commsStatus.lowBandwidthServerRestarting = false;
        console.log('Check comms, resend subscriptions again YES: ' + isHigh + ',' + JSON.stringify(this.commsStatus.videoReceptionStatus.lastDelays));
        this.azzSendSubscriptions();
      }
    } else if (this.videoFramesActive.length > 0) {

      if ((TIME_NOW - this.commsStatus.videoReceptionStatus.lastRxTimestamp < 1000) &&
        (TIME_NOW - this.commsStatus.lowBandwidthStatusTS >= 1000)) {

        if (this.commsStatus.videoReceptionStatus.lastDelays.length >= 5) {
          if ((isHigh >= this.commsStatus.videoReceptionStatus.lastDelays.length) &&
            (this.commsStatus.lowBandwidthStatus != AvProdLowBandwidthConfig.verylow)) {
            console.log('Check comms, delays detected: ' + isHigh + ',' + maxDelay);
            // Switch low bandwidth mode
            if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.high) {
              this.commsStatus.lowBandwidthStatus = AvProdLowBandwidthConfig.medium;
              this.commsStatus.lowBandwidthStatusTS = TIME_NOW;
            } else if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.medium) {
              this.commsStatus.lowBandwidthStatus = AvProdLowBandwidthConfig.low;
              this.commsStatus.lowBandwidthStatusTS = TIME_NOW;
            } else if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.low) {
              this.commsStatus.lowBandwidthStatus = AvProdLowBandwidthConfig.verylow;
              this.commsStatus.lowBandwidthStatusTS = TIME_NOW;
            }
            console.log('Switch low bandwidth to ' + this.commsStatus.lowBandwidthStatus);
            this.commsStatus.lowBandwidthServerRestarting = true;
            this.azzRemoveSubscriptions();
            this.onLowBandwidthChange$.next(this.commsStatus.lowBandwidthStatus);
            this.commsStatus.videoReceptionStatus.lastRxTimestamp = 0;
            this.commsStatus.videoReceptionStatus.lastDelays = [];
          }
        }
      }
    }
  }

  /**
   *
   * @param deviceType Type of device or component
   * @param deviceId Component identifier
   * @param section Item section
   * @param subSections Item subsections (optional)
   * @returns string describing item
   */
  private item2string(deviceType: AvProdDeviceType, deviceId: number, section: AvProdItemSection, subSections: string[]): string {
    let ret: string = deviceType + '/' + deviceId.toString() + '/' + section;
    for (let i = 0; i < subSections.length; i++) {
      ret += ('/' + subSections[i]);
    }
    return ret;
  }

  private string2item(item: string): IAvMsgItem {
    const RET: IAvMsgItem = {
      deviceType: AvProdDeviceType.none,
      deviceId: 0,
      section: AvProdItemSection.none,
      subSections: []
    }

    const STR_ARRAY: string[] = item.split('/');
    if (STR_ARRAY.length >= 3) {
      if ((<any>AvProdDeviceType)[STR_ARRAY[0]] !== undefined) {
        RET.deviceType = (<any>AvProdDeviceType)[STR_ARRAY[0]];
      }
      RET.deviceId = parseInt(STR_ARRAY[1]);
      if ((<any>AvProdItemSection)[STR_ARRAY[2]] !== undefined) {
        RET.section = (<any>AvProdItemSection)[STR_ARRAY[2]];
      }
    }

    if (STR_ARRAY.length > 3) {
      RET.subSections = STR_ARRAY.splice(3);
    }
    return RET;
  }

  /**
   * Receives new Status change from Web socket service
   *
   * @param wsStatus WebSocket service new status
   */
  private receiveWsStatus(wsStatus: WSStatus) {
    console.log('[AvProducerService] New Ws Status: ' + wsStatus);
    let localStatus: AvProdStatus = AvProdStatus.error;
    if ((wsStatus == WSStatus.closed) ||
      (wsStatus == WSStatus.closedByUser)) localStatus = AvProdStatus.closed;
    else if (wsStatus == WSStatus.closedByServer) localStatus = AvProdStatus.error;
    else if (wsStatus == WSStatus.closing) localStatus = AvProdStatus.closing;
    else if (wsStatus == WSStatus.connecting) localStatus = AvProdStatus.connecting;
    else if (wsStatus == WSStatus.open) {
      if ((this.commsStatus.status !== AvProdStatus.connected) &&
        (this.commsStatus.status !== AvProdStatus.receiving)) {
        this.azzSendHello();
        this.clientStatusTimestamp = (new Date()).getTime();
      }
      localStatus = AvProdStatus.connected;
    }

    if (this.timerStatusSubscription !== undefined) this.timerStatusSubscription.unsubscribe();
    if ((localStatus === AvProdStatus.connected) || (this.commsStatus.lowBandwidthServerRestarting === true)) {
      this.timerStatusSubscription = interval(1000).subscribe(() => {
        this.tickStatusCheck()
      });
    }

    this.commsStatus.ok = false;
    this.mediaStreamId = -1;

    this.commsStatus.status = localStatus;

    if (this.commsStatus.status != AvProdStatus.connected) {
      this.commsStatus.videoReceptionStatus.lastRxTimestamp = 0;
      this.commsStatus.videoReceptionStatus.lastDelays = [];
    }
    console.log('[AvProducerService] New Av Status: ' + this.commsStatus.status);
    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);
  }

  /**
   * Receives new ASCII message from avProducer Web socket
   *
   * @param data Message data received from the web socket
   */
  private receiveWsMsgAscii(data: any) {
    // Ignore first byte and parse JSON object
    const msgType: string = String.fromCharCode(data[0]);
    const msg = JSON.parse(new TextDecoder().decode(data.slice(1)));
    //console.log('[AvProducerService] New Ws Message Ascii: (' + data.length + ') (' + msgType + ') ' + JSON.stringify(msg));

    if (msg != undefined) {

      // Add received bytes for stats
      this.commsStatus.stats.rxBytes += data.length

      // Register reception TS for communications check
      this.clientStatusTimestamp = (new Date()).getTime();
      this.commsStatus.status = AvProdStatus.receiving;
      if (this.commsStatus.ok !== true) {
        // Trigger status change event
        this.commsStatus.ok = true;
        this.onCommsStatusChange$.next(this.commsStatus);
      }

      switch (msgType) {

        case AvProdMsgAsciiType.answer: {
          // Answer message received
          this.receiveAvAnswer(msg);
          break;
        }
        case AvProdMsgAsciiType.notification: {
          // Notification message received
          this.receiveAvNotification(msg);
          break;
        }
        case AvProdMsgAsciiType.request: {
          // Request message received
          this.receiveAvRequest(msg);
          break;
        }
      }
    }
  }

  /**
   * Receives AV Answer message
   *
   * @param msg Answer message object
   */
  private receiveAvAnswer(msg: IAvMsgAscii) {

    // Answers to ASCII messages
    switch (msg.request) {

      case AvProdRequests.hello: {
        // Hello answer returns client Id, token validation and time synchronization data
        const ANS_DATA: IAvMsgDataHelloAns | undefined = msg.data;
        if (msg.retCode !== 0) {
          console.log('[AvProducerService] Hello ERROR (' + msg.retStr + ')');
          // By now, close comms if Hello error
          this.closeComms();
          if (msg.retCode === 14) {
            // Too many clients error code
            this.onCommsTooManyClientsChange$.next(true);
          }
        } else if ((msg.retCode === 0) && (ANS_DATA !== undefined)) {
          this.clientInfo.clientId = ANS_DATA.clientId;
          if (ANS_DATA.deviceId !== undefined){
            this.clientInfo.deviceId = ANS_DATA.deviceId;
          }
          this.batteryUpdateTimestamp = 0;
          // Check registration return value
          // ansData.registration
          const HELLO_SYNC_DATA: IAvMsgDataHelloSync = {
            ts1: ANS_DATA.ts1,
            ts2: ANS_DATA.ts2,
            ts3: new Date().getTime()
          }
          this.azzSendHelloSync(HELLO_SYNC_DATA);
        }
        break;
      }

      case AvProdRequests.helloSync: {
        const ANS_DATA: IAvMsgDataHelloSyncAns | undefined = msg.data;
        if ((ANS_DATA !== undefined) && (msg.retCode !== undefined) && (msg.retCode == 0)) {
          this.clientInfo.tsDiff = ANS_DATA.tsDiff;
          console.log('[AvProducerService] Hello Sync Answer received. Id' + this.clientInfo.clientId + ' (' + this.clientInfo.tsDiff + ')');
          this.azzSendInitialRequests();
          this.azzSendSubscriptions();
        }
        break;
      }

      // Answers to get requests are like Notification
      case AvProdRequests.get: {
        this.receiveAvNotification(msg);
        break;
      }

      case AvProdRequests.command: {
        if (msg.item === 'interface/1/commands'){
          if (msg.dataTx.command === 'RequestMediaStreamId'){
            if (msg.data.streamId !== undefined){
              this.mediaStreamId = msg.data.streamId;
              console.log('[AvProducerService] RequestMediaStreamId ANS. Msg: ' + JSON.stringify(msg));
            } else {
              console.log('[AvProducerService] RequestMediaStreamId ANS Error. Id: ' + this.mediaStreamId);
              this.mediaStreamId = -1;
            }
            this.onRequestMediaStreamIdResponse$.next(msg);
          }
          else{
            console.log('[AvProducerService] receiveAvAnswer Interface CMD ' + JSON.stringify(msg));
            this.onInterfaceCommandResponse$.next(msg);
          }
        }
        else if (msg.dataTx.command === 'WebRtcCreate') {
          this.webRtcConnectionCreateHandleAnswer(msg);
        }
        else if (msg.dataTx.command === 'WebRtcSetDescription') {
          this.webRtcConnectionSetDescriptionHandleAnswer(msg);
        }
        else if (msg.dataTx.command === 'WebRtcDelete') {
          this.webRtcConnectionDeleteHandleAnswer(msg);
        }
        break;
      }
    }

    // Check answers for Binary data messages
    if ((msg.dataType !== undefined) && (msg.dataId !== undefined) &&
      (msg.retCode !== 0)) {
      switch (msg.dataType) {
        case AvProdMsgBinType.audioencoded:
        case AvProdMsgBinType.audioraw:
        case AvProdMsgBinType.frame:
        case AvProdMsgBinType.media:
          // Publication error received
          this.onAnswerErrorPublishSource.next(msg);
          break;
      }
    }

  }

  /**
   * Receives AV Notification message
   *
   * @param msg Notification message object
   */
  private receiveAvNotification(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    //console.log('[EventAvProducer] Notification Item: ' + JSON.stringify(item));

    // Audio level data received
    if (ITEM.section === AvProdItemSection.audiolevel) {
      if (ITEM.deviceType === AvProdDeviceType.output) {
        if (msg.data !== undefined) {
          this.audioLevelOut = msg.data;
          this.audioLevelOutSource.next(msg.data);
        }
      } else if (ITEM.deviceType === AvProdDeviceType.input) {
        if (msg.data !== undefined) {
          this.audioLevelInputs[ITEM.deviceId] = msg.data;
        }
      }
    }
    // Components data received
    else if (ITEM.section === AvProdItemSection.components) {
      if (ITEM.deviceType === AvProdDeviceType.server) {
        if (msg.data !== undefined) {
          //console.log('[EventAvProducer] Components: ' + JSON.stringify(msg.data));
          this.updateInputComponents(msg.data);
          this.updateOverlayComponents(msg.data);
          this.componentsSource.next(msg.data);
        }
      }
    }
    // Composer settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.composer)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Composer settings: ' + JSON.stringify(msg.data));
        this.updateComposerSettings(ITEM.deviceId, msg.data);
        this.onNewComposerSettingsSource.next(this.composerSettings);
      }
    }
    // Composer settings options received
    else if ((ITEM.section === AvProdItemSection.options) && (ITEM.deviceType === AvProdDeviceType.composer)) {
      if (msg.data !== undefined) {
        this.updateComposerSettingsOptions(ITEM.deviceId, msg.data);
      }
    }
    // Composer status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.composer)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Composer status: ' + JSON.stringify(msg.data));
        this.updateComposerStatus(ITEM.deviceId, msg.data);
        this.onNewComposerStatusSource.next(this.composerStatus);
      }
    }
    // Input settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.input)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Input settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateInputSettings(ITEM.deviceId, msg.data);
        this.onNewInputSettingsSource.next(ITEM.deviceId);
      }
    }
    // Overlay settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.overlay)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Overlay settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateOverlaySettings(ITEM.deviceId, msg.data);
        this.onNewOverlaySettingsSource.next(ITEM.deviceId);
      }
    }
    // Output settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.output)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Output settings: (' + item.deviceId + ')' + JSON.stringify(msg.data));
        this.updateOutputSettings(ITEM.deviceId, msg.data);
        this.onNewOutputSettingsSource.next(this.outputSettings);
      }
    }
    // Layout manager settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.layoutmanager)) {
      if (msg.data !== undefined) {
        console.log('[EventAvProducer] Layout Manager settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateLayoutManager(ITEM.deviceId, msg.data);
        this.onNewLayoutManagerSettingsSource.next(msg.data);
      }
    }
    // Server settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.server)) {
      if (msg.data !== undefined) {
        console.log('[EventAvProducer] Server settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateServerSettings(ITEM.deviceId, msg.data);
        this.onNewServerSettingsSource.next(msg.data);
      }
    }
    // Input status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.input)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Input status: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateInputStatus(ITEM.deviceId, msg.data);
        this.onNewInputStatusSource.next(ITEM.deviceId);
      }
    }
    // Server status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.server)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Server status: (' + item.deviceId + ')' + JSON.stringify(msg.data));
        this.updateServerStatus(ITEM.deviceId, msg.data);
      }
    }
    // Interface status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.interface)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Interface status: (' + ITEM.deviceId + ')[' + this.clientInfo.clientId + ']' + JSON.stringify(msg.data));
        this.updateInterfaceStatus(ITEM.deviceId, msg.data);
      }
    }
    // Interface client status data received
    else if ((ITEM.section === AvProdItemSection.client) && (ITEM.deviceType === AvProdDeviceType.interface)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Interface client status: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateClientStatus(ITEM.deviceId, msg.data);
      }
    }
    // Messaging status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.msg)) {
      if (msg.data !== undefined) {
        console.log('[EventAvProducer] Messaging status: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateMessagingStatus(ITEM.deviceId, msg.data);
      }
    }

    // Emit event fo new data
    const NEW_DATA: IAvOnNewData = {
      item: ITEM,
      data: msg.data
    }

    this.onNewDataSource.next(NEW_DATA);
  }

  /**
   * Receives AV Request message
   *
   * @param msg Request message object
   */
  private receiveAvRequest(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    console.log('[EventAvProducer] receiveAvRequest Item: ' + JSON.stringify(ITEM));

    // Command
    if (msg.request === AvProdMsgAsciiRequest.command) {
      if ((ITEM.deviceType === AvProdDeviceType.client) &&
        (ITEM.section === AvProdItemSection.commands)) {
        if (ITEM.deviceId === this.clientInfo.clientId) {
          // Command for this interface client
          if (msg.data !== undefined) {
            const CMD: IAvMsgAsciiCommand = msg.data;
            this.onCmdPublishSettingsChange$.next(CMD);
          }
        }
      }
    }
  }

  private executeCommandClient(cmd: IAvMsgAsciiCommand) {
    switch (cmd.command) {
      case 'PublishSettingsChange':

        break;
    }
  }

  /**
   * Receives new Binary message from avProducer Web socket
   *
   * @param data Message data received from the web socket
   */
  private receiveWsMsgBinary(data: any) {
    const MSG: IAvMsgBin = this.parseWsMsgBinary(data);
    //console.log('[AvProducerService] New Ws Message Binary: ' + msg.type);

    // Add received bytes for stats
    this.commsStatus.stats.rxBytes += MSG.dataSize;

    if (MSG.type == AvProdMsgBinType.frame) {
      // Trigger video frame event

      if (MSG.dataId == AV_PROD_FRAME_ID_OUPUT_OFFSET) {
        // Output video frame
        if (!this.videoStreamOutput$) {
          this.videoStreamOutput$ = new Subject<SafeUrl>();
        }
        if (this.urlStreamOutput) {
          URL.revokeObjectURL(this.urlStreamOutput);
        }
        this.urlStreamOutput = URL.createObjectURL(new Blob([MSG.data], {type: AV_PROD_URL_TYPE_JPEG}));
        this.videoStreamOutput$?.next(this.sanitizer.bypassSecurityTrustResourceUrl(this.urlStreamOutput));
      } else {
        // Input video frame
        if (!this.videoStreamInputs$[MSG.dataId]) {
          this.videoStreamInputs$[MSG.dataId] = new Subject<SafeUrl>();
        }
        if (this.urlStreamInputs[MSG.dataId]) {
          URL.revokeObjectURL(this.urlStreamInputs[MSG.dataId]);
        }
        this.urlStreamInputs[MSG.dataId] = URL.createObjectURL(new Blob([MSG.data], {type: AV_PROD_URL_TYPE_JPEG}));
        this.videoStreamInputs$[MSG.dataId]?.next(this.sanitizer.bypassSecurityTrustResourceUrl(this.urlStreamInputs[MSG.dataId]));
      }

      this.frameDataSource.next(MSG);
    } else if ((MSG.type == AvProdMsgBinType.audioraw) ||
      (MSG.type == AvProdMsgBinType.audiocancel)) {
      // Trigger raw audio event
      this.audioDataSource.next(MSG);
    } else {
      //console.log('[AvProducerService] New Ws Message Binary: ' + msg.type + '/' + msg.dataId);
    }
  }

  /**
   * Receives new Binary message from avProducer Web socket
   *
   * @param data Message data received from the web socket
   */
  private parseWsMsgBinary(data: any): IAvMsgBin {
    const MSG: IAvMsgBin = {
      type: String.fromCharCode(data[0]),
      timestamp: (256 * 256 * 256 * 256 * 256 * data[2]) +
        (256 * 256 * 256 * 256 * data[3]) +
        (256 * 256 * 256 * data[4]) +
        (256 * 256 * data[5]) +
        (256 * data[6]) + data[7],
      dataId: (256 * data[8]) + data[9],
      dataSubId: (256 * 256 * 256 * data[10]) + (256 * 256 * data[11]) + (256 * data[12]) + data[13],
      dataSize: data[18] * 0x1000000 + data[19] * 0x10000 + data[20] * 0x100 + data[21],
      data: data.slice(AV_PROD_BIN_HEADER_LENGTH)
    };
    const TIME_NOW = Date.now();
    const TS_DIFF = TIME_NOW - MSG.timestamp - this.clientInfo.tsDiff;
    if (TS_DIFF > 500) {
      console.log('[AvProducerService] Message ' + MSG.type + ' received late ' + TS_DIFF + ' (Discard)');
      MSG.type = '';
    }
    this.commsStatus.videoReceptionStatus.lastRxTimestamp = TIME_NOW;
    this.commsStatus.videoReceptionStatus.lastDelays.push(TS_DIFF);
    if (this.commsStatus.videoReceptionStatus.lastDelays.length > 5) this.commsStatus.videoReceptionStatus.lastDelays.shift();
    return MSG;
  }

  /**
   * Function to update input list info, called when new components information is received
   *
   * @param components Components information received from avProducer
   */
  private updateInputComponents(components: IAvProdComponents) {
    const NEW_INPUTS: IAvProdInput[] = [];
    components.inputs.forEach(element => {
      const NEW_INPUT: IAvProdInput = {
        info: element
      };
      const ACTUAL_INPUT: IAvProdInput | undefined = this.inputs.find(input => (input.info.id === element.id));
      if (ACTUAL_INPUT !== undefined) {
        if (ACTUAL_INPUT.settings !== undefined){
          NEW_INPUT.settings = ACTUAL_INPUT.settings;
        }
        else {
          this.azzRequestInputSettings(element.id);
        }
        if (ACTUAL_INPUT.status !== undefined){
          NEW_INPUT.status = ACTUAL_INPUT.status;
        }
        else {
          this.azzRequestInputStatus(element.id);
        }
      }
      else {
        //console.log('[AvProducerService] updateInputComponents. Request input settings/status ' + element.id);
        this.azzRequestInputStatus(element.id);
        this.azzRequestInputSettings(element.id);
      }
      NEW_INPUTS.push(NEW_INPUT);
    });
    this.inputs = NEW_INPUTS;
  }

  /**
   * Function to update overlay list info, called when new components information is received
   *
   * @param components Components information received from avProducer
   */
  private updateOverlayComponents(components: IAvProdComponents) {
    const NEW_OVERLAYS: IAvProdOverlay[] = [];
    components.overlays.forEach(element => {
      const NEW_OVERLAY: IAvProdOverlay = {
        info: element
      };
      const ACTUAL_OVERLAY: IAvProdOverlay | undefined = this.overlays.find(overlay => (overlay.info.id === element.id));
      if (ACTUAL_OVERLAY !== undefined) {
        if (ACTUAL_OVERLAY.settings !== undefined) ACTUAL_OVERLAY.settings = NEW_OVERLAY.settings;
      }
      NEW_OVERLAYS.push(NEW_OVERLAY);
      this.azzRequestOverlaySettings(NEW_OVERLAY.info.id);
    });
    this.overlays = NEW_OVERLAYS;
  }

  /**
   * Function to update composer settings, called when new setting information is received
   *
   * @param id Composer identifier
   * @param settings New composer settings received from avProducer
   */
  private updateComposerSettings(id: number, settings: IAvProdComposerSettings) {
    this.composerSettings = settings;
  }

  /**
   * Function to update composer settings options, called when new components information is received
   *
   * @param id Composer identifier
   * @param options New composer settings received from avProducer
   */
  private updateComposerSettingsOptions(id: number, options: any) {
    // Update fonts
    while (AV_PROD_OPTIONS_FONTS.length > 0) AV_PROD_OPTIONS_FONTS.pop();
    if (options.fonts !== undefined) {
      for (let i: number = 0; i < options.fonts.length; i++) {
        const ITEM: IAvSettingSelectOption = {
          label: options.fonts[i],
          labelTranslate: options.fonts[i],
          valueNumber: i,
          valueStr: options.fonts[i]
        }
        AV_PROD_OPTIONS_FONTS.push(ITEM);
      }
    }
    AV_PROD_OPTIONS_FONTS.sort((a,b) => {
        if (a.label > b.label) return 1; else return -1;
    });
  }

  /**
   * Function to update composer status, called when new status information is received
   *
   * @param id Composer identifier
   * @param status New composer settings received from avProducer
   */
  private updateComposerStatus(id: number, status: IAvProdComposerStatus) {
    this.composerStatus = status;
  }

  /**
   * Function to update output settings, called when new information is received
   *
   * @param id Input identifier
   * @param settings New output settings received from avProducer
   */
  private updateOutputSettings(id: number, settings: IAvProdOutputSettings) {
    this.outputSettings = settings;
  }

  /**
   * Function to update input settings, called when new information is received
   *
   * @param id Input identifier
   * @param settings New input settings received from avProducer
   */
  private updateInputSettings(id: number, settings: IAvProdInputSettings) {
    const ACTUAL_INPUT: IAvProdInput | undefined = this.inputs.find(input => (input.info.id === id));
    if (ACTUAL_INPUT !== undefined) {
      ACTUAL_INPUT.settings = settings;
      if (settings.favorite !== undefined) ACTUAL_INPUT.info.favorite = settings.favorite;
      if (settings.name !== undefined) ACTUAL_INPUT.info.name = settings.name;
    }
  }

  /**
   * Function to update input status, called when new information is received
   *
   * @param id Input identifier
   * @param status
   */
  private updateInputStatus(id: number, status: IAvProdInputStatus) {
    const ACTUAL_INPUT: IAvProdInput | undefined = this.inputs.find(input => (input.info.id === id));
    if (ACTUAL_INPUT !== undefined) {
      ACTUAL_INPUT.status = status;
    }
  }

  /**
   * Function to update overlay settings, called when new information is received
   *
   * @param id Input identifier
   * @param settings New input settings received from avProducer
   */
  private updateOverlaySettings(id: number, settings: IAvProdOverlaySettings) {
    const ACTUAL_OVERLAY: IAvProdOverlay | undefined = this.overlays.find(overlay => (overlay.info.id === id));
    if (ACTUAL_OVERLAY !== undefined) {
      ACTUAL_OVERLAY.settings = settings;
      if (settings.favorite !== undefined) ACTUAL_OVERLAY.info.favorite = settings.favorite;
      if (settings.general?.name !== undefined) ACTUAL_OVERLAY.info.name = settings.general.name;
    }
  }

  /**
   * Function to update server status, called when new information is received
   *
   * @param id Server identifier (1)
   * @param status New server status received from avProducer
   */
  private updateServerStatus(id: number, status: IAvProdServerStatus) {
    this.serverStatus = status;
  }

  /**
   * Function to update server settings, called when new information is received
   *
   * @param id Server identifier (1)
   * @param settings New server settings received from avProducer
   */
  private updateServerSettings(id: number, settings: IAvProdServerSettings) {
    this.serverSettings = settings;
  }

  /**
   * Function to update interface status, called when new information is received
   *
   * @param id Interface identifier (1)
   * @param status New interface status received from avProducer
   */
  private updateInterfaceStatus(id: number, status: IAvProdInterfaceStatus) {
    this.interfaceStatus = status;
    this.onNewInterfaceStatusSource.next(status);
  }

  /**
   * Function to update interface client status, called when new information is received
   *
   * @param id Interface client identifier (1)
   * @param status New interface client status received from avProducer
   */
  private updateClientStatus(id: number, status: IAvProdInterfaceClientStatus) {
    if (this.clientInfo.clientId === status.clientId) {
      this.clientStatus = status;
      // Send ping to avProducer to check connectivity status
      if (status.ts !== undefined) {
        this.azzSendPing(status.ts);
      }
      this.onNewInterfaceClientStatusSource.next(status);
      const TS_NOW: number = (new Date()).getTime();
      if (TS_NOW - this.batteryUpdateTimestamp > 60000){
        this.batteryUpdateTimestamp = TS_NOW;
        this.updateBatteryInfo();
      }
    } else {
      console.log('[AvProducerService] Client status different Id:' + this.clientInfo.clientId + '/' + status.clientId);
    }
  }

  /**
   * Function to update messaging component status, called when new information is received
   *
   * @param id Messaging component identifier (1)
   * @param status New messaging status received from avProducer
   */
  private updateMessagingStatus(id: number, status: IAvProdMessagingStatus) {
    if ((id === 1)&&(status.devId === id)) {
      // Chat messaging
      this.messagingChatStatus = status;
      this.onNewMessagingChatStatusSource.next(id);
    } else {
      console.log('[AvProducerService] Messaging chat status wrong Id:' + id);
    }
  }

  /**
   * Function to update layout manager settings, called when new settings are received
   *
   * @param id Layout manager identifier
   * @param settings New layout manager settings received from avProducer
   */
  private updateLayoutManager(id: number, settings: IAvProdLayoutMngrSettings) {
    if (id === 1) {
      this.layoutManager = settings;
    }
  }

  protected updateBatteryInfo(){
    Device.getBatteryInfo().then(info => {
      const STATUS_INFO: IAvProdInterfaceClientDeviceStatus =
      {
        batteryLevel: info.batteryLevel ? Math.round(info.batteryLevel * 100) : 0,
        batteryCharging: info.isCharging ?? false
      };
      this.deviceStatusInfo.next(STATUS_INFO);
      console.log('[AvProducerService] Battery ' + JSON.stringify(STATUS_INFO));
      this.azzNotifyClientStatus(STATUS_INFO);
    });
  }

  /**
   * Sends an ASCII message to avProducer
   *
   * @param msgType Character defining message type (request, notification, answer)
   * @param request Message action (subscribe, set, get, unsubscribe, ...)
   * @param item avProducer item destination (object type / identifier / item / ...)
   * @param data Optional extra message data information in JSON object format
   *
   * @return boolean (true for OK and false for Error)
   */
  private sendMsgAscii(msgType: string, request: string, item: string, data?: any): boolean {
    const RET = true;
    let msg = '';
    const MSG_OBJECT: any = {};

    MSG_OBJECT.request = request;
    MSG_OBJECT.item = item;
    if (data !== undefined) MSG_OBJECT.data = data;

    // Start by message type character
    msg += msgType;
    // Add stringified JSON object
    msg += JSON.stringify(MSG_OBJECT);
    //console.log('[AvProducerService] sendMsgAscii:' + msg);
    this.wsService.sendMessage(msg);
    // Add transmitted bytes for stats
    this.commsStatus.stats.txBytes += msg.length;
    return RET;
  }

  /**
   * Sends a command to a specific device in the avProducer
   *
   * @param deviceType
   * @param deviceId
   * @param cmd
   * @returns boolean (true for OK and false for Error)
   */
  private azzSendCommand(deviceType: AvProdDeviceType, deviceId: number, cmd: IAvMsgAsciiCommand): boolean {
    const ITEM: string = this.item2string(deviceType, deviceId, AvProdItemSection.commands, []);
    return this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.command, ITEM, cmd);
  }

  /**
   * Sends Hello message to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendHello() {
    const DATA_OBJ: IAvMsgDataHello = {
      clientType: this.clientInfo.clientType,
      clientDeviceType: this.clientInfo.clientDeviceType,
      deviceId: this.clientInfo.deviceId,
      token: this.clientInfo.token,
      name: this.clientInfo.name,
      user: this.clientInfo.user,
      ts1: new Date().getTime()
    }

    return this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.hello, 'interface', DATA_OBJ);
  }

  /**
   * Sends Hello Syn message to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendHelloSync(data: IAvMsgDataHelloSync) {
    return this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.helloSync, 'interface', data);
  }

  /**
   * Sends ping message to avProducer
   *
   */
  private azzSendPing(tsNotification: number) {
    const DATA_OBJ: IAvMsgDataPing = {
      tsNotification: tsNotification
    }
    //console.log('[AvProducerService] avProducer Ping');
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.ping, 'interface', DATA_OBJ);
  }

  /**
   * Sends unsubscribe message to avProducer
   *
   */
  private azzRemoveSubscriptions() {
    console.log('Remove subscriptions');
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, 'all');
  }

  /**
   * Sends subscription messages to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendSubscriptions() {
    console.log('Send subscriptions');
    if (this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, 'all')) {
      let data: IAvMsgDataSubscription | undefined;
      if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.medium) data = {'interval': 60};
      else if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.low) data = {'interval': 140};
      else if (this.commsStatus.lowBandwidthStatus === AvProdLowBandwidthConfig.verylow) data = {'interval': 990};

      for (let i = 0; i < AV_PROD_SUBSCRIPTIONS_BASIC.length; i++) {
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, AV_PROD_SUBSCRIPTIONS_BASIC[i]);
      }

      for (let i = 0; i < this.videoFramesActive.length; i++) {
        let item = '';
        if (this.videoFramesActive[i] >= AV_PROD_FRAME_ID_OUPUT_OFFSET) {
          item = 'output/' + (this.videoFramesActive[i] - AV_PROD_FRAME_ID_OUPUT_OFFSET).toString() + '/frame';
        } else {
          item = 'input/' + this.videoFramesActive[i].toString() + '/frame';
        }
        // Interval depending on LowBandwidth configuration
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, item, data);
      }

      if (this.audioChannelActive != -1) {
        let item: string;
        if (this.audioChannelActive >= AV_PROD_FRAME_ID_OUPUT_OFFSET) {
          if ((this.audioOwnInputCancellation === true) && (this.mediaStreamId !== -1) && (this.mediaPublishing === true)) {
            item = 'input/' + (this.mediaStreamId - 1).toString() + '/audiocancel';
          } else {
            item = 'output/' + (this.audioChannelActive - AV_PROD_FRAME_ID_OUPUT_OFFSET).toString() + '/audio';
          }
        } else {
          item = 'input/' + this.audioChannelActive.toString() + '/audio';
        }
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, item);
      }

      if (this.audioLevelActive === true) {
        for (let i = 0; i < AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL.length; i++) {
          this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL[i]);
        }
      }

    }
  }

  /**
   * Sends initial request messages to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendInitialRequests() {
    for (let i = 0; i < AV_PROD_INITIAL_REQUESTS.length; i++) {
      this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.get, AV_PROD_INITIAL_REQUESTS[i]);
    }
  }

  //////////////////////////////////////////////
  //*          Public Functions              *//
  public getHostUrl(): string {
    if (this.event?.host !== undefined){
      return this.event?.host;
    }
    else {
      return '';
    }
  }

  public getEventTokenViewer(): string {
    if (this.event?.viewerToken !== undefined){
      return this.event?.viewerToken;
    }
    else {
      return '';
    }
  }

  /**
   * Sends a binary message to avProducer
   *
   * @param msgType Character defining message type (frame, video, audio, media, ...)
   * @param id Data identifier
   * @param subId Data sub identifier
   * @param data Binary data array
   *
   * @return boolean (true for OK and false for Error)
   */
  public sendMsgBinary(msgType: string, id: number, subId: number, data: any): boolean {
    let ret = false;

    if (data && data.size > 0) {

      // Add transmitted bytes for stats
      this.commsStatus.stats.txBytes += data.size;

      // Header
      const A_SEED = new Uint8Array(22);
      A_SEED[0] = msgType.charCodeAt(0);
      A_SEED[1] = 0;
      // Timestamp
      const TS_NOW: number = (new Date()).getTime();
      A_SEED[2] = (TS_NOW / (0x10000000000)) & 0xff;
      A_SEED[3] = (TS_NOW / (0x100000000)) & 0xff;
      A_SEED[4] = (TS_NOW >> 24) & 0xff;
      A_SEED[5] = (TS_NOW >> 16) & 0xff;
      A_SEED[6] = (TS_NOW >> 8) & 0xff;
      A_SEED[7] = (TS_NOW >> 0) & 0xff;
      // id
      A_SEED[8] = (id >> 8) & 0xff;
      A_SEED[9] = (id >> 0) & 0xff;
      // Sub id
      A_SEED[10] = (subId >> 24) & 0xff;
      A_SEED[11] = (subId >> 16) & 0xff;
      A_SEED[12] = (subId >> 8) & 0xff;
      A_SEED[13] = (subId >> 0) & 0xff;
      // Reserved
      A_SEED[14] = 0;
      A_SEED[15] = 0;
      A_SEED[16] = 0;
      A_SEED[17] = 0;
      // Data size
      A_SEED[18] = (data.size >> 24) & 0xff;
      A_SEED[19] = (data.size >> 16) & 0xff;
      A_SEED[20] = (data.size >> 8) & 0xff;
      A_SEED[21] = (data.size >> 0) & 0xff;
      // prepending header to blob data
      const FINAL_DATA = new Blob([A_SEED, data]);
      this.wsService.sendMessage(FINAL_DATA);
      ret = true;
    }
    return ret;
  }

  /**
   * Function to add an active video frame subscription
   * @param id Input or output identifier (outputs above AV_PROD_FRAME_ID_OUTPUT_OFFSET)
   */
  public addVideoActive(id: number) {
    if (this.videoFramesActive.includes(id) === false) {
      this.videoFramesActive.push(id);
    }
    let item: string;
    if (id >= AV_PROD_FRAME_ID_OUPUT_OFFSET) {
      item = 'output/' + (id - AV_PROD_FRAME_ID_OUPUT_OFFSET).toString() + '/frame';
    } else {
      item = 'input/' + id.toString() + '/frame';
    }
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, item);
  }

  /**
   * Function to remove an active video frame subscription
   * @param id Input or output identifier (outputs above AV_PROD_FRAME_ID_OUTPUT_OFFSET)
   */
  public removeVideoActive(id: number) {
    let index = -1;
    while ((index = this.videoFramesActive.findIndex((value) => value === id)) != -1) {
      this.videoFramesActive.splice(index, 1);
    }
    let item: string;
    if (id >= AV_PROD_FRAME_ID_OUPUT_OFFSET) {
      item = 'output/' + (id - AV_PROD_FRAME_ID_OUPUT_OFFSET).toString() + '/frame';
    } else {
      item = 'input/' + id.toString() + '/frame';
    }
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, item);
  }

  /**
   * Function to select audio channel to receive and play locally
   * @param id Input or output identifier (outputs above AV_PROD_FRAME_ID_OUTPUT_OFFSET)
   */
  public changeAudioChannelActive(id: number) {
    if (this.audioChannelActive != id) {
      this.audioChannelActive = id;
      this.azzSendSubscriptions();
    }
  }

  /**
   * Function returns active audio channel
   * @returns number
   */
  public getAudioChannelActive(): number {
    return this.audioChannelActive;
  }

  /**
   * Function to select audio channel to receive and play locally
   * @param cancel Flag to enabled / disable echo cancellation
   */
  public changeAudioOwnInputCancellation(cancel: boolean) {
    if (this.audioOwnInputCancellation != cancel) {
      this.audioOwnInputCancellation = cancel;
      this.azzSendSubscriptions();
    }
  }

  /**
   * Function returns audio echo cancellation
   * @returns boolean
   */
  public getAudioOwnInputCancellation(): boolean {
    return this.audioOwnInputCancellation;
  }

  /**
   * Function to change audio level subscriptions
   * @param active Flag to enable or disable audio level unsubcriptions
   */
  public changeAudioLevelActive(active: boolean) {
    this.audioLevelActive = active;
    for (let i = 0; i < AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL.length; i++) {
      if (this.audioLevelActive === true) {
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL[i]);
      } else {
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, AV_PROD_SUBSCRIPTIONS_AUDIO_LEVEL[i]);
      }
    }
  }

  /**
   * Function to change low bandwidth mode
   * @param mode Flag to enable or disable audio level unsubcriptions
   */
  public changeLowBandwidthMode(mode: AvProdLowBandwidthConfig) {
    if ((mode != this.commsStatus.lowBandwidthConfig) ||
      (mode != this.commsStatus.lowBandwidthStatus)) {
      this.commsStatus.lowBandwidthConfig = mode;
      this.commsStatus.lowBandwidthStatus = mode;
      this.commsStatus.lowBandwidthStatusTS = (new Date()).getTime();
      this.azzSendSubscriptions();
      this.onLowBandwidthChange$.next(this.commsStatus.lowBandwidthStatus);
    }
  }

  /**
   * Function to change input settings
   *
   * @param id Input identifier
   * @param settings New input settings
   */
  public azzChangeInputSettings(id: number, settings: IAvProdInputSettings) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.set,
      this.item2string(AvProdDeviceType.input, id, AvProdItemSection.settings, []),
      settings);
  }

  /**
   * Function to request input settings
   *
   * @param id Input identifier
   */
  public azzRequestInputSettings(id: number) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.input, id, AvProdItemSection.settings, []));
  }

  /**
   * Function to request input status
   *
   * @param id Input identifier
   */
  public azzRequestInputStatus(id: number) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.input, id, AvProdItemSection.status, []));
  }

  /**
   * Function to change overlay settings
   *
   * @param id Input identifier
   * @param settings New input settings
   */
  public azzChangeOverlaySettings(id: number, settings: IAvProdOverlaySettings) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.set,
      this.item2string(AvProdDeviceType.overlay, id, AvProdItemSection.settings, []),
      settings);
  }

  /**
   * Function to request overlay settings
   *
   * @param id Input identifier
   */
  public azzRequestOverlaySettings(id: number) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.overlay, id, AvProdItemSection.settings, []));
  }

  /**
   * Function to change output settings
   *
   * @param id Output identifier
   * @param settings New output settings
   */
  public azzChangeOutputSettings(id: number, settings: IAvProdOutputSettings) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.set,
      this.item2string(AvProdDeviceType.output, id, AvProdItemSection.settings, []),
      settings);
  }

  /**
   * Function to change video layout settings
   *
   * @param layoutId Layout identifier
   * @param tileSelection List of inputs selected for video layout
   */
  public azzChangeVideoLayoutSettings(layoutId: number, tileSelection: number[]) {
    const SETTINGS: IAvProdComposerSettings = {
      layoutId: layoutId,
      videoSelection: tileSelection
    }
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.set,
      this.item2string(AvProdDeviceType.composer, 1, AvProdItemSection.settings, []),
      SETTINGS);
  }

  /**
   * Function to change video layout tile with drop user action
   *
   * @param info Input Layout tile drop data
   */
  public azzChangeVideoLayoutDrop(info: IAvProdInputDropInfo) {
    console.log('[AvProducerService] azzChangeVideoLayoutDrop ' + JSON.stringify(info));
    const LAYOUT: IAvProdVideoLayout | undefined = this.layoutManager.videoLayouts.find(element => (element.id === this.composerSettings.layoutId));
    const INPUT: IAvProdInput | undefined = this.inputs.find(element => (element.info.id === info.inputId));
    if ((LAYOUT !== undefined)&&(INPUT !== undefined)){
      for (let i: number = LAYOUT.tiles.length - 1; i >= 0; i--){
        if ((info.x >= LAYOUT.tiles[i].x)&&
            (info.x <= LAYOUT.tiles[i].x + LAYOUT.tiles[i].width)&&
            (info.y >= LAYOUT.tiles[i].y)&&
            (info.y <= LAYOUT.tiles[i].y + LAYOUT.tiles[i].height)){

          // Tile found
          const SEL: number[] | undefined = this.composerSettings.videoSelection;
          if ((this.composerSettings.layoutId !== undefined)&&
              (SEL !== undefined)&&
              (SEL[LAYOUT.tiles[i].id - 1] !== undefined)){

                SEL[LAYOUT.tiles[i].id - 1] = info.inputId;
            this.azzChangeVideoLayoutSettings(this.composerSettings.layoutId, SEL);
            break;
          }
        }
      }
    }
  }

  /**
   * Function to change video layout swaping two tiles
   *
   * @param info Input Layout tile swap data
   */
  public azzChangeVideoLayoutTileSwap(info: IAvProdInputTileSwapInfo) {
    console.log('[AvProducerService] azzChangeVideoLayoutTileSwap ' + JSON.stringify(info));
    const LAYOUT: IAvProdVideoLayout | undefined = this.layoutManager.videoLayouts.find(element => (element.id === this.composerSettings.layoutId));
    if (LAYOUT !== undefined){
      let index1: number = -1;
      let index2: number = -1;
      for (let i: number = LAYOUT.tiles.length - 1; i >= 0; i--){
        if ((info.x1 >= LAYOUT.tiles[i].x)&&
            (info.x1 <= LAYOUT.tiles[i].x + LAYOUT.tiles[i].width)&&
            (info.y1 >= LAYOUT.tiles[i].y)&&
            (info.y1 <= LAYOUT.tiles[i].y + LAYOUT.tiles[i].height)){

          // Tile 1 found
          if (index1 === -1){
            index1 = LAYOUT.tiles[i].id - 1;
          }
        }
        if ((info.x2 >= LAYOUT.tiles[i].x)&&
            (info.x2 <= LAYOUT.tiles[i].x + LAYOUT.tiles[i].width)&&
            (info.y2 >= LAYOUT.tiles[i].y)&&
            (info.y2 <= LAYOUT.tiles[i].y + LAYOUT.tiles[i].height)){

          // Tile 2 found
          if (index2 === -1){
            index2 = LAYOUT.tiles[i].id - 1;
          }
        }
      }

      if ((index1 > -1)&&(index2 > -1)&&
          (index1 !== index2)){
        const SEL: number[] | undefined = this.composerSettings.videoSelection;
        if ((this.composerSettings.layoutId !== undefined)&&
            (SEL !== undefined)&&
            (SEL[index1] !== undefined)&&
            (SEL[index2] !== undefined)){
          const INPUT_ID_1 = SEL[index1];
          const INPUT_ID_2 = SEL[index2];
          SEL[index1] = INPUT_ID_2;
          SEL[index2] = INPUT_ID_1;
          this.azzChangeVideoLayoutSettings(this.composerSettings.layoutId, SEL);
        }
      }
    }
  }

  /**
   * Function to change composer settings
   *
   * @param settings New composer settings
   */
  public azzChangeComposerSettings(settings: IAvProdComposerSettings) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.set,
      this.item2string(AvProdDeviceType.composer, 1, AvProdItemSection.settings, []),
      settings);
  }

  /**
   * Function to request server components
   *
   */
  public azzRequestServerComponents() {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.server, 1, AvProdItemSection.components, []));
  }

  /**
   * Function to request server settings
   *
   */
  public azzRequestServerSettings() {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.server, 1, AvProdItemSection.settings, []));
  }

  /**
   * Function to request composer settings
   *
   */
  public azzRequestComposerSettings() {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.composer, 1, AvProdItemSection.settings, []));
  }

  /**
   * Function to request composer settings options
   *
   */
  public azzRequestComposerSettingsOptions() {
    this.sendMsgAscii(
      AvProdMsgAsciiType.request,
      AvProdMsgAsciiRequest.get,
      this.item2string(AvProdDeviceType.composer, 1, AvProdItemSection.options, []));
  }

  /**
   * Function to refresh server resources
   *
   */
  public azzCmdServerRefreshResources() {
    const CMD: IAvMsgAsciiCommand = {
      command: 'RefreshResources',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.server, 1, CMD);
  }

  /**
   * Function to request server an asset download from archiver
   *
   */
  public azzCmdServerAssetDownload(assetId: number, assetType: AvProdInputType) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'AssetDownload',
      params: {'assetId': assetId, 'assetType': assetType}
    }
    this.azzSendCommand(AvProdDeviceType.server, 1, CMD);
  }

  /**
   * Function to save a video layout preset
   *
   * @param id Preset identifier
   */
  public azzCmdComposerPresetSave(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'LayoutPresetFavoriteSet',
      params: {'presetId': id}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to save a video layout preset
   *
   * @param id Preset identifier
   */
  public azzCmdComposerPresetRemove(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'LayoutPresetFavoriteRemove',
      params: {'presetId': id}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to change video layout preset name
   *
   * @param id Preset identifier
   * @param name Preset new name
   */
  public azzCmdComposerPresetSaveName(id: number, name: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'LayoutPresetFavoriteNameSet',
      params: {'presetId': id, 'name': name}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to apply a video layout preset
   *
   * @param id Preset identifier
   */
  public azzCmdComposerPresetApply(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'LayoutPresetFavoriteApply',
      params: {'presetId': id}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to reset overlay chronometer
   *
   * @param period Game period for chronometer
   * @param current Current chrono time in seconds
   */
  public azzCmdComposerOverlayChronoReset(period: number, current: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'OverlayChronoReset',
      params: {'period': period, 'current': current}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to reset overlay score games
   *
   * @param level reset level
   */
  public azzCmdComposerOverlayScoreReset(level: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ScoreReset',
      params: {'level': level}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }


  /**
   * Function to start overlay chronometer
   *
   */
  public azzCmdComposerOverlayChronoStart() {
    const CMD: IAvMsgAsciiCommand = {
      command: 'OverlayChronoStart',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }
  /**
   * Function to stop overlay chronometer
   *
   */
  public azzCmdComposerOverlayChronoStop() {
    const CMD: IAvMsgAsciiCommand = {
      command: 'OverlayChronoStop',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to create a new time tag
   *
   */
  public azzCmdComposerTimeTagCreateCurrent() {
    const CMD: IAvMsgAsciiCommand = {
      command: 'TimeTagCreateCurrent'
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to remove all time tags
   *
   */
  public azzCmdComposerTimeTagRemoveAll() {
    const CMD: IAvMsgAsciiCommand = {
      command: 'TimeTagRemoveAll'
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to remove a specific time tag
   *
   * @param id: number Time tag identifier
   */
  public azzCmdComposerTimeTagRemove(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'TimeTagRemove',
      params: {'id': id}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to rename a specific time tag
   *
   */
  public azzCmdComposerTimeTagRename(id: number, name: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'TimeTagRename',
      params: {'id': id, 'name': name}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to increase overlay score
   *
   */
  public azzCmdComposerScoreIncrement(teamIndex: number, incr: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ScoreIncrement',
      params: {'teamIndex': teamIndex, 'incr': incr}
    }
    this.azzSendCommand(AvProdDeviceType.composer, 1, CMD);
  }

  /**
   * Function to toggle playing input clip
   *
   * @param id Input identifier
   */
  public azzCmdInputToggleClipPlay(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ClipPlayToggle',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to move previous frame in input clip
   *
   * @param id Input identifier
   */
  public azzCmdInputClipFramePrevious(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ClipFramePrevious',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to move next frame in input clip
   *
   * @param id Input identifier
   */
  public azzCmdInputClipFrameNext(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ClipFrameNext',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to delete input resource
   *
   * @param id Input identifier
   */
  public azzCmdInputDelete(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'Delete',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to request input video clip frames
   *
   * @param id Input identifier
   * @param start Initial video frame
   * @param end Final video frame
   */
  public azzCmdInputClipRequestFrames(id: number, start: number, end: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'ClipRequestFrames',
      params: {
        'frameStart': start,
        'frameEnd': end
      }
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to create input video Web RTC connection
   *
   * @param id Input identifier
   * @param type Viewer or broadcaster connection
   */
  public azzCmdInputWebRtcCreate(id: number, type: AvProdWebRtcConnectionType) {
    console.log('[AvProducerService] azzCmdInputWebRtcCreate ' + id + ' type:' + type);
    if ((type === AvProdWebRtcConnectionType.broadcaster)&&(id === 1)){
      console.log('[AvProducerService] azzCmdInputWebRtcCreate TEST');
    }
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcCreate',
      params: {
        'type': type
      }
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to set description for an input video Web RTC connection
   *
   * @param id Output identifier
   * @param type Viewer or broadcaster connection
   * @param webRtcId Web RTC connection identifier
   * @param answerSdp Web RTC connection answer SDP
   */
  public azzCmdInputWebRtcSetDescription(id: number, type: AvProdWebRtcConnectionType, webRtcId: string, answerSdp: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcSetDescription',
      params: {
        'type': type,
        'id': webRtcId,
        'answerSdp': answerSdp
      }
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to delete an input video Web RTC connection
   *
   * @param id Input identifier
   * @param type Viewer or broadcaster connection
   * @param webRtcId Web RTC connection identifier
   */
  public azzCmdInputWebRtcDelete(id: number, type: AvProdWebRtcConnectionType, webRtcId: string) {
    console.log('[AvProducerService] azzCmdInputWebRtcDelete ' + id + ' type:' + type);
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcDelete',
      params: {
        'type': type,
        'id': webRtcId
      }
    }
    this.azzSendCommand(AvProdDeviceType.input, id, CMD);
  }

  /**
   * Function to create output video Web RTC connection
   *
   * @param id Output identifier
   */
  public azzCmdOutputWebRtcCreate(id: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcCreate',
      params: {}
    }
    this.azzSendCommand(AvProdDeviceType.output, id, CMD);
  }

  /**
   * Function to set description for an output video Web RTC connection
   *
   * @param id Output identifier
   * @param webRtcId Web RTC connection identifier
   * @param answerSdp Web RTC connection answer SDP
   */
  public azzCmdOutputWebRtcSetDescription(id: number, webRtcId: string, answerSdp: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcSetDescription',
      params: {
        'id': webRtcId,
        'answerSdp': answerSdp
      }
    }
    this.azzSendCommand(AvProdDeviceType.output, id, CMD);
  }

  /**
   * Function to delete an output video Web RTC connection
   *
   * @param id Output identifier
   * @param webRtcId Web RTC connection identifier
   */
  public azzCmdOutputWebRtcDelete(id: number, webRtcId: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'WebRtcDelete',
      params: {
        'id': webRtcId
      }
    }
    this.azzSendCommand(AvProdDeviceType.output, id, CMD);
  }

  /**
   * Function to request media stream identifier to start publishing
   *
   */
  public azzCmdInterfaceRequestMediaStreamId(slotIndex: number, slotId: string, streamId: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'RequestMediaStreamId',
      params: {slotIndex: slotIndex, slotId: slotId, streamId: streamId}
    }
    this.azzSendCommand(AvProdDeviceType.interface, 1, CMD);
  }

  /**
   * Function to start publishing using a specific streamId
   *
   * @param streamId: Publication stream identifier
   * @param mode
   */
  public azzCmdInterfacePublishStart(streamId: number, mode: number, slotIndex: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'PublishStart',
      params: {streamId: streamId, mode: mode, slotIndex: slotIndex} // Transmission mode 2 for WebRTC
    }
    this.azzSendCommand(AvProdDeviceType.interface, 1, CMD);
    this.mediaPublishing = true;
    if ((this.audioChannelActive != -1) && (this.audioOwnInputCancellation === true)) {
      this.azzSendSubscriptions();
    }
  }

  /**
   * Function to end publication using a specific streamId
   *
   * @param streamId: Publication stream identifier
   */
  public azzCmdInterfacePublishEnd(streamId: number, slotIndex: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'PublishEnd',
      params: {streamId: streamId, slotIndex: slotIndex}
    }
    this.azzSendCommand(AvProdDeviceType.interface, 1, CMD);
    this.mediaPublishing = false;
    if ((this.audioChannelActive != -1) && (this.audioOwnInputCancellation === true)) {
      this.azzSendSubscriptions();
    }
  }

  /**
   * Function to send a comment or chat message
   *
   * @param devId: Messaging system identifier
   * @param originUserId: Origin user identifier
   * @param originUserName: Origin user name
   * @param text: Message text
   */
  public azzCmdMessagingSend(devId: number, originUserId: number, originUserName: string, text: string) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'SendMessage',
      params: {originUserId: originUserId, originUserName: originUserName, text: text}
    }
    this.azzSendCommand(AvProdDeviceType.msg, devId, CMD);
  }

  /**
   * Function to remove a comment or chat message
   *
   * @param devId: Messaging system identifier
   * @param msgId: Message identifier
   */
  public azzCmdMessagingRemove(devId: number, msgId: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'RemoveMessage',
      params: {msgId: msgId}
    }
    this.azzSendCommand(AvProdDeviceType.msg, devId, CMD);
  }

  /**
   * Function to move favorite layout up
   *
   * @param index: Favorite layout index
   */
  public azzCmdLayoutFavorietMoveUp(index: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'FavoriteMoveUp',
      params: {index: index}
    }
    this.azzSendCommand(AvProdDeviceType.layoutmanager, 1, CMD);
  }

  /**
   * Function to move favorite layout down
   *
   * @param index: Favorite layout index
   */
  public azzCmdLayoutFavorietMoveDown(index: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'FavoriteMoveDown',
      params: {index: index}
    }
    this.azzSendCommand(AvProdDeviceType.layoutmanager, 1, CMD);
  }

  /**
   * Function to move favorite layout to first position
   *
   * @param index: Favorite layout index
   */
  public azzCmdLayoutFavorietMoveTop(index: number) {
    const CMD: IAvMsgAsciiCommand = {
      command: 'FavoriteMoveTop',
      params: {index: index}
    }
    this.azzSendCommand(AvProdDeviceType.layoutmanager, 1, CMD);
  }

  /**
   * Function to send notification of client new publication settings
   * @param publish Client publication data
   */
  public azzNotifyClientPublish(publish: IAvProdInterfaceStatusPublish) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.notification,
      AvProdMsgAsciiRequest.notification,
      this.item2string(AvProdDeviceType.client, this.clientInfo.clientId, AvProdItemSection.publish, []),
      publish);
  }

  /**
   * Function to send notification of client new status
   * @param status Client status data
   */
  public azzNotifyClientStatus(status: IAvProdInterfaceClientDeviceStatus) {
    this.sendMsgAscii(
      AvProdMsgAsciiType.notification,
      AvProdMsgAsciiRequest.notification,
      this.item2string(AvProdDeviceType.client, this.clientInfo.clientId, AvProdItemSection.status, []),
        status);
  }

  /**
   * Function to set audio stereo option in Web-RTC connection
   *
   * @param sdp
   * @returns
   */
  protected enableStereoOpus(sdp: string | undefined): string {
    if (sdp !== undefined) {
      return sdp.replace(/a=fmtp:111/, 'a=fmtp:111 stereo=1\r\na=fmtp:111');
    } else {
      return '';
    }
  }

  protected onWebRtcDataChannel(type: AvProdDeviceType, id: number, event: RTCDataChannelEvent) {
    console.log('[AvProducerService] onWebRtcDataChannel ' + event.channel.label + ' Type:' + type + ' Id:' + id);
    let rtcViewer: IAvProdWebRtcViewerData;
    if (type === AvProdDeviceType.input){
      rtcViewer = this.webRtcInputs[id].viewerManager;
    }
    else if (type === AvProdDeviceType.output){
      rtcViewer = this.webRtcOutputs[id].viewerManager;
    }
    else{
      return;
    }

    if (event.channel.label === 'video') {
      rtcViewer.video.firstTS = 0;
      rtcViewer.video.decoder = null;
      if (rtcViewer.video.frame$ === undefined){
        rtcViewer.video.frame$ = new Subject();
      }
      rtcViewer.video.channel = event.channel;
      rtcViewer.video.channel.onmessage = this.onWebRtcVideoReceived.bind(this, type, id, rtcViewer);
      rtcViewer.video.channel.onclose = this.onWebRtcChannelClose.bind(this, type, id);
    }
    else if (event.channel.label === 'audio') {
      if (rtcViewer.audio.data$ === undefined){
        rtcViewer.audio.data$ = new Subject();
      }
      rtcViewer.audio.channel = event.channel;
      rtcViewer.audio.channel.onmessage = this.onWebRtcAudioReceived.bind(this, type, id, rtcViewer);
      rtcViewer.audio.channel.onclose = this.onWebRtcChannelClose.bind(this, type, id);
      if (rtcViewer.audio.active === true){
        this.webRtcConnectionChannelStartStop(AvProdWebRtcConnectionType.viewer, type, id, 'audio', 'start');
      }
      else {
        this.webRtcConnectionChannelStartStop(AvProdWebRtcConnectionType.viewer, type, id, 'audio', 'stop');
      }
      // if (type === AvProdDeviceType.output){
      //   this.webRtcConnectionChannelStartStop(AvProdWebRtcConnectionType.viewer, type, id, 'audio', 'start');
      // }
      // else if (type === AvProdDeviceType.input){
      //   this.webRtcConnectionChannelStartStop(AvProdWebRtcConnectionType.viewer, type, id, 'audio', 'stop');
      // }
    }
  }

  protected onWebRtcVideoFrameReceived(rtcViewer: IAvProdWebRtcViewerData, event: VideoFrame) {
    if (rtcViewer.video.frame$ !== undefined){
      rtcViewer.video.frame$.next(event);
    }
    //event.close();
  }

  protected async onWebRtcAudioReceived(devType: AvProdDeviceType, devId: number, rtcViewer: IAvProdWebRtcViewerData, event: MessageEvent) {

    if (rtcViewer.audio.active === true){
      const aux = new Uint8Array(event.data.slice(0,20));
      const s = (aux[4]<<24)+(aux[5]<<16)+(aux[6]<<8)+(aux[7]);
      const t: number = (aux[8]*(2**56))+(aux[9]*(2**48))+(aux[10]*(2**40))+(aux[11]*(2**32))+(aux[12]*(2**24))+(aux[13]*(2**16))+(aux[14]*(2**8))+(aux[15]);
      //let codec: number = aux[16];
      const data = new Uint8Array(event.data.slice(20));
      const NOW = Date.now();
      //console.log('[AvProducerService] onWebRtcAudioReceived ' + devType + '/' + devId + ':' + data.byteLength + ' ' + t + ' ' + s);
      if (rtcViewer.audio.data$ !== undefined){
        if (s === data.byteLength){
          //if (devType === AvProdDeviceType.output){
          //  console.log('[AvProducerService] onWebRtcAudioReceived - AUDIO ' + devType + '/' + devId + ' Offset:' + (NOW - t) + ' Interval:' + (NOW - rtcViewer.audio.lastLocalTS) + ' buffer:' + rtcViewer.audio.channel?.bufferedAmount);
          //}
          rtcViewer.audio.lastLocalTS = NOW;
          rtcViewer.audio.lastTS = t;
          rtcViewer.audio.data$.next(data);
          const NOW2 = Date.now();
          if (NOW2 - NOW > 1){
            console.log('[AvProducerService] Audio Received Too MUCH Time: ' + (NOW2 - NOW));
          }
        }
      }
    }
    else {
      this.webRtcConnectionChannelStartStop(AvProdWebRtcConnectionType.viewer, devType, devId, 'audio', 'stop');
    }
  }

  protected async onWebRtcChannelClose(devType: AvProdDeviceType, devId: number, event: Event) {
    console.log('[AvProducerService] onWebRtcChannelClose: ' + devType + '/' + devId);
  }

  protected async onWebRtcVideoReceived(devType: AvProdDeviceType, devId: number, rtcViewer: IAvProdWebRtcViewerData, event: MessageEvent) {
    if (event.data.byteLength < 20) {
      return;
    }
    const NOW = Date.now();
    const aux = new Uint8Array(event.data.slice(0,20));
    const w = (aux[0]<<8)+aux[1];
    const h = (aux[2]<<8)+aux[3];
    const s = (aux[4]<<24)+(aux[5]<<16)+(aux[6]<<8)+(aux[7]);
    //let t: number = (aux[8]<<56)+(aux[9]<<48)+(aux[10]<<40)+(aux[11]<<32)+(aux[12]<<24)+(aux[13]<<16)+(aux[14]<<8)+(aux[15]);
    let t: number = (aux[8]*(2**56))+(aux[9]*(2**48))+(aux[10]*(2**40))+(aux[11]*(2**32))+(aux[12]*(2**24))+(aux[13]*(2**16))+(aux[14]*(2**8))+(aux[15]);
    const codec = aux[16];
    const keyFrame = aux[17];

    if (devType === AvProdDeviceType.output){
      //console.log('[AvProducerService] onWebRtcVideoReceived - VIDEO ' + devType + '/' + devId + ' Offset:' + (NOW - t) + ' Interval:' + (NOW - rtcViewer.video.lastLocalTS) + ' buffer:' + rtcViewer.video.channel?.bufferedAmount);
    }
    rtcViewer.video.lastLocalTS = NOW;

    if (rtcViewer.video.decoder === null || w !== rtcViewer.video.width || h !== rtcViewer.video.height) {
      rtcViewer.video.decoder = null;
      rtcViewer.video.firstTS = 0;
      console.log('[AvProducerService] New Video data RESET ' + devType + '/' + devId + ': w' + w + ' ' + h + ' ' + s + ' ' + t);

      let codecStr: string = '';
      if (codec === 2){
        codecStr = 'vp8';
      }
      else{
        codecStr = 'avc1.4d401f';
      }
      // Initialize decoder
      const init = {
        output: this.onWebRtcVideoFrameReceived.bind(this, rtcViewer),
        error: (e: any) => {
          console.error('Viewer Decoder ERROR:' + devType + '/' + devId  + ':' + e.message);
          rtcViewer.video.decoder=null;
          rtcViewer.video.firstTS=0;
          if (devType === AvProdDeviceType.input) this.webRtcConnectionForceKeyFrame(devType, devId);
        },
      };

      const config = {
        codec: codecStr,
        codedWidth: w,
        codedHeight: h,
        avc:{format: 'annexb'}
      };

      const { supported } = await VideoDecoder.isConfigSupported(config);

      if (supported) {
        rtcViewer.video.decoder = new VideoDecoder(init);
        rtcViewer.video.decoder.configure(config);
        rtcViewer.video.width=w;
        rtcViewer.video.height=h;
      }
      else {
        console.error('[AvProducerService] Video decoder configuration is not valid');
      }
      //return;
    }

    const NEW_DATA = new Uint8Array(event.data.slice(20));
    if (NEW_DATA.byteLength!=s) {
      console.error('[AvProducerService] WebRtc Video Data Wrong size (' + devType + '/' + devId + ') Header:'+ s +' Actual:'+NEW_DATA.byteLength);
    }

    const CHUNK_DATA: Uint8Array = new Uint8Array(NEW_DATA);

    //let type: EncodedVideoChunkType = 'delta';
    let type: any = 'delta';
    if (keyFrame !== 0)
    {
      //console.log('[AvProducerService] WebRtc Video Data KF (' + devType + '/' + devId + ')');
      type = 'key';
      if (rtcViewer.video.firstTS == 0) {
        rtcViewer.video.firstTS = t;
      }
    }

    /*
    if (CHUNK_DATA[6] === 0x00 && CHUNK_DATA[7] === 0x00 && CHUNK_DATA[8] === 0x00 && CHUNK_DATA[9] === 0x01 && CHUNK_DATA[10] === 0x67) {
      type = 'key';
      if (rtcViewer.video.firstTS == 0) {
        rtcViewer.video.firstTS = t;
      }
    }
    else {
      type = 'delta';
    }*/

    if (rtcViewer.video.firstTS == 0) return;

    if (rtcViewer.video.lastTS > t && rtcViewer.video.lastTS !== 0) {
      console.log('[AvProducerService] Video Received Wrong timestamp ' + devType + '/' + devId + t + ' ' + rtcViewer.video.lastTS);
    }
    // Temporary debug for repeating timestamps
    if (rtcViewer.video.lastTS == t){
      console.log('[AvProducerService] Video Received SAME timestamp ' + devType + '/' + devId + ' ' + t);
    }
    /////////////////////////////////////////
    rtcViewer.video.lastTS = t;
    t = t - rtcViewer.video.firstTS;

    const ENCODED_CHUNK = new EncodedVideoChunk({
      timestamp: t,
      type: type,
      data: CHUNK_DATA
    });

    if (rtcViewer.video.decoder) {
      if (rtcViewer.video.decoder.decodeQueueSize > 5){
        console.log('[AvProducerService] Video decoder queue: ' + rtcViewer.video.decoder.decodeQueueSize + ' ' + devType + '/' + devId);
        if (keyFrame !== 0){
          rtcViewer.video.decoder.decode(ENCODED_CHUNK);
          console.log('[AvProducerService] Video decoder queue full, but KEY frame');
        }
      }
      else{
        rtcViewer.video.decoder.decode(ENCODED_CHUNK);
      }
    }

    const NOW2 = Date.now();
    if (NOW2 - NOW > 2){
      console.log('[AvProducerService] Video Received Too MUCH Time: ' + (NOW2 - NOW));
    }
  }

  /**
   * Function to handle web RTC connection description answer
   *
   * @param msg Web RTC peer type (broadcaster, viewer)
   */
  protected async webRtcConnectionSetDescriptionHandleAnswer(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    const CONNECTION_TYPE: AvProdWebRtcConnectionType = msg.dataTx.params.type;
    const INOUT_TYPE: AvProdDeviceType = ITEM.deviceType;
    const DEVICE_ID: number = ITEM.deviceId;

    console.log('[AvProducerService] webRtcConnectionSetDescriptionHandleAnswer InOut:' + INOUT_TYPE + ' Id:' + DEVICE_ID);
    if (INOUT_TYPE === AvProdDeviceType.output) {
      this.webRtcOutputs[DEVICE_ID].viewer.active = true;
    }
    else if (INOUT_TYPE === AvProdDeviceType.input) {
      if (CONNECTION_TYPE === AvProdWebRtcConnectionType.broadcaster) {
        this.webRtcInputs[DEVICE_ID].broadcaster.active = true;
      }
      else {
        this.webRtcInputs[DEVICE_ID].viewer.active = true;
      }
    }
  }

  /**
   * Function to handle web RTC connection delete answer
   *
   * @param msg Web RTC peer type (broadcaster, viewer)
   */
  protected async webRtcConnectionDeleteHandleAnswer(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    const CONNECTION_TYPE: AvProdWebRtcConnectionType = msg.dataTx.params.type;
    const INOUT_TYPE: AvProdDeviceType = ITEM.deviceType;
    const DEVICE_ID: number = ITEM.deviceId;

    console.log('[AvProducerService] webRtcConnectionDeleteHandleAnswer InOut:' + INOUT_TYPE + ' Id:' + DEVICE_ID);
    if (INOUT_TYPE === AvProdDeviceType.output) {
      this.webRtcOutputs[DEVICE_ID].viewer.active = false;
      this.webRtcOutputs[DEVICE_ID].viewer.id = '';
      this.webRtcOutputs[DEVICE_ID].viewer.clientSdp = '';
      this.webRtcOutputs[DEVICE_ID].viewer.serverSdp = '';
      this.webRtcOutputs[DEVICE_ID].viewer.lastClientPingTS = 0;
      this.webRtcOutputs[DEVICE_ID].viewer.lastStartTS = 0;
      this.webRtcOutputs[DEVICE_ID].viewer.peerConnection = undefined;
    }
    else if (INOUT_TYPE === AvProdDeviceType.input) {
      if (CONNECTION_TYPE === AvProdWebRtcConnectionType.broadcaster) {
        this.webRtcInputs[DEVICE_ID].broadcaster.active = false;
        this.webRtcInputs[DEVICE_ID].broadcaster.id = '';
        this.webRtcInputs[DEVICE_ID].broadcaster.clientSdp = '';
        this.webRtcInputs[DEVICE_ID].broadcaster.serverSdp = '';
        this.webRtcInputs[DEVICE_ID].broadcaster.lastClientPingTS = 0;
        this.webRtcInputs[DEVICE_ID].broadcaster.lastStartTS = 0;
        this.webRtcInputs[DEVICE_ID].broadcaster.peerConnection = undefined;
        }
      else {
        this.webRtcInputs[DEVICE_ID].viewer.active = false;
        this.webRtcInputs[DEVICE_ID].viewer.id = '';
        this.webRtcInputs[DEVICE_ID].viewer.clientSdp = '';
        this.webRtcInputs[DEVICE_ID].viewer.serverSdp = '';
        this.webRtcInputs[DEVICE_ID].viewer.lastClientPingTS = 0;
        this.webRtcInputs[DEVICE_ID].viewer.lastStartTS = 0;
        this.webRtcInputs[DEVICE_ID].viewer.peerConnection = undefined;
      }
    }
  }

  /**
   * Function to handle web RTC connection creation answer
   *
   * @param msg Web RTC peer type (broadcaster, viewer)
   */
  protected async webRtcConnectionCreateHandleAnswer(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    let connectionType: AvProdWebRtcConnectionType = msg.dataTx.params.type;
    const INOUT_TYPE: AvProdDeviceType = ITEM.deviceType;
    const OFFER_SDP: string = msg.data.offerSdp.replace('a=extmap:13 urn:3gpp:video-orientation\r\n','');
    const WEB_RTC_ID: string = msg.data.id;
    const DEVICE_ID: number = ITEM.deviceId;

    if ((OFFER_SDP === '')||(OFFER_SDP === undefined)||(WEB_RTC_ID === '')||(WEB_RTC_ID === undefined)){
      console.log('[AvProducerService] Empty PARAMS ' + INOUT_TYPE + ' Id:' + DEVICE_ID + ' ConnType:' + connectionType);
      return;
    }

    if (connectionType === undefined) {
      connectionType = AvProdWebRtcConnectionType.viewer;
    }

    console.log('[AvProducerService] webRtcConnectionCreateHandleAnswer InOut:' + INOUT_TYPE + ' Id:' + DEVICE_ID + ' RTC Id:' + WEB_RTC_ID);
    //console.log('[AvProducerService] webRtcConnectionCreateHandleAnswer SDP:' + OFFER_SDP);
    /*
    const TEMP_CONNECTION: IAvProdWebRtcConnection = {
      active: true,
      id: WEB_RTC_ID,
      clientSdp: '',
      serverSdp: OFFER_SDP,
    }
    */
    const TEMP_PEER_CONNECTION: RTCPeerConnection  = new RTCPeerConnection({
      //sdpSemantics: 'unified-plan'
    });

    try {
      await TEMP_PEER_CONNECTION.setRemoteDescription({'type': 'offer', 'sdp': OFFER_SDP});
      if (connectionType === AvProdWebRtcConnectionType.broadcaster) {
        if (this.webRtcInputs[DEVICE_ID].broadcasterStream !== undefined) {
          const TMP_MEDIA_STREAM: MediaStream = this.webRtcInputs[DEVICE_ID].broadcasterStream!;   // Joaquin: No logro proteger frente a undefined
          TMP_MEDIA_STREAM.getTracks().forEach(track => TEMP_PEER_CONNECTION.addTrack(track));
        }
      }

      const originalAnswer: RTCSessionDescriptionInit = await TEMP_PEER_CONNECTION.createAnswer();
      const STEREO: boolean = true;
      const updatedAnswer = new RTCSessionDescription({
        type: 'answer',
        sdp: STEREO ? this.enableStereoOpus(originalAnswer.sdp) : originalAnswer.sdp
      });
      await TEMP_PEER_CONNECTION.setLocalDescription(updatedAnswer);
      //TEMP_CONNECTION.clientSdp = updatedAnswer.sdp;

      if (connectionType === AvProdWebRtcConnectionType.viewer) {
        // Set event handlers and decoder for viewers
        TEMP_PEER_CONNECTION.onconnectionstatechange = function (event) {
          console.log('[AvProducerService] RTC State changed:  InOut:' + INOUT_TYPE + ' Id:' + DEVICE_ID + ' ' + TEMP_PEER_CONNECTION.connectionState);
          
        }
        TEMP_PEER_CONNECTION.onsignalingstatechange = function (event) {
          console.log('[AvProducerService] RTC Signalling State changed: ' + JSON.stringify(event));
        }
        TEMP_PEER_CONNECTION.onnegotiationneeded = function (event) {
          console.log('[AvProducerService] RTC Negotiation needed: ' + JSON.stringify(event));
        }
        TEMP_PEER_CONNECTION.ondatachannel = this.onWebRtcDataChannel.bind(this, INOUT_TYPE, DEVICE_ID);
      }

      if (INOUT_TYPE === AvProdDeviceType.output) {
        this.webRtcOutputs[DEVICE_ID].viewer.peerConnection = TEMP_PEER_CONNECTION;
        this.webRtcOutputs[DEVICE_ID].viewer.clientSdp = updatedAnswer.sdp;
        this.webRtcOutputs[DEVICE_ID].viewer.id = WEB_RTC_ID;
        this.azzCmdOutputWebRtcSetDescription(DEVICE_ID, WEB_RTC_ID, updatedAnswer.sdp);
      }
      else if (INOUT_TYPE === AvProdDeviceType.input) {
        if (connectionType === AvProdWebRtcConnectionType.broadcaster) {
          this.webRtcInputs[DEVICE_ID].broadcaster.peerConnection = TEMP_PEER_CONNECTION;
          this.webRtcInputs[DEVICE_ID].broadcaster.clientSdp = updatedAnswer.sdp;
          this.webRtcInputs[DEVICE_ID].broadcaster.id = WEB_RTC_ID;
        }
        else {
          this.webRtcInputs[DEVICE_ID].viewer.peerConnection = TEMP_PEER_CONNECTION;
          this.webRtcInputs[DEVICE_ID].viewer.clientSdp = updatedAnswer.sdp;
          this.webRtcInputs[DEVICE_ID].viewer.id = WEB_RTC_ID;
        }
        this.azzCmdInputWebRtcSetDescription(DEVICE_ID, connectionType, WEB_RTC_ID, updatedAnswer.sdp);
      }

    }
    catch (error) {
      console.log('[AvProducerService] (webRtcConnectionCreateHandleAnswer) Catch error: ' + JSON.stringify(error));
      TEMP_PEER_CONNECTION.close();
      throw error;
    }

  }

  /**
   * Function to set the audio cancel of a web RTC connection
   *
   * @param type Web RTC peer type (broadcaster, viewer)
   * @param inout Input or output channel
   * @param id Input or output identifier
   * @param cancelId Input identifier to cancel (0 means no cancel)
   */
  public webRtcConnectionAudioCancel(type: AvProdWebRtcConnectionType, inoutType: AvProdDeviceType, id: number, cancelId: number) {
    if ((inoutType === AvProdDeviceType.output)&&
        (type === AvProdWebRtcConnectionType.viewer)&&
        (this.webRtcOutputs[id] !== undefined)){
      const CMD: Object = {
        'action': 'cancelaudio',
        'id': cancelId
      }
      //console.log('[AvProducerService] webRtcConnectionAudioCancel ' + JSON.stringify(CMD));
      try{
        this.webRtcOutputs[id].viewerManager.audio.channel?.send(JSON.stringify(CMD));
      }
      catch(err){
        console.log('[AvProducerService] webRtcConnectionAudioCancel ERROR ' + JSON.stringify(err));
      }
    }
  }

  /**
   * Function to start/stop channel transmission of a web RTC connection
   *
   * @param type Web RTC peer type (broadcaster, viewer)
   * @param inout Input or output channel
   * @param id Input or output identifier
   * @param channel Channel selector (audio, video)
   * @param action Action (start, stop)
   */
  public webRtcConnectionChannelStartStop(type: AvProdWebRtcConnectionType, inoutType: AvProdDeviceType, id: number, channel: string, action: string) {
    if ((action !== 'start')&&(action !== 'stop')){
      return;
    }

    if (type === AvProdWebRtcConnectionType.viewer){
      let rtcChannel: RTCDataChannel | null = null;
      const CMD: Object = {
        'action': action
      }
      if ((inoutType === AvProdDeviceType.output)&&
          (this.webRtcOutputs[id] !== undefined)){
            if (channel === 'audio'){
              rtcChannel = this.webRtcOutputs[id].viewerManager.audio.channel;
            }
            else if (channel === 'video'){
              rtcChannel = this.webRtcOutputs[id].viewerManager.video.channel;
            }
      }
      else if ((inoutType === AvProdDeviceType.input)&&
          (this.webRtcInputs[id] !== undefined)){
            if (channel === 'audio'){
              rtcChannel = this.webRtcInputs[id].viewerManager.audio.channel;
            }
            else if (channel === 'video'){
              rtcChannel = this.webRtcInputs[id].viewerManager.video.channel;
            }
      }

      //console.log('[AvProducerService] webRtcConnectionAudioCancel ' + JSON.stringify(CMD));
      try{
        rtcChannel?.send(JSON.stringify(CMD));
      }
      catch(err){
        console.log('[AvProducerService] webRtcConnectionChannelStartStop ERROR ' + JSON.stringify(err));
      }
    }
  }

  /**
   * Function to request a video frame in a viewer web RTC connection
   *
   * @param inout Input or output channel
   * @param id Input or output identifier
   */
  public webRtcConnectionForceKeyFrame(inoutType: AvProdDeviceType, id: number) {
    const CMD: Object = {
      'action': 'forcekeyframe'
    }
    if ((inoutType === AvProdDeviceType.output)&&
        (this.webRtcOutputs[id] !== undefined)){
      console.log('[AvProducerService] webRtcConnectionForceKeyFrame (' + inoutType + ' ' + id + ')' + JSON.stringify(CMD));
      try{
        this.webRtcOutputs[id].viewerManager.video.channel?.send(JSON.stringify(CMD));
      }
      catch(err){
        console.log('[AvProducerService] webRtcConnectionForceKeyFrame ERROR ' + JSON.stringify(err));
      }
    }
    else if ((inoutType === AvProdDeviceType.input)&&
        (this.webRtcInputs[id] !== undefined)){
      console.log('[AvProducerService] webRtcConnectionForceKeyFrame (' + inoutType + ' ' + id + ')' + JSON.stringify(CMD));
      try{
        this.webRtcInputs[id].viewerManager.video.channel?.send(JSON.stringify(CMD));
      }
      catch(err){
        console.log('[AvProducerService] webRtcConnectionForceKeyFrame ERROR ' + JSON.stringify(err));
      }
    }
  }

  /**
   * Function to ping and confirm the usage of a web RTC connection
   *
   * @param type Web RTC peer type (broadcaster, viewer)
   * @param inout Input or output channel
   * @param id Input or output identifier
   * @param mediaStream
   * @param viewerAudioEnabled Enable or disable audio reception in viewer connections
   */
  public webRtcConnectionPing(type: AvProdWebRtcConnectionType, inoutType: AvProdDeviceType, id: number, mediaStream: MediaStream | undefined, viewerAudioEnabled: boolean) {
    //console.log('[AvProducerService] webRtcConnectionPing ' + inoutType + '/' + id);
    const TIME_NOW: number = (new Date()).getTime();
    if ((inoutType === AvProdDeviceType.output)&&
        (this.webRtcOutputs[id] === undefined)) {
          this.webRtcOutputs[id] = {
            viewer: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            viewerManager: {
              video: {
                width: 0,
                height: 0,
                channel: null,
                decoder: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                partialVideoData: null,
                frame$: new Subject()
              },
              audio: {
                active: viewerAudioEnabled,
                channel: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                data$: new Subject()
              }
            }
          }
    }
    else if ((inoutType === AvProdDeviceType.input)&&
        (this.webRtcInputs[id] === undefined)) {
          this.webRtcInputs[id] = {
            viewer: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            broadcaster: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            viewerManager: {
              video: {
                width: 0,
                height: 0,
                channel: null,
                decoder: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                partialVideoData: null,
                frame$: new Subject()
              },
              audio: {
                active: viewerAudioEnabled,
                channel: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                data$: new Subject()
              }
            }
          }
    }

    if (type === AvProdWebRtcConnectionType.broadcaster) {
      if (inoutType === AvProdDeviceType.input) {
        if (this.webRtcInputs[id].broadcasterStream !== mediaStream){
          let needReconnect: boolean = false;
          this.webRtcInputs[id].broadcasterStream = mediaStream;
          if (this.webRtcInputs[id].broadcaster.peerConnection !== undefined){
            const VIDEO_SENDER: RTCRtpSender | undefined = this.webRtcInputs[id].broadcaster.peerConnection?.getSenders().filter(sender => sender.track?.kind === 'video')[0];
            const VIDEO_TRACK: MediaStreamTrack | undefined = this.webRtcInputs[id].broadcasterStream?.getVideoTracks()[0];
            if ((VIDEO_SENDER !== undefined)&&(VIDEO_TRACK !== undefined)){
              console.error('[AvProducerService] webRtcConnectionPing - Replacing video track');
              try{
                VIDEO_SENDER.replaceTrack(VIDEO_TRACK);
              }
              catch(err){
                needReconnect = true;
                console.error('[AvProducerService] webRtcConnectionPing - Error replacing video track ' + err);
              }

              //const PARAMS = VIDEO_SENDER.getParameters();
              //if (!PARAMS.encodings) {
              //  PARAMS.encodings = [{}];
              //}
              //PARAMS.encodings[0].maxBitrate = 1000 * 1000; // bps
              //VIDEO_SENDER.setParameters(PARAMS);
            }
            else if ((VIDEO_SENDER === undefined)&&(VIDEO_TRACK !== undefined)){
              try{
                needReconnect = true;
                this.webRtcInputs[id].broadcaster.peerConnection?.addTrack(VIDEO_TRACK);
              }
              catch(err){
                console.error('[AvProducerService] webRtcConnectionPing - Error adding video track ' + err);
              }
            }
            //else if ((VIDEO_SENDER !== undefined)&&(VIDEO_TRACK === undefined)){
            //  this.webRtcInputs[id].broadcaster.peerConnection?.removeTrack(VIDEO_SENDER);
            //}

            const AUDIO_SENDER: RTCRtpSender | undefined = this.webRtcInputs[id].broadcaster.peerConnection?.getSenders().filter(sender => sender.track?.kind === 'audio')[0];
            const AUDIO_TRACK: MediaStreamTrack | undefined = this.webRtcInputs[id].broadcasterStream?.getAudioTracks()[0];
            if ((AUDIO_SENDER !== undefined)&&(AUDIO_TRACK !== undefined)){
              console.error('[AvProducerService] webRtcConnectionPing - Replacing audio track');
              try{
                AUDIO_SENDER.replaceTrack(AUDIO_TRACK);
              }
              catch(err){
                needReconnect = true;
                console.error('[AvProducerService] webRtcConnectionPing - Error replacing audio track ' + err);
              }
            }
            else if ((AUDIO_SENDER === undefined)&&(AUDIO_TRACK !== undefined)){
              try{
                needReconnect = true;
                this.webRtcInputs[id].broadcaster.peerConnection?.addTrack(AUDIO_TRACK);
              }
              catch(err){
                console.error('[AvProducerService] webRtcConnectionPing - Error adding audio track ' + err);
              }
            }
            //else if ((AUDIO_SENDER !== undefined)&&(AUDIO_TRACK === undefined)){
            //  this.webRtcInputs[id].broadcaster.peerConnection?.removeTrack(AUDIO_SENDER);
            //}

            if (needReconnect === true){
              this.azzCmdInputWebRtcDelete(id, AvProdWebRtcConnectionType.broadcaster, this.webRtcInputs[id].broadcaster.id);
              /*
              this.webRtcInputs[id].broadcaster.peerConnection?.createAnswer().then( ans => {
                const originalAnswer: RTCSessionDescriptionInit = ans;
                const STEREO: boolean = true;
                const updatedAnswer = new RTCSessionDescription({
                  type: 'answer',
                  sdp: STEREO ? this.enableStereoOpus(originalAnswer.sdp) : originalAnswer.sdp
                });
                this.webRtcInputs[id].broadcaster.peerConnection?.setLocalDescription(updatedAnswer).then(() => {
                  this.webRtcInputs[id].broadcaster.clientSdp = updatedAnswer.sdp;
                  this.azzCmdInputWebRtcSetDescription(this.webRtcInputs[id].broadcaster.deviceId, AvProdWebRtcConnectionType.broadcaster, this.webRtcInputs[id].broadcaster.id, updatedAnswer.sdp);
                });
              }).catch(err => console.error(err));
              */
            }
          }
        }
        this.webRtcInputs[id].broadcaster.lastClientPingTS = TIME_NOW;
      }
      else {
        // Error: Output channel cannot be broadcasted
        return false;
      }
    }
    else if (type === AvProdWebRtcConnectionType.viewer) {
      if (inoutType === AvProdDeviceType.output) {
        this.webRtcOutputs[id].viewer.lastClientPingTS = TIME_NOW;
        this.webRtcOutputs[id].viewerManager.audio.active = viewerAudioEnabled;
      }
      else if (inoutType === AvProdDeviceType.input) {
        this.webRtcInputs[id].viewer.lastClientPingTS = TIME_NOW;
        this.webRtcInputs[id].viewerManager.audio.active = viewerAudioEnabled;
      }
    }

    return true;
  }

  /**
   * Function called periodically to check possible actions with Web RTC connections
   *
   */
  protected tickWebRtcCheck() {
    const TIME_NOW: number = (new Date()).getTime();
    const MAX_TIMEOUT_PING: number = 3000;
    const MAX_TIMEOUT_TO_START: number = 5000;

    // Check Web RTC inputs
    this.webRtcInputs.forEach(element => {
      // Check broadcaster
      if (element.broadcaster.lastClientPingTS !== 0){
        if (TIME_NOW - element.broadcaster.lastClientPingTS < MAX_TIMEOUT_PING) {
          // Client component needs this connection
          if ((element.broadcaster.peerConnection?.connectionState === 'connected')||
              (element.broadcaster.peerConnection?.connectionState === 'connecting')) {
            // Web RTC is connected and working OK
          }
          else {
            if (element.broadcaster.active === false){
              // Creation needs to be requested to signalling server
              element.broadcaster.lastStartTS = TIME_NOW;
              element.broadcaster.active = true;
              this.azzCmdInputWebRtcCreate(element.broadcaster.deviceId, AvProdWebRtcConnectionType.broadcaster);
            }
            else {
              if (TIME_NOW - element.broadcaster.lastStartTS > MAX_TIMEOUT_TO_START) {
                this.webRtcConnectionDelete(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, element.broadcaster.deviceId);
              }
            }
          }
        }
        else {
          if (element.broadcaster.active === true){
            this.webRtcConnectionDelete(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, element.broadcaster.deviceId);
          }
        }
      }

      // Check viewer
      if (element.viewer.lastClientPingTS !== 0){
        if (TIME_NOW - element.viewer.lastClientPingTS < MAX_TIMEOUT_PING) {
          // Client component needs this connection
          if ((element.viewer.peerConnection?.connectionState === 'connected')||
              (element.viewer.peerConnection?.connectionState === 'connecting')) {
            // do nothing
          }
          else {
            if (element.viewer.active === false){
              // Creation needs to be requested to signalling server
              element.viewer.lastStartTS = TIME_NOW;
              element.viewer.active = true;
              this.azzCmdInputWebRtcCreate(element.viewer.deviceId, AvProdWebRtcConnectionType.viewer);
            }
            else {
              if (TIME_NOW - element.viewer.lastStartTS > MAX_TIMEOUT_TO_START) {
                console.log('[AvProducerService] tickWebRtcCheck: input Viewer Delete Id:' + element.viewer.deviceId);
                this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.input, element.viewer.deviceId);
              }
            }
          }
        }
        else {
          if (element.viewer.active === true){
            this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.input, element.viewer.deviceId);
          }
        }
      }

    });

    // Check Web RTC outputs
    this.webRtcOutputs.forEach(element => {
      // Check viewer
      if (element.viewer.lastClientPingTS !== 0) {
        if (TIME_NOW - element.viewer.lastClientPingTS < MAX_TIMEOUT_PING) {
          // Client component needs this connection
          if ((element.viewer.peerConnection?.connectionState === 'connected')||
              (element.viewer.peerConnection?.connectionState === 'connecting')) {
            // do nothing
            //console.log('[AvProducerService] tickWebRtcCheck: output video channel ' + element.viewerManager.video.channel?.readyState);
            //console.log('[AvProducerService] tickWebRtcCheck: output audio channel ' + element.viewerManager.audio.channel?.readyState);
          }
          else {
            if (element.viewer.active === false){
              // Creation needs to be requested to signalling server
              element.viewer.lastStartTS = TIME_NOW;
              element.viewer.active = true;
              console.log('[AvProducerService] tickWebRtcCheck: output Create');
              this.azzCmdOutputWebRtcCreate(element.viewer.deviceId);
            }
            else {
              if (TIME_NOW - element.viewer.lastStartTS > MAX_TIMEOUT_TO_START) {
                console.log('[AvProducerService] tickWebRtcCheck: output Delete Id:' + element.viewer.deviceId);
                this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.output, element.viewer.deviceId);
              }
            }
          }
        }
        else {
          if (element.viewer.active === true){
            this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.output, element.viewer.deviceId);
          }
        }
      }
    });
  }

  protected webRtcConnectionCloseAll() {
    console.log('[AvProducerService] webRtcConnectionCloseAll START');
    this.webRtcOutputs.forEach(element => {
      if (element.viewer.active === true){
        this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.output, element.viewer.deviceId);
        if (element.viewerManager.video.channel !== null){
          element.viewerManager.video.channel.onclose = null;
        }
        if (element.viewerManager.video.channel !== null){
          element.viewerManager.video.channel.onmessage = null;
        }
        if (element.viewerManager.audio.channel !== null){
          element.viewerManager.audio.channel.onclose = null;
        }
        if (element.viewerManager.audio.channel !== null){
          element.viewerManager.audio.channel.onmessage = null;
        }
        element.viewer.peerConnection?.close();
        element.viewer.active = false;
        element.viewer.lastClientPingTS = 0;
        console.log('[AvProducerService] webRtcConnectionCloseAll Out ' + element.viewer.deviceId);
      }
    })
    this.webRtcInputs.forEach(element => {
      if (element.viewer.active === true){
        this.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.input, element.viewer.deviceId);
        if (element.viewerManager.video.channel !== null){
          element.viewerManager.video.channel.onclose = null;
        }
        if (element.viewerManager.video.channel !== null){
          element.viewerManager.video.channel.onmessage = null;
        }
        if (element.viewerManager.audio.channel !== null){
          element.viewerManager.audio.channel.onclose = null;
        }
        if (element.viewerManager.audio.channel !== null){
          element.viewerManager.audio.channel.onmessage = null;
        }
        element.viewer.peerConnection?.close();
        element.viewer.active = false;
        element.viewer.lastClientPingTS = 0;
        console.log('[AvProducerService] webRtcConnectionCloseAll In Viewer ' + element.viewer.deviceId);
      }
      if (element.broadcaster.active === true){
        this.webRtcConnectionDelete(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, element.broadcaster.deviceId);
        element.broadcaster.peerConnection?.close();
        element.broadcaster.active = false;
        element.broadcaster.lastClientPingTS = 0;
        console.log('[AvProducerService] webRtcConnectionCloseAll In Caster ' + element.broadcaster.deviceId);
      }
    })
  }

  /**
   * Function to create a web RTC connection
   *
   * @param type Web RTC peer type (broadcaster, viewer)
   * @param inout Input or output channel
   * @param id Input or output identifier
   * @param stereo
   * @param mediaStream
   */
/*   protected webRtcConnectionCreate(type: AvProdWebRtcConnectionType, inoutType: AvProdDeviceType, id: number, stereo: boolean, mediaStream: MediaStream | undefined): boolean {

    console.log('[AvProducerService] webRtcConnectionCreate ' + inoutType + '/' + id);
    if ((inoutType === AvProdDeviceType.output)&&
        (this.webRtcOutputs[id] === undefined)) {
          this.webRtcOutputs[id] = {
            viewer: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            viewerManager: {
              video: {
                width: 0,
                height: 0,
                channel: null,
                decoder: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                partialVideoData: null,
                frame$: new Subject()
              },
              audio: {
                active: true,
                channel: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                data$: new Subject()
              }
            }
          }
    }
    else if ((inoutType === AvProdDeviceType.input)&&
        (this.webRtcInputs[id] === undefined)) {
          this.webRtcInputs[id] = {
            viewer: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            broadcaster: {
              active: false,
              deviceId: id,
              id: '',
              serverSdp: '',
              clientSdp: '',
              lastClientPingTS: 0,
              lastStartTS: 0
            },
            viewerManager: {
              video: {
                width: 0,
                height: 0,
                channel: null,
                decoder: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                partialVideoData: null,
                frame$: new Subject()
              },
              audio: {
                active: false,
                channel: null,
                firstTS: 0,
                lastTS: 0,
                lastLocalTS: 0,
                data$: new Subject()
              }
            }
          }
    }

    if (type === AvProdWebRtcConnectionType.broadcaster) {
      if (inoutType === AvProdDeviceType.input) {
        this.webRtcInputs[id].broadcasterStream = mediaStream;
      }
      else {
        // Error: Output channel cannot be broadcasted
        return false;
      }
    }

    if (inoutType === AvProdDeviceType.output) {
      this.azzCmdOutputWebRtcCreate(id);
    }
    else if (inoutType === AvProdDeviceType.input) {
      this.azzCmdInputWebRtcCreate(id, type);
    }

    return true;
  }
*/
  /**
   * Function to delete a Web-RTC connection
   *
   * @param type Web RTC peer type (broadcaster, viewer)
   * @param inout Input or output channel
   * @param id Input or output identifier
   */
  public async webRtcConnectionDelete(type: AvProdWebRtcConnectionType, inoutType: AvProdDeviceType, id: number) {
    console.log('[AvProducerService] webRtcConnectionDelete ' + type + ' ' + inoutType + '/' + id);
    if (inoutType === AvProdDeviceType.output) {
      if (this.webRtcOutputs[id] !== undefined) {
        this.webRtcOutputs[id].viewer.peerConnection?.close();
        this.webRtcOutputs[id].viewer.lastClientPingTS = 0;
        this.azzCmdOutputWebRtcDelete(id, this.webRtcOutputs[id].viewer.id);

        this.webRtcOutputs[id].viewer.id = '';
        this.webRtcOutputs[id].viewer.clientSdp = '';
        this.webRtcOutputs[id].viewer.serverSdp = '';
        this.webRtcOutputs[id].viewer.lastClientPingTS = 0;
        this.webRtcOutputs[id].viewer.lastStartTS = 0;
        this.webRtcOutputs[id].viewer.peerConnection = undefined;
      }
    }
    else if (inoutType === AvProdDeviceType.input) {
      if (this.webRtcInputs[id] !== undefined) {
        if (type === AvProdWebRtcConnectionType.broadcaster) {
          this.webRtcInputs[id].broadcaster.peerConnection?.close();
          this.webRtcInputs[id].broadcaster.lastClientPingTS = 0;
          this.azzCmdInputWebRtcDelete(id, type, this.webRtcInputs[id].broadcaster.id);
        }
        else {
          this.webRtcInputs[id].viewer.peerConnection?.close();
          this.webRtcInputs[id].viewer.lastClientPingTS = 0;
          this.azzCmdInputWebRtcDelete(id, type, this.webRtcInputs[id].viewer.id);

          this.webRtcInputs[id].viewer.id = '';
          this.webRtcInputs[id].viewer.clientSdp = '';
          this.webRtcInputs[id].viewer.serverSdp = '';
          this.webRtcInputs[id].viewer.lastClientPingTS = 0;
          this.webRtcInputs[id].viewer.lastStartTS = 0;
          this.webRtcInputs[id].viewer.peerConnection = undefined;
          }
      }
    }
  }
}

