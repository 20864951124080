import { FormControl, FormGroup } from '@angular/forms';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IEvent, IEventCategories } from '../../interfaces/events/event.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_EVENT_CATEGORIES } from '../../const/av-producer-options';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { IToastConfig } from '../../interfaces/utils/utils.interface';


export class EventSettingsClass {
  protected useSaveButton: boolean = false;
  protected eventSettingsForm: FormGroup = new FormGroup([]);
  protected isResetNeeded: boolean = false;
  protected isDirty: boolean = false;
  protected isBusy: boolean = false;
  protected initialized: boolean = false;
  protected categories: IEventCategories[] = [];
  protected _mode: string = 'edit';   // Options: 'create','edit'
  protected _event: IEvent | undefined;

  protected items: IAvSettingsItemConfig[] = [
    {
      id: 'eventName',
      type: AvProdSettingsType.text,
      name: 'eventSettings.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'eventDescription',
      type: AvProdSettingsType.textArea,
      name: 'eventSettings.description',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'eventCategory',
      type: AvProdSettingsType.selectComboNumber,
      name: 'eventSettings.category',
      min: 0,
      max: 0,
      step: 0,
      options: AV_PROD_OPTIONS_EVENT_CATEGORIES,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'eventPrivate',
      type: AvProdSettingsType.switchBoolean,
      name: 'eventSettings.private',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'eventSettings.privateInfoDescription',
      value: true,
      visible: false
    },
    {
      id: 'eventProtected',
      type: AvProdSettingsType.switchBoolean,
      name: 'eventSettings.protected',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'eventSettings.protectedInfoDescription',
      value: undefined
    },
    {
      id: 'eventPassword',
      type: AvProdSettingsType.password,
      name: 'eventSettings.password',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: undefined
    }
  ];

  constructor(protected eventsService: EventsService,
              protected users: UserService) {
    // Do nothing
  }

  protected init(): void {
    //console.log('[EventSettingsClass] init');
    //if ((this._event === undefined)&&(this._mode === 'edit')){
    //  this._event = this.eventsService.currentEvent;
    //  console.log('[EventSettingsClass] init. By default, use current event from eventsService');
    //}
    this.items.forEach((element: IAvSettingsItemConfig) => {
      this.eventSettingsForm.addControl(element.id, new FormControl());
    });
    this.initialized = true;
    this.readSettings();
  }

  protected setIsVisible(visible: boolean): void {
    if (visible) {
      this.eventSettingsForm.markAsPristine();
      this.isDirty = false;
      this.readSettings();
    }
  }

  protected setMode(value: string): void {
    if (value != this._mode) {
      if ((value === 'create') || (value === 'edit')) {
        this._mode = value;
        this.readSettings();
      }
    }
  }

  protected setEvent(value: IEvent): void {
    this._event = value;
    this.readSettings();
  }

  protected emitEventCreated(event: IEvent): void {
    // must be overridden
  }

  protected emitSettingsChanged(event: IEvent): void {
    // must be overridden
  }

  protected emitDirty(value: boolean): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected async navigateToSection(section: string): Promise<boolean> {
    return false;
  }

  protected readSettings(): void {
    // Update settings
    if (!this.isDirty) {
      if (this.initialized) {
        if (this._event !== undefined) {
          this.eventSettingsForm.get('eventName')?.setValue(this._event.name);
          this.eventSettingsForm.get('eventDescription')?.setValue(this._event.description);
          this.eventSettingsForm.get('eventCategory')?.setValue(this._event.categoryId);
          this.eventSettingsForm.get('eventPrivate')?.setValue(this._event.private);
          this.eventSettingsForm.get('eventProtected')?.setValue(this._event.protected);
          this.eventSettingsForm.get('eventPassword')?.setValue(this._event.protectedPassword);
        } else {
          if (this.eventSettingsForm.get('eventName')?.value == null) this.eventSettingsForm.get('eventName')?.setValue('');
          if (this.eventSettingsForm.get('eventDescription')?.value == null) this.eventSettingsForm.get('eventDescription')?.setValue('');
          if (this.eventSettingsForm.get('eventCategory')?.value == null) this.eventSettingsForm.get('eventCategory')?.setValue(1);
          if (this.eventSettingsForm.get('eventPrivate')?.value == null) this.eventSettingsForm.get('eventPrivate')?.setValue(false);
          if (this.eventSettingsForm.get('eventProtected')?.value == null) this.eventSettingsForm.get('eventProtected')?.setValue(false);
          if (this.eventSettingsForm.get('eventPassword')?.value == null) this.eventSettingsForm.get('eventPassword')?.setValue('');
        }
      }
      this.refreshEnabledAndVisibleControls();
      this.isResetNeeded = false;
      this.isDirty = false;
      this.emitDirty(this.isDirty);
    } else {
      this.isResetNeeded = true;
    }
  }

  protected onSettingChanged(event: IAvSettingsItemConfig): void {
    this.eventSettingsForm.markAsDirty();
    this.refreshDirtyFlags();
    this.refreshEnabledAndVisibleControls();
    if ((this.useSaveButton === false)&&(this.isDirty === true)){
      this.onSave();
    }
  }

  protected refreshEnabledAndVisibleControls(): void {
    if (this.eventSettingsForm.get('eventProtected')?.value === true) {
      this.updateItemVisibility('eventPassword', true);
    } else {
      this.updateItemVisibility('eventPassword', false);
    }
  }

  protected updateItemVisibility(id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = this.items.find((element: IAvSettingsItemConfig) => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
  }

  protected refreshDirtyFlags(): void {
    this.isDirty = this.eventSettingsForm.dirty;
    if (this.isDirty) {
      this.isResetNeeded = true;
    }
    this.emitDirty(this.isDirty);
  }

  protected onSave(): void {
    if (this._mode === 'edit') {
      this.isBusy = true;
      if (this._event?.id !== undefined) {
        // Edit event configuration
        if (this.validateForm()) {
          const EVENT: IEvent = {
            id: this._event?.id,
            name: this.eventSettingsForm.get('eventName')?.value,
            description: this.eventSettingsForm.get('eventDescription')?.value,
            categoryId: this.eventSettingsForm.get('eventCategory')?.value,
            private: this.eventSettingsForm.get('eventPrivate')?.value,
            protected: this.eventSettingsForm.get('eventProtected')?.value
          };
          if (this.eventSettingsForm.get('eventPassword')?.value != '') {
            EVENT.protectedPassword = this.eventSettingsForm.get('eventPassword')?.value;
          }
          if (this._event?.producerToken !== undefined) {
            EVENT.producerToken = this._event?.producerToken;
          }

          console.log('[EventSettingsClass] Edit save: ' + JSON.stringify(EVENT));
          this.eventsService.updateEvent(EVENT)
            .subscribe((event: IEvent | null) => {
              if (event && event.id) {
                console.log('[EventSettingsClass] Event edit');
                if (this._event === undefined) {
                  this._event = event;
                } else {
                  this._event.name = event.name;
                  this._event.description = event.description;
                  this._event.categoryId = event.categoryId;
                  this._event.private = event.private;
                  this._event.protected = event.protected;
                }
                this.emitSettingsChanged(this._event);
                this.readSettings();
              } else {
                console.log('[EventSettingsClass] Error edit Event');
              }
              this.isBusy = false;
            });

          this.eventSettingsForm.markAsPristine();
          this.isDirty = false;
          this.refreshDirtyFlags();
          this.emitDirty(this.isDirty);
        } else {
          this.isBusy = false;
        }
      } else {
        this.isBusy = false;
        console.log('[EventSettingsClass] Error changing Event. Id is not defined');
      }
    }
  }

  protected onReset(): void {
    this.isBusy = true;
    this.eventSettingsForm.markAsPristine();
    this.isDirty = false;
    this.readSettings();
    this.isBusy = false;
  }

  protected validateForm(): boolean {
    let ret: boolean = true;

    if ((this.eventSettingsForm.get('eventName')?.value == null) || (this.eventSettingsForm.get('eventName')?.value == '')) ret = false;
    if ((this.eventSettingsForm.get('eventDescription')?.value == null) || (this.eventSettingsForm.get('eventDescription')?.value == '')) ret = false;
    if ((this.eventSettingsForm.get('eventCategory') == null) || (this.eventSettingsForm.get('eventCategory')?.value == null)) ret = false;
    if ((this.eventSettingsForm.get('eventPrivate') == null) || (this.eventSettingsForm.get('eventPrivate')?.value == null)) ret = false;
    if ((this.eventSettingsForm.get('eventProtected') == null) || (this.eventSettingsForm.get('eventProtected')?.value == null)) ret = false;

    if (this.eventSettingsForm.get('eventProtected')?.value == true) {
      if (this._mode == 'create') {
        if ((this.eventSettingsForm.get('eventPassword')?.value == null) || (this.eventSettingsForm.get('eventPassword')?.value == '')) ret = false;
      }
    }

    if (!ret) {
      this.displayToast({
        options: {
          autohide: false,
          placement: ToastPlacement.middleCenter
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.error,
          title: 'eventSettings.invalidFields',
          text: 'eventSettings.invalidFieldsDetail',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
    return ret;
  }

  protected onCreate(): void {
    if (this._mode === 'create') {
      this.isBusy = true;
      // Create new event
      if (this.validateForm()) {
        const EVENT: IEvent = {
          userId: this.users.user.id,
          organizationId: this.users.orgId,
          name: this.eventSettingsForm.get('eventName')?.value,
          description: this.eventSettingsForm.get('eventDescription')?.value,
          categoryId: this.eventSettingsForm.get('eventCategory')?.value,
          private: this.eventSettingsForm.get('eventPrivate')?.value,
          protected: this.eventSettingsForm.get('eventProtected')?.value,
          protectedPassword: this.eventSettingsForm.get('eventPassword')?.value,
        };

        console.log('[EventSettingsClass] Create: ' + JSON.stringify(EVENT));
        this.eventsService.createEvent(EVENT)
          .subscribe((event: IEvent | null) => {
            if (event && event.id) {
              console.log('[EventSettingsClass] Event created');
              this._event = event;
              this.readSettings();
              this.emitEventCreated(event);
            } else {
              let errorMsg: string = 'eventSettings.errorCreatingEventDetails';
              this.displayToast({
                options: {
                  autohide: false,
                  placement: ToastPlacement.middleCenter
                },
                data: {
                  closeButtonHeader: true,
                  status: ToastStatus.error,
                  title: 'general.error',
                  text: 'eventSettings.errorCreatingEventDetails',
                  alignText: 'text-center',
                  buttons: []
                },
              });
              console.log('[EventSettingsClass] Error creating Event');
            }
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
        console.log('[EventSettingsClass] Fields not valid');
      }
    }
  }
}
