import { Subscription } from 'rxjs';
import { IOrganizationInfo, IUserOrganizationInfo } from '../interfaces/user/user.interface';
import { UserService } from '../services/user/user.service';

export class OrganizationBannerClass {

    protected orgList: IUserOrganizationInfo[] = [];
    protected _orgId: number = 0;
    protected userSubscription: Subscription | undefined;
    protected userOrgSubscription: Subscription | undefined;
    protected bannerImageSrc: string | undefined;
    protected profileImageSrc: string | undefined;
    protected _layoutMode: string = ''; //'manager' | 'dashboard' | 'player' | 'organization'
    protected _eventOrgId: number = 0;

    constructor (protected userService: UserService) {}

    protected init(): void {
        this.updateUserOrganizationList();
        this.updateImages();
        this.userSubscription?.unsubscribe();
        this.userSubscription = this.userService.user$.subscribe(() => this.updateUserOrganizationList());
        this.userOrgSubscription?.unsubscribe();
        this.userOrgSubscription = this.userService.orgId$.subscribe(() => this.updateUserOrganizationList());
      }

      protected close(): void {
        this.userOrgSubscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
      }

    protected updateUserOrganizationList(): void {

        this.updateImages();
        console.log('[OrganizationBannerClass] updateUserOrganizationList - orgId: ' + this._orgId);
        console.log('[OrganizationBannerClass] updateUserOrganizationList' + JSON.stringify(this.userService.user));
        if (this.userService.user.organizations !== undefined){
          this.orgList = this.userService.user.organizations;
        }
        else{
          this.orgList = [];
        }
    }

    protected updateImages(): void {
      const ORG: IOrganizationInfo | undefined = this.userService.organization;
      if ((ORG?.profilePhoto !== undefined) &&
          (ORG?.profilePhoto !== '')){
        this.profileImageSrc = 'url("' + ORG.profilePhoto + '")';
      }
      else{
        switch (this._orgId){
          case 1:
            this.profileImageSrc = 'url("/assets/web/img/organizations/azzulei-logo.png")';
            break;
          case 2:
            this.profileImageSrc = 'url("/assets/web/img/organizations/liga-iberica-logo.jpg")';
            break;
          default:
            this.profileImageSrc = 'url("/assets/web/img/organizations/default-logo.png")';
            break;
        }
      }

      if ((ORG?.backgroundPhoto !== undefined) &&
          (ORG?.backgroundPhoto !== '')){
        this.bannerImageSrc = 'url("' + ORG.backgroundPhoto + '")';
      }
      else{
        switch (this._orgId){
          case 1:
            this.bannerImageSrc = 'url("/assets/web/img/organizations/azzulei-banner.png")';
            break;
          case 2:
            this.bannerImageSrc = 'url("/assets/web/img/organizations/liga-iberica-banner.png")';
            break;
          default:
            this.bannerImageSrc = 'url("/assets/web/img/organizations/default-banner.png")';
            break;
        }
      }
      
      console.log('[ORGANIZATION BANNER CLASS] - updateImages - Banner: ' + this.bannerImageSrc + ', Profile: ' + this.profileImageSrc);
    }

    protected onClickProfile(): void {
      //TO BE OVERRIDE
    }
}
