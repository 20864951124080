import { Subscription, first, tap } from 'rxjs';

import {
  IEvent,
  IEventAsset,
  IEventUserLastChanges,
} from '../../interfaces/events/event.interface';
import { AvProdInputType } from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { IOrganizationInfo } from '../../interfaces/user/user.interface';
import { EVENTS } from '../../const/events.const';

export class StorageOverviewClass {
  protected currentStorage: number = 0;
  protected totalStorage: number = 0;
  protected assets: IEventAsset[] = [];
  protected eventCount: number = 0;

  protected userChangesSubscription: Subscription | undefined;
  protected orgIdSubscription: Subscription | undefined;

  protected assetTypes: any[] = [
    {
      key: 'images',
      name: 'manager.storage.images',
      icon: 'bi bi-image',
      count: 0,
    },
    {
      key: 'overlayImages',
      name: 'manager.storage.overlayImages',
      icon: 'bi bi-image-alt',
      count: 0,
    },
    {
      key: 'videos',
      name: 'manager.storage.videos',
      icon: 'bi bi-play-btn',
      count: 0,
    },
    {
      key: 'sounds',
      name: 'manager.storage.sounds',
      icon: 'bi bi-music-note-list',
      count: 0,
    },
    {
      key: 'documents',
      name: 'manager.storage.documents',
      icon: 'bi bi-filetype-pdf',
      count: 0,
    },
    {
      key: 'events',
      name: 'manager.storage.events',
      icon: 'bi bi-list-task',
      count: 0,
    },
  ];

  constructor(protected events: EventsService,
              protected user: UserService) {
    console.log('[StorageOverviewClass] constructor');
  }

  protected init(): void {
    this.orgIdSubscription?.unsubscribe();
    this.orgIdSubscription = this.user.orgId$.subscribe(() =>
      this.updateAssetList()
    );
    this.userChangesSubscription = this.events.userChanges$.subscribe((data: IEventUserLastChanges) =>
      this.checkUserChanges(data)
    );
    this.readEvents();
    this.updateAssetList();
    this.events.addPollingClient('StorageOverviewClass');
  }

  protected destroy(): void {
    this.orgIdSubscription?.unsubscribe();
    this.userChangesSubscription?.unsubscribe();
    this.events.removePollingClient('StorageOverviewClass');
  }

  protected checkUserChanges(changes: IEventUserLastChanges): void {
    if (changes.storage === true || changes.account === true) {
      this.readEvents();
      this.updateAssetList();
    }
  }

  protected readEvents(): void {
    console.log('[EventStatusListClass] readEvents');
    if (this.user.user?.id !== undefined) {
      this.events.getUserEvents(this.user.orgId)
        .pipe(
          first(),
          tap((eventList: IEvent[]) => {
            let archivedEvents: IEvent[] = [];
            archivedEvents = eventList.filter( (event: IEvent) => event.status === EVENTS.status.archived);
            this.eventCount = archivedEvents.length;
            this.setAssetKeyCount('events', this.eventCount);
          }))
        .subscribe(() => {
        });
    }
  }

  protected updateAssetList(): void {
    if (this.user.orgId !== 0){
      this.user.getOrganizationData().subscribe(() => {
        let org: IOrganizationInfo | undefined = this.user.organization;
        if (org.storageUsed !== undefined) {
          this.currentStorage = org.storageUsed;
        }
        if (org.storageTotal !== undefined) {
          this.totalStorage = org.storageTotal;
          this.emitStoragePlan(org.storageTotal);
        }

      });
    }
    else{
      this.user.getUserData()
        .subscribe(() => {
          if (this.user.user.storageUsed !== undefined) {
            this.currentStorage = this.user.user.storageUsed;
          }
          if (this.user.user.storageTotal !== undefined) {
            this.totalStorage = this.user.user.storageTotal;
            this.emitStoragePlan(this.user.user.storageTotal);
          }
        });
    }

    this.events.getUserAssetList()
      .subscribe((answer: IEventAsset[] | null) => {
        if (answer !== null) {
          this.assets = answer;
          this.updateAssetCount();
        }
      });
  }

  protected updateAssetCount(): void {
    let countImages: number = 0;
    let countOverlayImages: number = 0;
    let countVideos: number = 0;
    let countDocuments: number = 0;
    let countSounds: number = 0;
    for (let i: number = 0; i < this.assets.length; i++) {
      switch (this.assets[i].type) {
        case AvProdInputType.audioClip:
          countSounds++;
          break;
        case AvProdInputType.videoAudioClip:
        case AvProdInputType.videoClip:
          countVideos++;
          break;
        case AvProdInputType.imageTile:
          countImages++;
          break;
        case AvProdInputType.imageOverlay:
          countOverlayImages++;
          break;
        case AvProdInputType.document:
          countDocuments++;
          break;
      }
    }
    this.setAssetKeyCount('images', countImages);
    this.setAssetKeyCount('overlayImages', countOverlayImages);
    this.setAssetKeyCount('videos', countVideos);
    this.setAssetKeyCount('documents', countDocuments);
    this.setAssetKeyCount('sounds', countSounds);
  }

  protected setAssetKeyCount(key: string, count: number): void {
    const ITEM: any = this.assetTypes.find((element) => element.key === key);
    if (ITEM !== undefined) {
      ITEM.count = count;
    }
  }

  protected formatSizeKB(size: number): string {
    let scaledSize: number = size;
    let units: string[] = [' B', ' KB', ' MB', ' GB'];
    let iterations: number = 1; // Input is KBytes

    if (scaledSize < 0) {
      scaledSize = 0;
    }

    while (scaledSize > 1024 && iterations < units.length - 1) {
      scaledSize = scaledSize / 1024;
      iterations++;
    }

    return Number(scaledSize.toFixed(1)).toString() + units[iterations];
  }

  protected emitStoragePlan(item: number): void {
    //Must be overridden
  }
}
