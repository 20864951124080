import { IEvent } from '../../interfaces/events/event.interface';
import { IPlayerData } from '../../interfaces/player/player.interface';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { EventsService } from '../../services/events/events.service';

export class SitePageClass {

  protected orgName: string = '';
  protected orgNameLong: string = '';
  protected orgId: number = 0;
  protected eventsLive: IEvent[] = [];
  protected eventsArchived: IEvent[] = [];
  protected watchToken: string | undefined;
  protected isPlayerLive: boolean = false;

  protected playerDataready: boolean = false;
  protected playerData: IPlayerData = {
    id: 0,
    name: 'Name',
    token: '',
    start: new Date(),
    protected: false,
    password: '',
    status: ''
  }

  constructor(protected events: EventsService) {
  }

  protected init(addr: string): void {
    this.orgName = addr.slice(1,);
    console.log('[SitePageComponent] ngAfterViewInit ' + this.orgName);

    // Temporary a piñón
    switch(this.orgName){
      case 'ligaibericagaf':
        this.orgId = 2;
        this.orgNameLong = 'Liga Ibérica GAF';
        break;

      case 'azzulei':
        this.orgId = 1;
        this.orgNameLong = 'Azzulei Technologies';
        break;
      default:
        this.exitPage();
        break;
    }
    if (this.orgId !== 0){
      this.updateEventList();
    }
  }

  protected destroy() {
  }

  protected exitPage(): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }
  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }

  protected updateEventList(){
    this.events.getEventsByStatus('Running', this.orgId)
      .pipe()
      .subscribe(list => {
        if ((list !== null)&&(list !== undefined)){
          this.eventsLive = list;
          this.setPlayerEvent(this.eventsLive[0].viewerToken, true);
        }
    });
    this.events.getEventsByStatus('Archived', this.orgId)
      .pipe()
      .subscribe(list => {
        if ((list !== null)&&(list !== undefined)){
          this.eventsArchived = list;
          //this.setPlayerEvent(this.eventsArchived[0].viewerToken);
        }
    });
  }

  protected onClickEvent(token: string, isLive: boolean){
    //LIVE PLAYER TEMPORARILY DISABLED
    this.setPlayerEvent(token, isLive);
    //this.goToSection(token, true);
  }

  protected setPlayerEvent(token: string | undefined, isLive: boolean){
    this.watchToken = token;
    this.isPlayerLive = isLive;
    console.log('[SITE PAGE CLASS] - setPlayerEvent - Event token: ' + this.watchToken + ', Live: ' + this.isPlayerLive);
  }

  protected goToSection(token: string, newWindow: boolean){
    //MUST OVERRIDE
  }

  protected onClickRefresh(){
    this.updateEventList();
  }
}
