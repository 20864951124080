import { ENV_COMMON } from '@azz-life-streamer/environments'
import { IAvSettingSelectOption } from '../interfaces/av-producer/event-av-producer.interface';

export const API = {
  timeout: 30000,
  attempts: 0,
  delayAttempts: 5000,
  headers: {
    authToken: 'Authorization',
    source: 'Source'
  },
  interceptors: {
    api: {
      skip: [
        'play.azzulei.tv',
        'dev-play.azzulei.tv',
//        'assets',
        'upload',
        '.svg',
        'v3/events/validate-token'
      ]
    }
  },
  urls: {
    server: ENV_COMMON.api,
    actions: {
      app: {
        status: 'app-status/status-check',
        version: 'app-status/version-check'
      },
      assets: {
        get: 'assets',
        set: 'assets',
        delete: 'assets'
      },
      contentReport: {
        getReasons: 'content-report-reasons',
        report: 'content-reports'
      },
      destinations: {
        create: 'destinations',
        delete: 'destinations',
        get: 'destinations',
        set: 'destinations',
        reconnect: 'destinations/reconnect',
      },
      device: {
        register: 'devices/register',
      },
      events: {
        archive: 'events/archive',
        categories: 'events-categories',
        checkToken: 'events/check-token',
        concurrencePermission: 'events/concurrence-permission',
        create: 'events',
        creationPermission: 'events/creation-permission',
        delete: 'events',
        finish: 'events/finish',
        get: 'events',
        getUserEvents: 'events/get-all-info',
        getByToken: 'events/validate-token',
        publicationTools: 'events-publication-tools',
        requestCreation: 'events-creation-requests',
        status: 'events/get-status',
        update: 'events',
      },
      error: {
          message: 'error-message'
      },
      organizations: {
        acceptInvitation: 'organizations/accept-invitation',
        create: 'organizations',
        declineInvitation: 'organizations/decline-invitation',
        delete: 'organizations',
        get: 'organizations',
        inviteMember: 'organizations/invite-member',
        removeMember: 'organizations/remove-member',
        update: 'organizations',
      },
      stripe: {
        createSession: 'stripe/create-session',
        getCustomerPortal: 'stripe/customer-portal'
      },
      users: {
        activateAccount: 'users/activate-account',
        changes: 'users/changes',
        checkForgotPasswordCode: 'users/check-reset-password-code',
        checkDeletionCode: 'users/check-deletion-code',
        deleteAccount: 'users/delete',
        getLoginAuthKey: 'users/login-auth',
        getUpdateAuthKey: 'users/update-auth',
        getData: 'users/get-data',
        hasActiveEvents:'users/has-active-events',
        login: 'users/login',
        logout: 'users/logout',
        renewToken:'users/login-renew',
        sendActivationCode:'users/send-activation-code',
        resetPassword:'users/reset-password',
        setFirstPassword: 'users/set-first-password',
        sendForgotPasswordCode: 'users/send-reset-password-code',
        sendDeletionCode: 'users/send-deletion-code',
        sendSetPasswordCode: 'users/send-set-password-code',
        setPushData: 'users/set-push-info',
        signUp: 'users/create',
        update: 'users/update',
      },
      forgot: {
        pass: 'users/reset-pass'
      },
      contact: {
        form: 'tools/support'
      }
    }
  }
};

export const API_OPTIONS_REPORT_CONTENT_REASONS: IAvSettingSelectOption[] = []

// Array of option arrays to manage language update
export const API_OPTIONS_LIST: IAvSettingSelectOption[][] = []
