import { TranslateService } from '@ngx-translate/core';
import { Subscription, first } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IEvent, IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { EVENTS } from '../../const/events.const';
import { UserService } from '../../services/user/user.service';
import { EventsService } from '../../services/events/events.service';
import { DatePipe } from '@angular/common';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { EventStatusLayoutMode } from '../../enums/utils.enum';


export class EventStorageListClass {
  protected eventsFinished: IEvent[] = [];
  protected eventsArchived: IEvent[] = [];
  protected eventsReceived: boolean = false;
  protected userInitializedSubscription: Subscription | undefined;
  protected userChangesSubscription: Subscription | undefined;
  protected userOrgIdSubscription: Subscription | undefined;
  //protected storageAvailable: number = 0;

  protected eventStatusLayoutMode = EventStatusLayoutMode;

  constructor(protected users: UserService,
              protected eventsService: EventsService,
              protected translate: TranslateService) { }
/*
  protected displayAlert(data: IAlertData): void {
    //must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    //must be overridden
  }
*/
  init(): void {
    console.log('[EventStorageListClass] init');
    this.userInitializedSubscription?.unsubscribe();
    this.userInitializedSubscription = this.users.onInitialized$.subscribe(() => this.initialize());
    this.userOrgIdSubscription?.unsubscribe();
    this.userOrgIdSubscription = this.users.orgId$.subscribe(() => this.initialize());
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.eventsService.userChanges$.subscribe(data => this.checkUserChanges(data));
    this.initialize();
    this.eventsService.addPollingClient('EventStorageListClass');
    console.log('[EventStorageListClass] init END');
  }

  destroy(): void {
    if (this.userInitializedSubscription !== undefined) {
      this.userInitializedSubscription.unsubscribe();
    }
    this.userOrgIdSubscription?.unsubscribe();
    this.userChangesSubscription?.unsubscribe();
    this.eventsService.removePollingClient('EventStorageListClass');
    console.log('[EventStorageListClass] destroy');
  }

  protected initialize(): void {
    //if ((this._initialized === false)&&(this.users.isInitialized === true)) {
    if (this.users.isInitialized) {
      this.readEvents();
      //this._initialized = true;
    }
    //this.updateStorageAvailable();
  }

  protected checkUserChanges(data: IEventUserLastChanges): void {
    console.log('[EventStorageListClass] checkUserChanges');
    if (data.eventList === true){
      this.readEvents();
    }
    else if (data.events !== undefined) {
      data.events.forEach((id: number) => {
        let event: IEvent | undefined = this.eventsFinished.find(element => element.id === id);
        if (event === undefined){
          event = this.eventsArchived.find(element => element.id === id)
        }
        if ((event !== undefined)&&(event.id !== undefined)){
          this.eventsService.getEvent(event.id)
            .subscribe((eventRefreshed: IEvent | null) => {
              if ((eventRefreshed !== undefined)&&(event !== undefined)) {
                Object.assign(event, eventRefreshed);
              }
            });
        }
      });
    }

    //if ((data.storage === true)||(data.account === true)){
    //  this.updateStorageAvailable(); 
    //}
  }

  protected readEvents(): void {
    console.log('[EventStorageListClass] readEvents');
    if (this.users.user?.id !== undefined) {
      this.eventsService.getUserEvents(this.users.orgId)
        .pipe(
          first(),
          tap((eventList: IEvent[]) => {
            this.eventsFinished = eventList.filter( (event: IEvent) => 
              event.status === EVENTS.status.finished
            );
            this.eventsArchived = eventList.filter( (event: IEvent) => 
              event.status === EVENTS.status.archived
            );
          }))
        .subscribe(() => {
          this.eventsReceived = true;
        });
    }
  }

  protected onEventDeleted(event: IEvent): void {
    console.log('[EventStorageList] Event deleted. Refresh all');
    this.readEvents();
  }

/*
  protected updateStorageAvailable(): void{
    if ((this.users.orgId !== 0)&&(this.users.orgId !== undefined)&&
        (this.users.organization !== undefined)){
      if ((this.users.organization.storageTotal !== undefined)&&
          (this.users.organization.storageUsed !== undefined)){
        // Check units (in KBytes)
        this.storageAvailable = (this.users.organization.storageTotal - this.users.organization.storageUsed) * 1024;
      }
      else {
        this.storageAvailable = 0;
      }
    }
    else {
      if ((this.users.user.storageTotal !== undefined)&&
          (this.users.user.storageUsed !== undefined)){
        // Check units (in KBytes)
        this.storageAvailable = (this.users.user.storageTotal - this.users.user.storageUsed) * 1024;
      }
      else {
        this.storageAvailable = 0;
      }
    }
    console.log('[EventStorageListClass] updateStorageAvailable ' + this.storageAvailable);
  }

  protected archiveEvent(id: number | undefined): void {
    if (id !== undefined) {
      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'eventStatus.archiveEventConfirmation',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
          {
            text: 'general.confirm',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              if (id !== undefined) {
                this.eventsService.archiveEvent(id)
                  .subscribe({
                    next: (resp: any) => {
                      console.log('[EventStorageClass] Event archived: ' + JSON.stringify(resp));
                      if (resp === false){
                        console.log('[EventStorageClass] Event archive ERROR');
                        this.displayToast({
                          options: {
                            autohide: true,
                            placement: ToastPlacement.bottomRight
                          },
                          data: {
                            closeButtonHeader: true,
                            status: ToastStatus.error,
                            title: 'general.error',
                            text: 'eventStatus.archiveEventError',
                            alignText: 'text-center',
                            buttons: []
                          },
                        });
                      }
                      this.readEvents();
                    }
                  });
              }
            }
          }]
      });
    }
  }

  protected deleteEvent(event: IEvent | undefined): void {
    if (event !== undefined){
      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'eventStatus.deleteEventConfirmation',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
          {
            text: 'general.confirm',
            color: 'danger',
            closeButton: true,
            handler: (): void => {
              if (event !== undefined) {
                this.eventsService.deleteEvent(event)
                  .subscribe({
                    next: (resp: any) => {
                      console.log('[EventStorageClass] Event deleted: ' + JSON.stringify(resp));
                      this.readEvents();
                    },
                    error: (err) => {
                      console.log('[EventStorageClass] Event delete ERROR: ' + JSON.stringify(err));
                      this.displayToast({
                        options: {
                          autohide: true,
                          placement: ToastPlacement.bottomRight
                        },
                        data: {
                          closeButtonHeader: true,
                          status: ToastStatus.error,
                          title: 'general.error',
                          text: 'eventStatus.deleteEventError',
                          alignText: 'text-center',
                          buttons: []
                        },
                      });
                    }
                  });
              }
            }
          }]
      });
    }
  }

  protected getExpirationInterval(time: number | undefined){
    if ((time === 0)||(time === undefined)){
      return 0;
    }
    else {
      const NOW: number = Date.now();
      const TIME_LEFT: number = ((time * 1000) - (NOW));
      if (TIME_LEFT > 0){
        return TIME_LEFT;
      }
      else{
        return 0;
      }
    }
  }

  protected formatExpirationTimeInterval(time: number | undefined): string {
    let ret: string='';
    console.log('[EventStorageList] formatExpirationTime ' + time);
    let tmpTime: number = this.getExpirationInterval(time) / 1000; // Convert to seconds
    if ((tmpTime !== undefined)&&(tmpTime > 0)){
      let days: number = 0;
      let hours: number = 0;
      let mins: number = 0;
      let secs: number = 0;

      if (tmpTime > 3600 * 24){   // More than 1 day
        days = Math.floor(tmpTime / (3600 * 24));
        hours = Math.floor( (tmpTime % (3600 * 24)) / 3600);
        ret = days.toFixed() + this.translate.instant('general.shortDays') + ' ' + hours.toFixed() + this.translate.instant('general.shortHours');
      }
      else if (tmpTime > 3600){   // More than 1 hour
        hours = Math.floor(tmpTime / (3600));
        mins = Math.floor( (tmpTime % (3600)) / 60);
        ret = hours.toFixed() + this.translate.instant('general.shortHours') + ' ' + mins.toFixed() + this.translate.instant('general.shortMins');
      }
      else{   // Less than 1 hour
        mins = Math.floor(tmpTime / (60));
        secs = Math.floor(tmpTime % (3600));
        ret = mins.toFixed() + this.translate.instant('general.shortMins') + ' ' + secs.toFixed() + this.translate.instant('general.shortSecs');
      }
    }
    else{
      ret = '0';
    }
    return ret;
  }

  protected formatSize(size: number): string {
    let scaledSize: number = size;
    let units: string[] = [' B',' KB',' MB',' GB'];
    let iterations: number = 0;

    while ((scaledSize > 1024)&&(iterations < units.length-1))
    {
      scaledSize = scaledSize / 1024;
      iterations++;
    }

    return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
  }
  */
}
