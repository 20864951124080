import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAvProdInput, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import {
  AvProdInputPlayingState,
  AvProdInputSourceType,
  AvProdInputTypeNumber,
  AvProdSettingsType
} from '../../const/av-producer.const';
import {
  AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL,
  AV_PROD_OPTIONS_INPUT_SOURCE_TYPE_EXT
} from '../../const/av-producer-options';
import { ModalDialogId } from '../../enums/utils.enum';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { ShareInfoService } from '../../services/share-info/share-info.service';
import { inject } from '@angular/core';


export class InputAddLiveClass {

  protected tabSelected: number = 0;
  protected addExtButtonDisabled: boolean = true;
  protected nativeLiveInputs: IAvProdInput[] = [];
  protected emptyLiveInputs: IAvProdInput[] = [];
  protected externalLiveInputs: IAvProdInput[] = [];
  protected inputExtForm: FormGroup = new FormGroup([]);
  protected allowCustomExternal: boolean = false;
  protected _eventPublisherToken: string = '';
  protected _eventPublisherLink: string = '';
  protected _eventName: string = '';

  protected inputStatusSubscription: Subscription | undefined;
  protected inputSettingsSubscription: Subscription | undefined;

  protected itemsExtSettings: IAvSettingsItemConfig[] = [
    {
      id: 'name',
      type: AvProdSettingsType.text,
      name: 'inputSettings.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'liveSourceType',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.liveSourceType',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_SOURCE_TYPE_EXT,
      placeholder: '',
      value: 2
    },
    {
      id: 'liveSourcePushProtocol',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.liveSourcePushProtocol',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL,
      placeholder: '',
      value: 1
    },
    // {
    //   id: 'liveSourceUrlPush',
    //   type: AvProdSettingsType.text,
    //   name: 'inputSettings.liveSourceUrlPush',
    //   min: 0,
    //   max: 0,
    //   step: 1,
    //   options: [],
    //   placeholder: '',
    //   value: ''
    // },
    {
      id: 'liveSourceUrlPull',
      type: AvProdSettingsType.text,
      name: 'inputSettings.liveSourceUrlPull',
      min: 0,
      max: 500,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ]
  protected itemsExtSettingsFiltered: IAvSettingsItemConfig[] = [];

  private shareSrv: ShareInfoService = inject(ShareInfoService);

  constructor(protected avProd: AvProducerService,
              protected modalService: ModalManagerService,
              protected translate: TranslateService) { }

  protected setTokenPublisher(value: string | undefined): void {
    if (value !== undefined) {
      this._eventPublisherToken = value;
      this._eventPublisherLink = ENV_COMMON.web + 'liveinput/' + value;
    }
  }

  protected setEventName(value: string | undefined): void {
    if (value !== undefined) {
      this._eventName = value;
    }
  }

  protected init(): void {
    this.itemsExtSettings.forEach((element: IAvSettingsItemConfig) => {
      this.inputExtForm.addControl(element.id, new FormControl());
    });

    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateLiveInputs());
    this.inputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe(() => this.updateLiveInputs());
    this.resetAddExtControls();
    this.updateLiveInputs();
    this.updateExtItemsFiltered();
  }

  protected destroy(): void {
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
  }

  protected displayToast(config: IToastConfig): void {
    // to be overridden
  }

  protected displayAlert(data: IAlertData): void {
    // to be overridden
  }

  protected updateLiveInputs(): void {
    console.log('[InputAddLiveClass] updateLiveInputs');
    // Check permissions for custom external inputs
    if ((this.avProd.serverSettings?.input.customInputs !== undefined)&&
        (this.avProd.serverSettings?.input.customInputs > 0)){
      this.allowCustomExternal = true;
    }

    this.nativeLiveInputs = this.avProd.inputs.filter(element =>
      (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) &&
      (element.settings?.liveSourceType === AvProdInputSourceType.native) &&
      (element.status?.playingState === AvProdInputPlayingState.playing)
    );
    this.emptyLiveInputs = this.avProd.inputs.filter(element =>
      (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) &&
      (element.settings?.liveSourceType === AvProdInputSourceType.native) &&
      (element.status?.playingState !== AvProdInputPlayingState.playing)
    );
    this.externalLiveInputs = this.avProd.inputs.filter(element =>
      (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) &&
      (element.settings?.liveSourceType !== AvProdInputSourceType.native)
    );
  }

  protected updateExtItemsFiltered(): void {
    const NEW_LIST: IAvSettingsItemConfig[] = [];
    Object.assign(NEW_LIST, this.itemsExtSettings);
    this.itemsExtSettingsFiltered = NEW_LIST;
    console.log('[InputAddLiveClass] updateExtItemsFiltered');

    this.updateItemVisibility('name', true);
    this.updateItemVisibility('liveSourceType', true);
    this.inputExtForm.get('liveSourceUrlPush')?.disable();
    if (this.inputExtForm.get('liveSourceType')?.value === AvProdInputSourceType.pull) {
      this.updateItemVisibility('liveSourceUrlPush', false);
      this.updateItemVisibility('liveSourceUrlPull', true);
      this.updateItemVisibility('liveSourcePushProtocol', false);
    } else if (this.inputExtForm.get('liveSourceType')?.value === AvProdInputSourceType.push) {
      this.updateItemVisibility('liveSourceUrlPush', true);
      this.updateItemVisibility('liveSourceUrlPull', false);
      this.updateItemVisibility('liveSourcePushProtocol', true);
    }
  }

  protected updateItemVisibility(id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = this.itemsExtSettingsFiltered.find((element: IAvSettingsItemConfig) => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
    this.updateAddExtButtonDisabled();
  }

  protected onClickDeleteExternal(id: number): void {
    const INPUT: IAvProdInput | undefined = this.externalLiveInputs.find((element: IAvProdInput) => element.info.id === id);
    if (INPUT !== undefined) {
      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'inputAdd.deleteInputConfirmation',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true
        },
          {
            text: 'general.confirm',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              const SETTINGS: IAvProdInputSettings = {};
              SETTINGS.name = 'Input' + (id + 1);
              SETTINGS.liveSourceType = AvProdInputSourceType.native;
              this.avProd.azzChangeInputSettings(id, SETTINGS);
            }
          }]
      });
    }
  }

  protected onClickConfig(id: number): void {
    this.modalService.show({id: ModalDialogId.producerInputSettings, subId: id, title: 'producer.inputSettings'}, true);
  }

  protected onClickAddExternalInput(): void {
    if (this.emptyLiveInputs.length > 0) {
      const INPUT_ID: number = this.emptyLiveInputs[this.emptyLiveInputs.length - 1].info.id;
      const SETTINGS: IAvProdInputSettings = {};
      SETTINGS.name = this.inputExtForm.get('name')?.value;
      SETTINGS.liveSourceType = this.inputExtForm.get('liveSourceType')?.value;
      if (SETTINGS.liveSourceType === AvProdInputSourceType.pull) {
        SETTINGS.liveSourcePullURL = this.inputExtForm.get('liveSourceUrlPull')?.value;
      }
      if (SETTINGS.liveSourceType === AvProdInputSourceType.push) {
        SETTINGS.liveSourcePushProtocol = this.inputExtForm.get('liveSourcePushProtocol')?.value;
      }

      this.inputExtForm.markAsPristine();
      this.avProd.azzChangeInputSettings(INPUT_ID, SETTINGS);
      this.resetAddExtControls();
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.success,
          title: 'general.done',
          text: 'producer.settingsSavedSuccess',
          alignText: 'text-center',
          buttons: []
        },
      });
    } else {
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.error,
          title: 'general.error',
          text: 'inputAdd.externalInputSlotNotAvailable',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
  }

  protected onClickShare(section: string): void {
    const TITLE: string = 'Azzulei TV - ' + this.translate.instant('eventStatus.shareTitle.' + section);
    let body: string = 'Azzulei TV\n\n';
    let url: string = this._eventPublisherLink;
    body += this.translate.instant('eventStatus.shareMessage.' + section,
      {
        token: this._eventPublisherToken,
        name: this._eventName,
        server: ENV_COMMON.web
      });
    const OPTIONS:  {title: string, text: string, url: string, dialogTitle: any} = {
      title: TITLE,
      text: body,
      url,
      dialogTitle: this.translate.instant('eventStatus.accessCodes'),
    };
    this.shareSrv.shareToken(OPTIONS);
  }

  protected onClickCopy(): void {
    this.copyToClipboard(this._eventPublisherLink);
  }

  protected onTabSelectExternal(): void {
    if (this.allowCustomExternal) {
      this.tabSelected = 1;
    }
    else{
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.info,
          title: 'general.information',
          text: 'inputAdd.externalInputNotAllowed',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
  }

  /*protected shareToken(title: string, body: string, url: string): void {
    //must be overridden
  }*/

  protected copyToClipboard(text: string): void {
    //must be overridden
  }

  protected updateAddExtButtonDisabled(){
    let buttonDisabled: boolean = false;
    if (this.emptyLiveInputs.length === 0){
      buttonDisabled = true;
    }
    else if ((this.inputExtForm.get('name')?.value === '')||
        (this.inputExtForm.get('name')?.value === null)){
      buttonDisabled = true;
    }
    else{
      if (this.inputExtForm.get('liveSourceType')?.value === AvProdInputSourceType.pull) {
        if ((this.inputExtForm.get('liveSourceUrlPull')?.value === '')||
            (this.inputExtForm.get('liveSourceUrlPull')?.value === null)){
          buttonDisabled = true;
        }
      }
    }
    this.addExtButtonDisabled = buttonDisabled;
  }

  protected resetAddExtControls(){
    this.inputExtForm.get('liveSourceType')?.setValue(2);
    this.inputExtForm.get('liveSourcePushProtocol')?.setValue(1);
    this.inputExtForm.get('liveSourceUrlPull')?.setValue('');
    this.inputExtForm.get('name')?.setValue('');
    this.updateAddExtButtonDisabled();
  }
}
