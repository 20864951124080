import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import {
  IUpdateUser,
  IActivateUser,
  ILoginUser,
  IResetPasswordUser,
  ISignUpUser,
  IUser,
  IUserAuthKey,
  IRefreshToken,
  IPushNotification,
  ILoginGoogleUser,
  ILoginAppleUser,
  IUserPlanConfig,
  IDeleteUser,
  IUserSubscriptionConfig,
  IOrganizationInfo,
  IUserOrganizationMember,
  ILoginMicrosoftUser,
} from '../../interfaces/user/user.interface';
import { IApi, IContactData, IHttpResponse } from '../../interfaces/utils/utils.interface';
import { COMMON } from '../../const/common.const';
import { API } from '../../const/api.const';
import { AuthService } from '../auth/auth.service';
import { CryptService } from '../crypt/crypt.service';
import { DeviceService } from '../device/device.service';
import { LanguageService } from '../language/language.service';


@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  pushSubscription: Subscription | undefined;
  onInitialized$: Observable<boolean>;

  private onInitialized: Subject<boolean> = new Subject(); // Indicates if service (user or anonymous User initiated) has been initialized
  private pInitialized: boolean = false;
  private pUser: IUser | undefined;
  private pAnonymousUser: boolean = true;
  private pUserActivation: string = '';
  private pOrgId: number = 0;
  private pOrganization: IOrganizationInfo | undefined;

  protected apiErrorData: IContactData = {
    name: 'Team support',
    email: 'team@azzulei.com',
    subject: '',
    comments: '',
    version: '',
  };

  public user$: BehaviorSubject<IUser | undefined> = new BehaviorSubject<IUser | undefined>(undefined);
  public orgId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient,
              private deviceService: DeviceService,
              public cryptService: CryptService,
              private languageService: LanguageService,
              private authService: AuthService) {
    this.onInitialized$ = this.onInitialized.asObservable();
  }

  public get user(): IUser {
    return this.pUser as IUser;
  }

  public set user(user: IUser | undefined) {
    let newOrgId: number = 0;
    if (user?.id !== undefined && user?.id === this.pUser?.id) {
      newOrgId = -1; // No Org change
    } else {
      const DATA_STR: string | null = localStorage.getItem(
        COMMON.storageKeys.userOrgId
      );
      if (DATA_STR !== null) {
        const DATA: any = JSON.parse(DATA_STR);
        if (DATA.userId !== undefined && DATA.userId === user?.id) {
          if (DATA.orgId !== undefined) {
            newOrgId = DATA.orgId;
          }
        }
      }
    }

    this.pUser = user;
    if (newOrgId !== -1){
      this.orgId = newOrgId;
    }
    this.user$.next(this.pUser);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get anonymousUser(): boolean {
    return this.pAnonymousUser as boolean;
  }

  public set anonymousUser(anonymous: boolean) {
    this.pAnonymousUser = anonymous;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get userActivation(): string {
    return this.pUserActivation;
  }

  public set userActivation(email: string) {
    this.pUserActivation = email;
  }

  public get isInitialized(): boolean {
    return this.pInitialized;
  }

  public set isInitialized(initialized: boolean) {
    this.pInitialized = initialized;
    this.onInitialized.next(this.pInitialized);
  }

  public get organization(): IOrganizationInfo {
    return this.pOrganization as IOrganizationInfo;
  }
  public get orgId(): number {
    return this.pOrgId;
  }
  public set orgId(id: number) {
    let found: boolean = false;
    if (id === 0){
      found = true;
    }
    else if (this.pUser?.organizations !== undefined){
      for (let i: number = 0; i<this.pUser.organizations.length; i++){
        if (this.pUser.organizations[i].id === id){
          found = true;
          break;
        }
      }
    }

    if (found){
      this.pOrgId = id;
    }
    else{
      this.pOrgId = 0;
    }

    this.saveUserOrgId();

    if (this.pOrgId === 0){
      this.pOrganization = undefined;
      this.orgId$.next(this.pOrgId);
    }
    else{
      try{
        this.getOrganization(this.orgId)
            .pipe()
            .subscribe((org: IOrganizationInfo | null) => {
              if (org != null) {
                if (org.id === this.orgId){
                  this.pOrganization = org;
                  console.log('[UserService] setOrgId - OK org data ' + JSON.stringify(this.pOrganization));
                }
                else {
                  this.pOrgId = 0;
                  this.pOrganization = undefined;
                }
              }
              else{
                this.pOrgId = 0;
                this.pOrganization = undefined;
              }
              this.orgId$.next(this.pOrgId);
            });
      }
      catch(err){
        console.error('[UserService] setOrgId - Error requesting org data ' + err);
        this.orgId$.next(this.pOrgId);
      }
    }
  }

  protected saveUserOrgId(){
    if (this.pUser?.id !== undefined){
      const DATA: any = {
        userId: this.pUser?.id,
        orgId: this.pOrgId
      }
      localStorage.setItem(COMMON.storageKeys.userOrgId, JSON.stringify(DATA));
    }
  }

  /**
   * Checks if auth key response has an extra code
   *
   * @param response
   * @returns
   */
  static handleAuthKeyResponse(response: IHttpResponse): IHttpResponse | Observable<never> {
    if (!response.error) {
      if (response.code !== 0) {
        throw new HttpErrorResponse({
          error: {
            code: response.code,
            error: true,
            message: response.message,
          },
          status: 200,
          statusText: response.message,
        });
      } else {
        return response;
      }
    } else {
      return throwError(() => response.error);
    }
  }

  /**
   * Handle data from Sign up response and returns user id & auth key for encryption or throw errors if request's failed
   *
   * @param response
   * @returns {userId, authKey}
   */
  static handleSignUpResponse(response: IHttpResponse): Observable<{ id: number; authKey: string }> {
    if (!response.error) {
      if (response.code === 0) {
        return of({
          id: response.data.id,
          authKey: response.data.auth_key,
        });
      } else {
        return throwError(() => response.code);
      }
    } else {
      return throwError(() => response.message);
    }
  }

  /**
   * Handle data from first pass request response and returns encrypt code or throw 600 error if it fails
   *
   * @param response
   */
  static handleFirstPasswordResponse(response: IHttpResponse): number {
    if (!response.error) {
      return response.code;
    } else {
      return 600;
    }
  }

  /**
   * Handle data from Forgot password
   *
   * @param response
   */
  static handleForgotPasswordResponse(response: IHttpResponse): IHttpResponse | Observable<never> {
    if (!response.error) {
      if(response.code !== 0) {
        return throwError(() => false);
      } else {
        return response;
      }
    } else {
      return throwError(() => response.error);
    }
  }

  /**
   * Formats an organization API response into organization interface
   *
   * @param response
   */
  private handleOrganizationResponse(response: IHttpResponse): IOrganizationInfo {
    if(!response.error) {
      return this.formatOrganizationInput(response.data);
    } else {
      throw new Error('Error');
    }
  }

  ngOnDestroy(): void {
    if (this.pushSubscription) {
      this.pushSubscription.unsubscribe();
    }
  }

  /**
   * If user doesn't already exist, inits user in service if access_token exist in localStorage
   * If token exists: request API user data
   *
   * @returns true if user data has been received, false otherwise
   */
  public initUser(): Observable<boolean> {
    if (this.user) {
      return of(true);
    }
    const TOKEN: string = this.authService.accessToken;
    if (TOKEN !== '') {
      return this.getUserData();
    } else {
      return of(false);
    }
  }

  /**
   * Inits anonymous user in service if access_token does not exist in localStorage
   *
   * @returns true if user data has been received, false otherwise
   */
  public initUserAnonymous(): void {
    this.pAnonymousUser = true;
    this.isInitialized = true;
  }

  /**
   * Signs Up user with signUp form data
   *
   * @param signUpUser
   */
  public signUp(signUpUser: ISignUpUser): Observable<number> {
    const DATA_SIGN_UP: ISignUpUser = this.formatDataSignUp(signUpUser);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.signUp}`, DATA_SIGN_UP)
      .pipe(
        switchMap((response: IHttpResponse) =>
          UserService.handleSignUpResponse(response)
        ),
        map((response: { id: number; authKey: string }) => {
          signUpUser.id = response.id;
          return this.formatPasswordSignUp(signUpUser, response.authKey);
        }),
        switchMap((signUpUserPass: ISignUpUser) =>
          this.sendFirstPassword.bind(this)(signUpUserPass)
        ),
        tap(() => (this.userActivation = DATA_SIGN_UP.email)),
        catchError((e) => {
          if (e) {
            return of(e);
          }
          return of(600);
        })
      );
  }

  /**
   * Activates user´s account
   */
  public activateAccount(activateAccount: IActivateUser): Observable<boolean> {
    const DATA_ACT_ACC: IActivateUser | null = this.formatDataActivateAccount(activateAccount);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.activateAccount}`, DATA_ACT_ACC)
      .pipe(
        map((response: IHttpResponse) => this.handleLoginResponse(response)),
        switchMap(this.getUserData.bind(this)),
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this)),
        catchError((e) => throwError(() => e))
      );
  }

  /**
   * User Login
   * Requests auth key to send password encrypted before login
   *
   * @param loginUser
   * @param versionCode
   */
  public login(loginUser: ILoginUser, versionCode: number): Observable<boolean> {
    return this.getLoginAuthKey({email: loginUser.email?.toLowerCase() ?? '',})
      .pipe(
        map((response: IHttpResponse) =>
          this.formatDataLogin(loginUser, response.data, versionCode)
        ),
        switchMap((loginData: ILoginUser) => this.sendLogin(loginData)),
        switchMap(this.getUserData.bind(this))
      // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
      //tap(this.pushNotificationRegister.bind(this))
    );
  }

  /**
   * User Login
   * Requests auth key to send password encrypted before login
   *
   * @param loginUser
   */
  public loginGoogle(loginUser: ILoginGoogleUser): Observable<boolean> {
    const LOGIN_USER_GOOGLE: ILoginGoogleUser = this.formatDataLoginGoogle(loginUser);

    return this.sendLogin(LOGIN_USER_GOOGLE, '?method=google2')
      .pipe(
        switchMap(this.getUserData.bind(this))
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this))
        );
  }

  /**
   * Gets apple sign in response to log user in azzulei
   *
   * @param loginUser
   */
  public loginApple(loginUser: ILoginAppleUser): Observable<boolean> {
    const LOGIN_USER_APPLE: ILoginAppleUser = this.formatDataLoginApple(loginUser);

    return this.sendLogin(LOGIN_USER_APPLE, '?method=apple')
      .pipe(
        switchMap(this.getUserData.bind(this))
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this))
      );
  }

  /**
   * Gets microsoft sign in response to log user in azzulei
   *
   * @param loginUser
   */
  public loginMicrosoft(loginUser: ILoginMicrosoftUser): Observable<boolean> {
    const LOGIN_USER_MS: ILoginMicrosoftUser = this.formatDataLoginMicrosoft(loginUser);

    return this.sendLogin(LOGIN_USER_MS, '?method=microsoft')
      .pipe(
        switchMap(this.getUserData.bind(this))
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this))
      );
  }

  /**
   * Updates user with update form data
   * First requests update auth key to encrypt new pass & current pass
   *
   * @param updateUser
   */
  public update(updateUser: IUpdateUser): Observable<boolean> {
    if (!this.user.email) {
      return throwError(() => false);
    }

    if (updateUser.curPassword !== undefined) {
      return this.getUpdateAuthKey({ email: this.user.email }).pipe(
        map((response: IHttpResponse) =>
          this.formatDataUpdateUser(updateUser, response.data)
        ),
        switchMap((updateData: IUpdateUser) => this.sendUpdate(updateData)),
        switchMap((codeResult: number) => {
          if (codeResult === 0) {
            return this.getUserData();
          } else {
            return throwError(() => codeResult);
          }
        })
      );
    } else {
      const UPD_DATA: IUpdateUser = this.formatDataUpdateUser(updateUser);
      return this.sendUpdate(UPD_DATA).pipe(
        switchMap((codeResult: number) => {
          if (codeResult === 0) {
            return this.getUserData();
          } else {
            return throwError(() => codeResult);
          }
        })
      );
    }
  }

  public updateLanguage(lang: string): void {
    if (this.user !== undefined && this.user.language !== lang) {
      const UPDATE_USER: IUpdateUser = { language: lang };
      this.update(UPDATE_USER).subscribe();
    }
  }

  /**
   * Gets a new access token from server if access token has expired & refresh token is set
   */
  public renewToken(versionCode: number): Observable<boolean> {
    if (this.authService.refreshToken) {
      const BODY: IRefreshToken = this.formatDataRenewToken(versionCode);
      return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.renewToken}`, BODY)
        .pipe(
          filter((response: IHttpResponse) => !!response),
          switchMap((response: IHttpResponse) => this.handleLoginResponse(response)),
          catchError(() => of(false))
        );
    } else {
      return of(false);
    }
  }

  /**
   * Requests user's logout to server and unauthorized current users in its session
   */
  public logout(): Subscription {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.logout}`)
      .pipe(
        tap(() => {
          /*if(result.data) {*/
          this.anonymousUser = true;
          this.user = undefined;
          localStorage.removeItem(COMMON.storageKeys.userOrgId);
          // Todo: Susana Mirar si el servicio de push debería de llamarse solo desde la app mobile
          //this.pushService.removeData();
          //this.removeSectionPermissions();
          if (this.pushSubscription) {
            this.pushSubscription.unsubscribe();
          }
          /* }*/
        }),
        switchMap(() => from(this.authService.authDisallowed())),
        catchError((err) => {
          //TODO: Susana revisar los posibles mensajes de error que puede devolver la api para que no haya problemas con el log out
          console.error(err);
          this.anonymousUser = true;
          this.user = undefined;
          this.authService.authDisallowed().catch(console.error);
          if (this.pushSubscription) {
            this.pushSubscription.unsubscribe();
          }
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  /**
   * Requests user's deletion account to server
   */
  public deleteAccount(): Observable<boolean> {
    this.anonymousUser = true;
    return this.http
      .delete(`${API.urls.server}/${API.urls.actions.users.deleteAccount}`, {
        body: { email: this.user.email },
      })
      .pipe(switchMap(() => from(this.authService.authDisallowed())));
  }

  /**
   * Sends a new email with activation code
   *
   * @param activateAccount
   */
  public sendActivation(activateAccount: IActivateUser): Observable<boolean> {
    const DATA_ACT_ACC: IActivateUser | null = this.formatDataActivateAccount(activateAccount);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.sendActivationCode}`, DATA_ACT_ACC)
      .pipe(
        map((response: IHttpResponse) => !response.error),
        catchError(() => of(false))
      );
  }

  /**
   * Sends an email with password restoration code
   *
   * @param resetPassword
   */
  public sendForgotPassword(resetPassword: IResetPasswordUser): Observable<any> {
    const DATA_ACT_ACC: IResetPasswordUser = this.formatForgotPassword(resetPassword);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.sendForgotPasswordCode}`, DATA_ACT_ACC)
      .pipe(
        map((response: IHttpResponse) =>
          UserService.handleForgotPasswordResponse(response)
        )
      );
  }

  /**
   * Checks restoration code
   *
   * @param resetPassword
   */
  public checkForgotPasswordCode(resetPassword: IResetPasswordUser): Observable<any> {
    const DATA_ACT_ACC: IResetPasswordUser = this.formatForgotPassword(resetPassword);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.checkForgotPasswordCode}`, DATA_ACT_ACC)
      .pipe(
        map((response: IHttpResponse) =>
          UserService.handleForgotPasswordResponse(response)
        )
      );
  }

  /**
   * Resets password
   *
   * @param resetPassword
   */
  public resetPassword(resetPassword: IResetPasswordUser): Observable<any> {
    const DATA_ACT_ACC: IResetPasswordUser = this.formatForgotPassword(resetPassword);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.resetPassword}`, DATA_ACT_ACC)
      .pipe(
        tap((response: IHttpResponse) => {
          if(!response.error && response.code === 0 && (this.user && this.user.defaultPassword)) {
            this.user.defaultPassword = false;
          }
        }),
        map((response: IHttpResponse) => UserService.handleForgotPasswordResponse(response))
      );
  }

  /**
   * Sends an email with deletion account code
   *
   * @param deleteUser
   */
  public sendDeletionAccountCode(deleteUser: IDeleteUser): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.sendDeletionCode}`, deleteUser)
      .pipe(
        map((response: IHttpResponse) => !response.error),
        catchError(() => of(false))
      );
  }

  /**
   * Sends an email with deletion account code
   *
   * @param passwordUser
   */
  public sendSetPasswordCode(passwordUser: IResetPasswordUser): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.sendSetPasswordCode}`, passwordUser)
      .pipe(
        map((response: IHttpResponse) => !response.error),
        catchError(() => of(false))
      );
  }

  /**
   * Checks deletion code entered by user
   *
   * @param deleteUser
   */
  public checkDeletionAccountCode(deleteUser: IDeleteUser): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.checkDeletionCode}`, deleteUser)
      .pipe(
        map((response: IHttpResponse) => !response.error),
        catchError(() => of(false))
      );
  }

  /**
   * Checks if current user has active events
   *
   * @param userId
   */
  public hasActiveEvents(userId: number): Observable<boolean> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.hasActiveEvents}/${userId}`)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => response.data),
        catchError(() => of(false))
      );
  }

  public resetUser(): void {
    this.user = undefined;
    this.anonymousUser = true;
  }

  /**
   * Changes the push notifications data associated with the user
   *
   * @param pushData
   */
  public changePush(pushData: IPushNotification): void {
    this.user.push = pushData;
    this.update({ push: this.user.push }).subscribe();
  }

  /**
   * Sends signup password to server after auth key is received
   *
   * @param signUpUser
   * @private
   */
  private sendFirstPassword(signUpUser: ISignUpUser): Observable<number> {
    return this.http
      .put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.setFirstPassword}`, signUpUser)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) =>
          UserService.handleFirstPasswordResponse(response)
        ),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Sends login data to server after auth key is received
   *
   * @param loginData
   * @param method
   * @private
   */
  private sendLogin(loginData: ILoginUser | ILoginGoogleUser | ILoginAppleUser | ILoginMicrosoftUser, method: string = ''): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.login}${method}`, loginData)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => this.handleLoginResponse(response)),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Sends current user's updated data after update auth key is received
   *
   * @param userData
   * @returns
   */
  private sendUpdate(userData: IUpdateUser): Observable<number> {
    return this.http
      .put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.update}`, userData)
      .pipe(
        map((response: IHttpResponse) =>
          this.handleUpdateResponse(response, userData)
        ),
        catchError(() => of(600))
      );
  }

  /**
   * Gets user data if token is set
   *
   * @returns true if user data has been retrieved, false otherwise
   */
  public getUserData(): Observable<boolean> {
    //console.log('[UserService] getUserData');
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getData}`)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => this.handleUserDataResponse(response)),
        map((user: IUser | undefined) => {
          //console.log('[UserService] getUserData: Receive user data ' + JSON.stringify(user));
          this.isInitialized = true;
          if (user) {
            this.anonymousUser = false;
            this.user = user;
            if (this.user.language !== undefined) {
              this.languageService.changeLanguage(this.user.language)
                .catch(console.error);
            }
            if (this.user.device === undefined) {
              this.user.device = this.deviceService.device;
            }
            // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
            //this.pushService.initialize(this.user.id)
            //.catch(console.error);
            return true;
          } else {
            this.anonymousUser = true;
            return false;
          }
        }),
        catchError(() => of(false))
      );
  }

  /**
   * Sends organization data to the API to create a new organization
   *
   * @param org
   */
  public createOrganization(org: IOrganizationInfo): Observable<IOrganizationInfo> {
    const ORG = this.formatOrganizationOutput(org);
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.create}`, ORG)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map(this.handleOrganizationResponse.bind(this)),
        catchError( () => of(org))
      );
  }

  /**
   * Sends organization data to the API to update an organization
   *
   * @param org
   */
  public updateOrganization(org: IOrganizationInfo): Observable<IOrganizationInfo> {
    const ORG_UPDATE = this.formatOrganizationOutput(org);
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.update}/${org.id}`, ORG_UPDATE)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val: IHttpResponse) => {
          return this.handleOrganizationResponse(val)
        }),
        catchError( () => of(org))
      );
  }

  /**
   * Requests organization data from the API
   *
   * @param id
   */
  protected getOrganization(id: number): Observable<IOrganizationInfo | null> {
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.get}/${id}`)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((val: IHttpResponse) => {
          return this.handleOrganizationResponse(val)
        }),
        catchError( () => of(null))
      );
  }

  public getOrganizationData(): Observable<boolean>{
    //console.log('[UserService] getOrganizationData');
    return this.http.get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.get}/${this.pOrgId}`)
      .pipe(
        filter((val: IHttpResponse) => !val.error),
        map((val: IHttpResponse) => {
          return this.handleOrganizationResponse(val)
        }),
        map((org: IOrganizationInfo | undefined) => {
          if (org) {
            //console.log('[UserService] getOrganizationData: ' + JSON.stringify(org));
            if (org.id === this.pOrgId){
              this.pOrganization = org;
              return true;
            }
            this.orgId = 0;
            return false;
          } else {
            this.orgId = 0;
            return false;
          }
        }),
        catchError(() => of(false))
      );
  }

  /**
   * Request delete organization to API
   *
   * @param id
   */
  public deleteOrganization(id: number): Observable<boolean> {
    return this.http.delete<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.delete}/${id}`)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data));
  }

  /**
   * Sends organization member invitation to API
   *
   * @param orgId
   * @param email
   */
  public inviteOrganizationMember(orgId: number, email: string): Observable<boolean> {
    let payload: any = {
      organization_id: orgId,
      email: email,
    }
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.inviteMember}`, payload)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data),
        catchError( () => of(false))
      );
  }

  /**
   * Requests organization member removal to API
   *
   * @param orgId
   * @param email
   * @param userId
   */
  public removeOrganizationMember(orgId: number, email: string | undefined, userId: number | undefined): Observable<boolean> {
    let payload: any = {
      organization_id: orgId,
      user_id: userId,
      email: email,
    }
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.removeMember}`, payload)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data),
        catchError( () => of(false))
      );
  }

  /**
   * Accepts organization member invitation
   *
   * @param orgId
   */
  public acceptOrganizationInvitation(orgId: number): Observable<boolean> {
    let payload: any = {
      organization_id: orgId,
    }
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.acceptInvitation}`, payload)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data),
        catchError( () => of(false))
      );
  }

  /**
   * Declines organization member invitation
   *
   * @param orgId
   */
  public declineOrganizationInvitation(orgId: number): Observable<boolean> {
    let payload: any = {
      organization_id: orgId,
    }
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.organizations.declineInvitation}`, payload)
      .pipe(
        filter((val: IHttpResponse) => !!val),
        map((resp: IHttpResponse) => resp.data),
        catchError( () => of(false))
      );
  }

  /**
   * Returns true if a defined user has any organization
   *
   * @return boolean
   */
  public hasOrganizations(): boolean {
    if(this.user) {
      if(this.user.organizations &&  this.user.organizations.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets auth key to encrypt password
   *
   * @param data
   * @returns
   */
  private getLoginAuthKey(data: IUserAuthKey): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getLoginAuthKey}`, data)
      .pipe(
        filter((response: IHttpResponse) => !!response),
        map((response: IHttpResponse) =>
          UserService.handleAuthKeyResponse(response)
        )
      );
  }

  /**
   * Gets auth key to encrypt password
   *
   * @param data
   * @returns
   */
  private getUpdateAuthKey(data: IUserAuthKey): Observable<any> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getUpdateAuthKey}`, data)
      .pipe(
        filter((response: IHttpResponse) => !!response),
        map((response: IHttpResponse) =>
          UserService.handleAuthKeyResponse(response)
        )
      );
  }

  /**
   * Sends push notifications data to update
   *
   * @param data
   * @private
   */
  /* private setPushData(data: IPushNotification): Observable<boolean | IHttpResponse> {
    const PUSH_DATA: IApi<IPushNotification> = this.formatPushData(data);
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.setPushData}`, {push:  PUSH_DATA})
      .pipe(
        filter((response: IHttpResponse) => !!response),
        catchError(() => of(false))
      );
  }*/

  /**
   * Formats a LoginData API response into user interface
   *
   * @param response
   */
  private handleLoginResponse(response: IHttpResponse): Observable<boolean> {
    if (!response.error) {
      this.anonymousUser = false;
      return from(
        this.authService.authAllowed(
          response.data.access_token,
          response.data.refresh_token
        )
      );
    } else {
      return of(false);
    }
  }

  /**
   * Formats a datUserData API response into user interface
   *
   * @param response
   */
  private handleUserDataResponse(response: IHttpResponse): IUser | undefined {
    if (!response.error) {
      const USER: IUser = this.formatUserInput(response.data);
      return USER ?? undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Validates Update response
   *
   * @param response
   * @param newData
   */
  private handleUpdateResponse(response: IHttpResponse, newData: IUpdateUser): number {
    if (!response.error) {
      if (response.data) {
        if (response.code === 0) {
          if (newData.name !== '' || newData.name !== undefined) {
            this.user.name = newData.name;
          }
          if (newData.language !== undefined && newData.language !== '') {
            this.user.language = newData.language;
          }
        }
      }
      return response.code;
    } else {
      return 600;
    }
  }

  /**
   * Formats SignUp data
   *
   * @param user
   * @private
   */
  private formatDataSignUp(user: ISignUpUser): ISignUpUser {
    return {
      id: 0,
      name: user.name,
      email: user.email,
      password: '',
      language: this.languageService.getPriorityLanguage().iso,
    };
  }

  /**
   * Formats Password SignUp data
   *
   * @param user
   * @param authKey
   * @private
   */
  private formatPasswordSignUp(user: ISignUpUser, authKey: string): ISignUpUser {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      password: this.cryptService.encrypt(user.password, authKey),
      language: this.languageService.getPriorityLanguage().iso,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @param authKey
   * @param versionCode
   * @private
   */
  private formatDataLogin(user: ILoginUser, authKey: string, versionCode: number): ILoginUser {
    return {
      email: user.email?.toLowerCase(),
      password: user.password
        ? this.cryptService.encrypt(user.password, authKey)
        : '',
      device: this.deviceService.formatDeviceOutput(),
      version: versionCode,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @private
   */
  private formatDataLoginGoogle(user: ILoginGoogleUser): ILoginGoogleUser {
    user.language = this.languageService.deviceLang.preferred.iso;
    return {
      clientId: user.clientId,
      id: user.id,
      email: user.email,
      name: user.name,
      language: user.language,
      device: this.deviceService.formatDeviceOutput(),
      version: user.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @private
   */
  private formatDataLoginApple(user: ILoginAppleUser): ILoginAppleUser {
    user.language = this.languageService.deviceLang.preferred.iso;
    return {
      identityToken: user.identityToken,
      authorizationCode: user.authorizationCode,
      name: user.name,
      language: user.language,
      user: user.user,
      device: this.deviceService.formatDeviceOutput(),
      version: user.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @private
   */
  private formatDataLoginMicrosoft(user: ILoginMicrosoftUser): ILoginMicrosoftUser {
    user.language = this.languageService.deviceLang.preferred.iso;
    return {
      displayName: user.displayName,
      givenName: user.givenName,
      id: user.id,
      mail: user.mail,
      surname: user.surname,
      userPrincipalName: user.userPrincipalName,
      language: user.language,
      device: this.deviceService.formatDeviceOutput(),
      version: user.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats user's update data and encrypt password if necessary
   *
   * @param user
   * @param authKey
   * @private
   */
  private formatDataUpdateUser(user: IUpdateUser, authKey?: string): IUpdateUser {
    if (!user.name) {
      user.name = '';
    }

    if (!user.curPassword) {
      user.curPassword = '';
    }

    if (!user.newPassword) {
      user.newPassword = '';
    }

    if (!user.push) {
      user.push = undefined;
    }

    return {
      email: this.user.email,
      name: user.name,
      curPassword:
        authKey && user.curPassword !== ''
          ? this.cryptService.encrypt(user.curPassword, authKey)
          : '',
      newPassword:
        authKey && user.newPassword !== ''
          ? this.cryptService.encrypt(user.newPassword, authKey)
          : '',
      language: user.language,
      push: user.push,
    };
  }

  /**
   * Formats Renew token data
   *
   * @private
   */
  private formatDataRenewToken(versionCode: number): IRefreshToken {
    return {
      email: this.authService.parseJwt().user,
      device: this.deviceService.formatDeviceOutput(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: this.authService.refreshToken,
      version: versionCode,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats activate account data
   *
   * @param activateAccount
   * @private
   */
  private formatDataActivateAccount(activateAccount: IActivateUser): IActivateUser | null {
    if (!this.userActivation) {
      return null;
    }
    return {
      email: this.userActivation,
      device: this.deviceService.formatDeviceOutput(),
      code: activateAccount.code,
      version: activateAccount.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats forgot password data
   *
   * @param forgotPassword
   * @private
   */
  private formatForgotPassword(forgotPassword: IResetPasswordUser): IResetPasswordUser {
    return {
      email: forgotPassword.email ? forgotPassword.email : '',
      code: forgotPassword.code ?? undefined,
      password: forgotPassword.password
        ? this.cryptService.encrypt(
            forgotPassword.password,
            forgotPassword.authKey
          )
        : '',
    };
  }

  /**
   * Formats forgot password data
   *
   * @private
   * @param pushData
   */
  /* private formatPushData(pushData: IPushNotification): IApi<IPushNotification> {
    return {
        id: pushData.id ?? '',
        timestamp: pushData.timestamp ?? 0,
        error: pushData.error ?? '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        error_timestamp: pushData.errorTimestamp ?? 0,
        allowed: true,
    };
  }*/

  private formatUserInput(user: IApi<IUser>): IUser {
    return {
      id: user.id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      password: user.password,
      device: user.device,
      language: user.language,
      image: user.image,
      lastLogin: user.last_login,
      code: user.code,
      push: user.push,
      productKey: user.product_key,
      storageUsed: user.storage_used,
      storageTotal: user.storage_total,
      streamingTime: user.streaming_time,
      defaultPassword: user.default_password,
      organizations: user.organizations,
      plan: this.formatUserPlanConfigInput(user.plan),
      subscription: this.formatUserSubscriptionConfigInput(user.subscription)
    };
  }

  private formatUserOrganizationMembersInput(members: IApi<IUserOrganizationMember>[] | undefined): IUserOrganizationMember[] {
    let list: IUserOrganizationMember[] = [];
    if ((members !== undefined)&&(members !== null)){
      list = [];
      for (let i:number=0; i<members.length; i++){
        let item: IUserOrganizationMember = {
          userId: members[i].user_id,
          email: members[i].email,
          role: members[i].role,
          status: members[i].status,
        }
        list.push(item);
      }
    }
    return list;
  }

  private formatUserPlanConfigInput(userPlan: IApi<IUserPlanConfig> | undefined): IUserPlanConfig | undefined {
    if ((userPlan !== undefined)&&(userPlan !== null)){
      return {
        channel: userPlan.channel,
        concurrentEvents: userPlan.concurrent_events,
        customInputs: userPlan.custom_inputs,
        customRtmp: userPlan.custom_rtmp,
        customSrt: userPlan.custom_srt,
        dvrTime: userPlan.dvr_time,
        extraAudioInputs: userPlan.extra_audio_inputs,
        maxDestinations: userPlan.max_destinations,
        maxDuration: userPlan.max_duration,
        maxHighlights: userPlan.max_highlights,
        maxHtmlGraphics: userPlan.max_html_graphics,
        maxLiveInputs: userPlan.max_live_inputs,
        maxProducers: userPlan.max_producers,
        maxResolution: userPlan.max_resolution,
        maxSetup: userPlan.max_setup,
        maxViewers: userPlan.max_viewers,
        storage: userPlan.storage,
        maxStreamingTime: userPlan.max_streaming_time,
        watermark: userPlan.watermark
      };
    }
    else{
      return undefined;
    }
  }

  private formatUserSubscriptionConfigInput(userSubscription: IApi<IUserSubscriptionConfig> | undefined): IUserSubscriptionConfig | undefined {
    if ((userSubscription !== undefined)&&(userSubscription !== null)){
      return {
        cancelAt: userSubscription.cancel_at,
        currentPeriodEnd: userSubscription.current_period_end,
        currentPeriodStart: userSubscription.current_period_start,
        startDate: userSubscription.start_date,
        status: userSubscription.status,
        trialEnd: userSubscription.trial_end
      };
    }
    else{
      return undefined;
    }
  }

  private formatOrganizationOutput(org: IOrganizationInfo): IApi<IOrganizationInfo> {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      id: org.id,
      name: org.name,
      alias: org.alias,
      description: org.description,
    };
  }

  private formatOrganizationInput(org: IApi<IOrganizationInfo>): IOrganizationInfo {
    return {
      id: org.id,
      name: org.name,
      alias: org.alias,
      description: org.description,
      storageUsed: org.storage_used,
      storageTotal: org.storage_total,
      productKey: org.product_key,
      streamingTime: org.streaming_time,
      subscription: this.formatUserSubscriptionConfigInput(org.subscription),
      plan: this.formatUserPlanConfigInput(org.plan),
      members: this.formatUserOrganizationMembersInput(org.members),
      profilePhoto: org.profile_photo,
      backgroundPhoto: org.background_photo,
    };
  }

  // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
  /* private pushNotificationRegister() {
    if(!this.pushSubscription) {
      this.pushSubscription = this.pushService.pushData
        .pipe(
          filter(val => val !== undefined && val !== null),
          first(),
          switchMap((data: IPushNotification) => this.setPushData(data))
        )
        .subscribe();
    }
  }*/
}
