import { Subscription, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IPlayerData } from '../../interfaces/player/player.interface';
import { IEvent, IEventListItemData, IEventShareInfoSection } from '../../interfaces/events/event.interface';
import { IAlertData, IModalDialogData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { ModalDialogId } from '../../enums/utils.enum';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { ShareInfoService } from '../../services/share-info/share-info.service';
import { inject } from '@angular/core';


export class PlayerPageClass {

  private shareSrv: ShareInfoService = inject(ShareInfoService);

  protected progressPercentage: number = 0;
  protected timerIntervalSubscription: Subscription = new Subscription();
  protected timerBannerSubscription: Subscription = new Subscription();
  protected playerDataReady: boolean = false;
  protected eventStarted: boolean = true;
  protected eventInfoTimestamp: number = 0;

  protected modalDialogId = ModalDialogId;
  protected modalDataCurrent: IModalDialogData = {id: ModalDialogId.none, title: ''};

  protected modalServiceSubscription: Subscription | undefined;

  /*protected bannerURL: string = '//armedtidying.com/watchnew?key=' + ENV_WEB.banners.watch;
  protected showBanner: boolean = !ENV_COMMON.production;*/

  //displayModalReportContent: boolean = false;
  //displayModalReportContentTS: number = 0;

  //protected listEventConfig: IEventListConfiguration = {
  //  itemConfig: { mode: 'horizontal'}
  //}

  protected playerData: IPlayerData = {
    id: 0,
    name: 'Name',
    token: '',
    start: new Date(),
    protected: false,
    password: '',
    status: ''
  }

  protected event: IEvent = {
    name: 'Name',
    userName: 'author',
    description: 'Description',
    categoryId: 1,
    protected: false,
    private: false,
    currentViewers: 0,
    totalViewers: 0
  }

  protected eventData: IEventListItemData = {
    id: 0,
    token: 'xxx-xxx-xxx',
    name: 'Name',
    author: 'Author',
    status: '',
    host: '',
    categoryId: 1,
    description: 'Description',
    viewersCurrent: 1,
    viewersTotal: 1,
    snapshotUrl: 'assets/images/events/azzulei.png',
    private: false,
    protected: false
  }

  protected validatingToken: boolean | undefined = undefined;
  private _token: string = '';

  constructor(protected activatedRoute: ActivatedRoute,
              protected eventsService: EventsService,
              protected router: Router,
              protected translate: TranslateService,
              protected users: UserService,
              protected modalService: ModalManagerService) {
  }

  set token(token: string) {
    if (this._token !== token){
      this.playerDataReady = false;
    }
    this._token = token;
  }

  get token(): string {
    return this._token;
  }

  protected init(token: string): void {
    if (this._token !== token){
      this.playerDataReady = false;
    }
    this.token = token;
    this.playerData.token = this.token;
    console.log('[PlayerPageComponent] Token: ' + this.token);
    this.validatingToken = true;
    this.checkToken();

    this.modalServiceSubscription?.unsubscribe();
    this.modalServiceSubscription = this.modalService.modalManagerEvent$.subscribe(value => {
      this.onModalNewData(value);
    });
    this.timerIntervalSubscription?.unsubscribe();
    this.timerIntervalSubscription = timer(1000, 1000).subscribe(count => this.tickInterval(count));
    this.timerBannerSubscription?.unsubscribe();
    this.timerBannerSubscription = timer(60000, 60000).subscribe(() => this.reloadBanner());
  }

  protected destroy() {
    this.modalServiceSubscription?.unsubscribe();
    if (this.timerIntervalSubscription) {
      this.timerIntervalSubscription.unsubscribe();
    }

    if (this.timerBannerSubscription) {
      this.timerBannerSubscription.unsubscribe();
    }
  }

  protected exitPage(): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }
  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }
  protected hideModal(): void {
    // must override
  }
  protected showModal(): void {
    // must override
  }


  protected onModalNewData(data: IModalDialogData) {
    this.modalDataCurrent = data;
    console.log('[PlayerPageClass] onModalNewData ' + JSON.stringify(data));
    switch (data.id) {
      case ModalDialogId.none:
        this.hideModal();
        break;
      default:
        this.showModal()
        break;
    }
  }

  protected tickInterval(count?: number): void {

    const NOW: Date = new Date(Date.now());
    if ((this.eventData.start !== undefined) && (this.eventData.end !== undefined)) {
      if (this.eventData.end < this.eventData.start) {
        this.progressPercentage = 0;
      } else if (NOW < this.eventData.start) {
        this.progressPercentage = 0;
      } else if (NOW > this.eventData.end) {
        this.progressPercentage = 100;
      } else {
        this.progressPercentage = 100 * (NOW.getTime() - this.eventData.start.getTime()) / (this.eventData.end.getTime() - this.eventData.start.getTime());
      }
    } else {
      this.progressPercentage = 0;
    }

    let checkInfoInterval: number = 10000;
    if (!this.eventStarted) checkInfoInterval = 5000;
    if (NOW.getTime() - this.eventInfoTimestamp > checkInfoInterval) {
      if(this.validatingToken !== true && count !== undefined && count > 3) {
        this.checkToken();
      }
    }
  }

  protected checkToken() {
    console.log('[PlayerPageComponent] checkToken: ' + this.token);
    this.eventsService.getEventInfo('viewer', this.token)
      .pipe()
      .subscribe((event: IEvent | null) => {
        this.validatingToken = false;
        if (event !== null) {
          console.log('[PlayerPageComponent] getEvent: ' + JSON.stringify(event));
          this.event = event;
          // Assign player data info
          if (this.event.id) this.playerData.id = this.event.id;
          else this.event.id = 0;
          this.playerData.name = this.event.name;
          this.playerData.status = this.event.status ? this.event.status : 'live';
          if (this.event.started) this.playerData.start = new Date(this.event.started * 1000);
          // OJO!! Revisar el tipo de protected y private
          if (this.event.protected === true) {
            this.playerData.protected = true;
            this.eventData.protected = true;
          } else if (this.event.protected === false) {
            this.playerData.protected = false;
            this.eventData.protected = false;
          }

          // Add this token to previously used token list
          this.eventsService.saveToken(this.token, 'viewer', this.event.name);

          // Assign event data info
          if (this.event.id) this.eventData.id = this.event.id;
          else this.event.id = 0;
          this.eventData.name = this.event.name;
          if (this.event.status) this.eventData.status = this.event.status;
          if (this.event.started) this.eventData.start = new Date(this.event.started * 1000);
          else this.eventData.start = undefined;
          if (this.event.ended) this.eventData.end = new Date(this.event.ended * 1000);
          else this.eventData.end = undefined;
          if (this.event.description) this.eventData.description = this.event.description;
          if (this.event.userName) this.eventData.author = this.event.userName;
          if (this.event.totalViewers) {
            if (this.event.totalViewers > 0) {
              this.eventData.viewersTotal = this.event.totalViewers;
            } else {
              this.eventData.viewersTotal = 1;
            }
          }
          if (this.event.currentViewers) {
            if (this.event.currentViewers > 0) {
              this.eventData.viewersCurrent = this.event.currentViewers;
            } else {
              this.eventData.viewersCurrent = 1;
            }
          }
          this.eventInfoTimestamp = (new Date(Date.now()).getTime());

          // Only open video player if event started at least 12 seconds ago
          if ((this.eventData.start != undefined) && (this.eventInfoTimestamp - this.eventData.start.getTime() > 12000)) {
            this.playerDataReady = true;
            this.eventStarted = true;
            console.log('[PlayerPageComponent] Event started ' + (this.eventInfoTimestamp - this.eventData.start.getTime()) + ' ago');
          } else {
            this.eventStarted = false;
          }
        } else {
          console.log('This token is not valid');
          // Display error
          this.showError('general.errorTokenNotValid');
          this.exitPage();
        }
      });
  }

  private showError(detail: string): void {
    this.displayToast({
      options: {
        placement: ToastPlacement.topCenter,
      },
      data: {
        status: ToastStatus.error,
        text: detail,
        closeButtonBody: true,
      },
    });
  }

  protected reloadBanner() {
    const iframe = document?.getElementById('adBanner') as HTMLIFrameElement;

    if (iframe) {
      // eslint-disable-next-line no-self-assign
      iframe.src = iframe.src;
    }
  }

  protected reportContent() {
    if (this.users.anonymousUser) {

      this.displayAlert({
        show: true,
        status: AlertStatus.error,
        title: 'player.reportContent',
        text: 'player.reportContentAnonymousDetail',
        closeButton: true,
        buttons: [{
          text: 'general.close',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        }]
      });

      // this.messageService.add({
      //   key: AvProdToastKeys.watchPage,
      //   severity:'error',
      //   summary: this.translate.instant('player.reportContent'),
      //   detail: this.translate.instant('player.reportContentAnonymousDetail')});
    } else {
      //const TIME_NOW: number = (new Date()).getTime();
      //this.displayModalReportContent = true;
      //this.displayModalReportContentTS = TIME_NOW;
      this.modalService.show({id: ModalDialogId.playerReportContent, subId: this.event.id, title: 'player.reportContent'}, false);
    }
  }

  protected onReportContentSent(status: boolean) {
    //this.displayModalReportContent = false;
    // this.messageService.add({
    //   key: AvProdToastKeys.watchPage,
    //   severity:'success',
    //   summary: this.translate.instant('player.reportContent'),
    //   detail: this.translate.instant('player.reportContentSuccess')});
  }

  protected onClickShare(section: string): void {
    const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this.event, section);
    this.shareSrv.shareToken(SHARE_DATA);
  }

  protected onClickCopy(section: string): void {
    const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this.event, section);
    let url: string = SHARE_DATA.url;
    this.copyToClipboard(url);
  }

  protected copyToClipboard(url: string): void {
    //TO BE OVERRIDE
  }


}
