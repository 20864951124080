import { AvProdOverlayScoreSport } from '../../const/av-producer.const';
import { IAvProdOverlaySettings } from './overlay-settings.interface';

    export interface IAvProdHighlightSettings {
        auto?: boolean;
        preRoll?: number;
        postRoll?: number;
    }

    export interface IAvProdVideoBackground {
        type?: number;
        inputId?: number;
        colors?: string[];
    }

    export interface IAvProdTextGroup {
        id?: number;
        title?: string;
        text?: string;
    }

    export interface IAvProdOverlayBanner {
        enabled?: boolean;
        texts?: IAvProdTextGroup[];
        textSelected?: number;
        docked?: boolean;
        positionPreset?: number;
        sizePreset?: number;
        alignment?: number;
        sizeScale?: number;
        opacity?: number;
        colorBack?: string;
        colorText?: string;
        fontFace?: string;
    }

    export interface IAvProdOverlayInputNames {
        enabled?: boolean;
        positionHor?: number;
        positionVer?: number;
        opacity?: number;
        colorBack?: string;
        colorText?: string;
    }

    export interface IAvProdOverlayTicker {
        enabled?: boolean;
        texts?: IAvProdTextGroup[];
        textSelected?: number;
        fontFace?: string;
        colorBack?: string;
        colorText?: string;
        opacity?: number;
    }

    export interface IAvProdOverlayTime {
        enabled?: boolean;
        format?: string;
        fontFace?: string;
    }

    export interface IAvProdOverlayChrono {
        enabled?: boolean;
        format?: string;
        period?: number;
        fontFace?: string;
    }

    export interface IAvProdOverlayStyle {
        mode?: number;
        colorMain?: string;
        colorSecondary?: string;
        fontFace?: string;
    }

    export interface IAvProdScoreTeam {
        name?: string;
        nameShort?: string;
        colorFormat?: number;
        colors?: string[];
        logoInputId?: number;
    }

    export interface IAvProdOverlayScore {
        enabled?: boolean;
        sport?: AvProdOverlayScoreSport;
        format?: string;
        values?: number[];
        valuesStr?: string[];
        currentSet?: number;
        sets?: number;
        tieBreak?: boolean;
        serve?: number;
        baseballBall?: number;
        baseballStrike?: number;
        baseballOut?: number;
        baseballBases?: boolean[];
        valuesSets?: number[];
        valuesSetsAll?: number[][];
        teams?: IAvProdScoreTeam[];
        fontFace?: string;
        fontFaceNames?: string;
    }

    export interface IAvProdTimeTag {
        name?: string;
        dateTime?: string;
        id?: number;
        ts?: number;
        highlightDone?: boolean;
    }

    export interface IAvProdVideoClipList {
        inputId: number;
        state: number;
    }

    export interface IAvProdPlaylist {
        tileId: number;
        playingMode: number;
        list: IAvProdVideoClipList[];
    }

    export interface IAvProdComposerSettings {
        layoutId?: number;
        audioMasterVolume?: number;
        audioMasterMode?: number;
        videoTransitionMode?: number;
        videoTransitionDirection?: number;
        videoTransitionSpeed?: number;
        layoutTransitionMode?: number;
        layoutTransitionSpeed?: number;
        liveInputAudioDefaultMode?: number;
        videoSelection?: number[];
        highlights?: IAvProdHighlightSettings;
        videoBackground?: IAvProdVideoBackground;
        overlayBanner?: IAvProdOverlayBanner;
        overlayInputNames?: IAvProdOverlayInputNames;
        overlayTicker?: IAvProdOverlayTicker;
        overlayTime?: IAvProdOverlayTime;
        overlayChrono?: IAvProdOverlayChrono;
        overlayScore?: IAvProdOverlayScore;
        overlayAdvanced?: IAvProdOverlaySettings[];
        overlayStyle?: IAvProdOverlayStyle;
        timeTagsNextId?: number;
        timeTags?: IAvProdTimeTag[];
        playlists?: IAvProdPlaylist[];
    }
