import { inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { timer, Subscription } from 'rxjs';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { EventStatusLayoutMode, ModalDialogId } from '../../enums/utils.enum';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IEvent, IEventShareInfoSection, IEventUserLastChanges } from "../../interfaces/events/event.interface";
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { EVENTS } from '../../const/events.const';
import { EventsService } from '../../services/events/events.service';
import { ShareInfoService } from "../../services/share-info/share-info.service";
import { ModalManagerService } from "../../services/modal/modal-manager.service";
import { UserService } from '../../services/user/user.service';


export class EventStatusClass {

  private shareSrv: ShareInfoService = inject(ShareInfoService);
  static readonly INTERVAL_REFRESH_CREATION: number = 2000;   // Refresh event every 2 seconds during creation
  static readonly INTERVAL_REFRESH_ACTIVE: number = 0;   // Refresh event every 5 seconds during active, try now no refresh
  static readonly INTERVAL_REFRESH_ARCHIVED: number = 0;   // Do not refresh event if archived

  protected _event: IEvent = {
    name: 'Name',
    userName: '',
    description: '',
    categoryId: 1,
    protected: false,
    private: false
  }
  protected _mode: string = 'creating'; // 'none' | 'creating' | 'active' | 'finished' | 'expired' | 'archived'
  protected _sections: string[] = ['produce', 'publish', 'watch'];
  protected _creationProgress: number = 0;
  protected _refreshEvent: boolean = true;
  protected _accordion: boolean = false;
  protected _layoutMode: EventStatusLayoutMode = EventStatusLayoutMode.list;
  protected _expanded: boolean = false;
  protected displayModalEmbedded: boolean = false;
  protected urlDownload: string = '';
  protected watchEmbeddedCode: string = '';
  protected downloadBusy: boolean = false;
  protected storageAvailable: number = 0;

  protected urlStudio: string = '';
  protected urlWatch: string = '';
  protected urlLiveInput: string = '';

  private receiveStatusSubscription: Subscription | undefined;
  private requestEventSubscription: Subscription | undefined;
  private userChangesSubscription: Subscription | undefined;
  private downloadBusyTimerSubscription: Subscription | undefined;

  constructor(protected translate: TranslateService,
              protected eventsService: EventsService,
              protected modalService: ModalManagerService,
              protected userService: UserService) {
    // do nothing
  }

  init(): void {
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.eventsService.userChanges$.subscribe((data: IEventUserLastChanges) => this.checkUserChanges(data));
    this.setCopyUrls();
    this.updateStorageAvailable();
  }

  destroy(): void {
    this.userChangesSubscription?.unsubscribe();
    if (this.requestEventSubscription !== undefined) this.requestEventSubscription.unsubscribe();
    if (this.receiveStatusSubscription !== undefined) this.receiveStatusSubscription.unsubscribe();
    if (this.downloadBusyTimerSubscription !== undefined) this.downloadBusyTimerSubscription.unsubscribe();
  }

  protected setAccordion(value: boolean): void {
    this._accordion = value;
  }

  protected setLayoutMode(value: EventStatusLayoutMode): void {
    this._layoutMode = value;
  }

  protected setRefreshEvent(refresh: boolean): void {
    this._refreshEvent = refresh;
    if (this._refreshEvent) {
      this.requestEvent();
      this.updateStatus();
    }
  }

  protected setEvent(value: IEvent): void {
    this._event = value;
    this.urlDownload = ENV_COMMON.downloadUrl + this._event.producerToken;

    if(ENV_COMMON.production){
      this.watchEmbeddedCode = '<iframe width="560" height="315"\n' +
      '  src="https://azzulei.tv/embed/' + this._event.viewerToken + '" title="Azzulei TV player"\n' +
      '  allow="accelerometer; autoplay; clipboard-write; encrypted-media;\n' +
      '   gyroscope; picture-in-picture; web-share"\n' +
      '  allowfullscreen>\n' +
      '</iframe>';
    } else {
      this.watchEmbeddedCode = '<iframe width="560" height="315"\n' +
      '  src="https://dev.azzulei.tv/embed/' + this._event.viewerToken + '" title="Azzulei TV player"\n' +
      '  allow="accelerometer; autoplay; clipboard-write; encrypted-media;\n' +
      '   gyroscope; picture-in-picture; web-share"\n' +
      '  allowfullscreen>\n' +
      '</iframe>';
    }


    if (value.status !== undefined) this.handleEventStatus(value.status);
    //console.log('[EventStatusClass] event input: ' + JSON.stringify(value));
    this.updateStatus();
  }

  protected emitEventCreationFinished(value: IEvent): void {
    //must be overridden
  }

  protected emitEventDeleted(value: IEvent): void {
    //must be overridden
  }

  protected emitEventArchived(value: IEvent): void {
    //must be overridden
  }

  protected emitEventFinished(value: IEvent): void {
    //must be overridden
  }

  protected emitEventExpanded(value: boolean): void {
    //must be overridden
  }

  protected emitEventStart(value: IEvent): void {
    //must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    //must be overridden
  }

  protected displayAlert(data: IAlertData): void {
    //must be overridden
  }

  protected displayModalEdit(show: boolean): void {
    //must be overridden
  }

  protected toggleExpanded(): void {
    this._expanded = !this._expanded;
    this.emitEventExpanded(this._expanded);
  }

  protected checkUserChanges(data: IEventUserLastChanges): void {
    //console.log('[EventStatusClass] checkUserChanges');
    if (data.eventList === true || (this._event.id !== undefined && data.events?.includes(this._event.id) === true)){
      this.requestEvent();
    }
  }


  /*protected subscribeEventStatus(val: boolean, interval: number): void {
    if (this.receiveStatusSubscription !== undefined) this.receiveStatusSubscription.unsubscribe();
    if (val) {
      if (this._event.id !== undefined) {
        console.log('[EventStatusClass] refreshStatus start polling');
        this.receiveStatusSubscription = this.eventsService.checkEventStatus(this._event.id, this._event.producerToken ?? '', interval)
          .pipe(
            filter((val: any) => !!val))
          .subscribe((status) => {
            this.handleEventStatus(status)
          });
      }
    }
  }*/

  protected handleEventStatus(status: string | number): void {

    // Temporary just for creation tests
    //this._event.status = EVENTS.status.creating;
    //this._creationProgress = 74;
    //return;

    if (typeof (status) == 'string') {
      if (status.includes('%')) {
        const STATUS_PARTS = status.split(' ');
        this._event.status = STATUS_PARTS[0];
        const PERCENT: number = parseInt(STATUS_PARTS[1].replace('%', ''), 10);

        if (PERCENT) {
          this._creationProgress = PERCENT;
        }
      } else {
        this._event.status = status;
      }
      this.updateStatus();
    } else {
      console.log('[EventStatus] New status number: ' + status);
    }
  }

  protected updateStatus(): void {
    let interval: number;
    if (this.requestEventSubscription !== undefined) this.requestEventSubscription.unsubscribe();
    // Update settings
    if ((this._event.status === EVENTS.status.pending) ||
      (this._event.status === EVENTS.status.creating) ||
      (this._event.status === EVENTS.status.installing)) {
      this._mode = 'creating';
      interval = EventStatusClass.INTERVAL_REFRESH_CREATION;
    } else if (this._event.status === EVENTS.status.running) {
      this._mode = 'active';
      interval = EventStatusClass.INTERVAL_REFRESH_ACTIVE;
    } else if (this._event.status === EVENTS.status.finished) {
      this._mode = 'finished';
      interval = EventStatusClass.INTERVAL_REFRESH_ARCHIVED;
    } else if (this._event.status === EVENTS.status.archived) {
      this._mode = 'archived';
      interval = EventStatusClass.INTERVAL_REFRESH_ARCHIVED;
    } else if (this._event.status === EVENTS.status.expired) {
      this._mode = 'expired';
      interval = EventStatusClass.INTERVAL_REFRESH_ARCHIVED;
    } else {
      this._mode = 'none';
      interval = EventStatusClass.INTERVAL_REFRESH_ACTIVE;
    }

    // User change event instead of polling info
    if ((interval > 0) && this._refreshEvent) {
      this.requestEventSubscription = timer(interval).subscribe(() => this.requestEvent());
    }
  }

  protected requestEvent(): void {
    console.log('[EventStatus] RequestEvent');
    if ((this._event.id !== undefined) && (this._event.producerToken !== undefined)) {
      //this.eventsService.getEventInfo('producer', this._event.producerToken)
      this.eventsService.getEvent(this._event.id)
        .subscribe((eventRefreshed: IEvent | null) => {
          if (eventRefreshed) {
            //console.log('[EventStatusClass] Event refresh: ' + JSON.stringify(eventRefreshed));
            this._event = eventRefreshed;
            this.urlDownload = ENV_COMMON.downloadUrl + this._event.producerToken;
            if (eventRefreshed.status !== undefined) {
              this.handleEventStatus(eventRefreshed.status);
            }
          }
        });
    }
  }

  protected onClickCopy(section: string): void {
    const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, section);
    let url: string = SHARE_DATA.url;
    this.copyToClipboard(url);
  }

  protected setCopyUrls(): void {
    const SHARE_DATA_LINPUT: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, 'publish');
    this.urlLiveInput = SHARE_DATA_LINPUT.url;

    const SHARE_DATA_PROD: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, 'produce');
    this.urlStudio = SHARE_DATA_PROD.url;

    const SHARE_DATA_WATCH: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, 'watch');
    this.urlWatch = SHARE_DATA_WATCH.url;
  }

  protected copyToClipboard(text: string): void {
    //must be overridden
  }


  protected onClickShare(section: string): void {
    const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, section);
    this.shareSrv.shareToken(SHARE_DATA);
  }

  protected onClickStart(): void {
    console.log('[event status - emit start');
    this.emitEventStart(this._event);
  }

  protected onClickProduce(value: boolean): void {
    if (this._event.producerToken !== undefined) {
      if (this._mode === EVENTS.status.creating) {
        this.emitEventCreationFinished(this._event);
      }
      this.goToSection('produce', this._event.producerToken, value);
    }
  }

  protected onClickPublish(): void {
    if (this._event.publisherToken !== undefined) {
      this.goToSection('publish', this._event.publisherToken, true);
    }
  }

  protected onClickWatch(): void {
    if (this._event.viewerToken !== undefined) {
      this.goToSection('watch', this._event.viewerToken, true);
    }
  }

  protected goToSection(section: 'produce' | 'publish' | 'watch', token: string, newWindow: boolean): void {
    // must be overridden
  }

  protected onClickEdit(): void {
    this.requestEvent();
    this.displayModalEdit(true);
  }

  protected onClickFinish(): void {
    if (this._event.id !== undefined) {
      console.log('[EventStatusClass] onClickFinish');

      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'eventStatus.finishEventConfirmation',
        closeButton: true,
        buttons: [{
            text: 'general.cancel',
            color: 'primary',
            fill: 'outline',
            closeButton: true
          },
          {
            text: 'general.confirm',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              if (this._event.id !== undefined) {
                this.eventsService.finishEvent(this._event.id, true, this._event.producerToken)
                  .subscribe((eventFinished: IEvent) => {
                    if (eventFinished) {
                      console.log('[EventStatusClass] Event finished: ' + JSON.stringify(eventFinished));
                      this.requestEvent();
                      this.emitEventFinished(this._event);
                    }
                  });
              }
            }
          }]
      });
    }
  }

  protected onClickDelete(): void {
    if (this._event.id !== undefined) {

      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'eventStatus.deleteEventConfirmation',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
          {
            text: 'general.confirm',
            color: 'danger',
            closeButton: true,
            handler: (): void => {
              if (this._event.id !== undefined) {
                this.eventsService.deleteEvent(this._event)
                  .subscribe({
                    next: (resp: any) => {
                      console.log('[EventStatusClass] Event deleted: ' + JSON.stringify(resp));
                      this.emitEventDeleted(this._event);
                    },
                    error: (err) => {
                      console.log('[EventStatusClass] Event delete ERROR: ' + JSON.stringify(err));
                      this.displayToast({
                        options: {
                          autohide: true,
                          placement: ToastPlacement.bottomRight
                        },
                        data: {
                          closeButtonHeader: true,
                          status: ToastStatus.error,
                          title: 'general.error',
                          text: 'eventStatus.deleteEventError',
                          alignText: 'text-center',
                          buttons: []
                        },
                      });
                    }
                  });
              }
            }
          }]
      });
    }
  }

  protected onClickArchive(): void {
    if ((this._event.id !== undefined)&&(this._event.status === 'finished')) {

      this.updateStorageAvailable();

      if ((this._event.totalSize !== 0)&&
          (this._event.totalSize !== undefined)&&
          (this._event.totalSize < this.storageAvailable)){

        this.displayAlert({
          show: true,
          status: AlertStatus.question,
          title: 'general.confirmation',
          text: 'eventStatus.archiveEventConfirmation',
          closeButton: true,
          buttons: [{
            text: 'general.cancel',
            color: 'primary',
            fill: 'outline',
            closeButton: true,
          },
            {
              text: 'general.confirm',
              color: 'primary',
              closeButton: true,
              handler: (): void => {
                if (this._event.id !== undefined) {
                  this.eventsService.archiveEvent(this._event.id)
                    .subscribe((resp: any) => {
                      console.log('[EventStatusClass] Event archived: ' + JSON.stringify(resp));
                      if (resp === true){
                        this.emitEventArchived(this._event);
                      }
                      else {
                        console.log('[EventStorageClass] Event archive ERROR');
                        this.displayToast({
                          options: {
                            autohide: true,
                            placement: ToastPlacement.bottomRight
                          },
                          data: {
                            closeButtonHeader: true,
                            status: ToastStatus.error,
                            title: 'general.error',
                            text: 'eventStatus.archiveEventError',
                            alignText: 'text-center',
                            buttons: []
                          },
                        });
                      }
                    });
                }
              }
            }]
        });
      }
      else{
        this.displayAlert({
          show: true,
          status: AlertStatus.error,
          title: 'general.error',
          text: 'eventStatus.archiveEventSizeWarning',
          closeButton: true,
          buttons: [{
            text: 'general.close',
            color: 'primary',
            fill: 'outline',
            closeButton: true,
          }]
        });
      }
    }
  }

  protected onClickDownload(): void {
    if (this._event.id !== undefined) {
      console.log('[EventStatusClass] Click Download');

      if (this.downloadBusyTimerSubscription !== undefined) this.downloadBusyTimerSubscription.unsubscribe();
      this.downloadBusy = true;
      this.downloadBusyTimerSubscription = timer(5000).subscribe(() => this.downloadBusy = false);
      this.displayToast({
        options: {
          placement: ToastPlacement.topCenter,
          autohide: true,
        },
        data: {
          status: ToastStatus.success,
          text: 'eventStatus.downloadRequested',
          closeButtonBody: true,
        },
      });
    }
  }

  protected onSettingsChanged(event: IEvent): void {
    this._event = event;
    this.requestEvent();
    //this.displayModalEdit(false);
  }

  protected onClickOpenEmbeddedCode(id: number | undefined, event: IEvent): void {
    //this.displayModalEmbedded = true;
    if(id !== undefined) {
      this.modalService.show(
        {
          id: ModalDialogId.eventEmbed,
          subId: id,
          info: event,
          title: 'eventStatus.watchEmbedded',
        },
        false
      );
    }
  }

  protected getSectionToken(section: string): string {
    switch (section) {
      case 'produce':
        return this._event.producerToken ?? 'aaa-bbb-ccc';
      case 'publish':
        return this._event.publisherToken ?? '';
      default:
      case 'watch':
        return this._event.viewerToken ?? '';
    }
  }

  protected getExpirationInterval(time: number | undefined){
    if ((time === 0)||(time === undefined)){
      return 0;
    }
    else {
      const NOW: number = Date.now();
      const TIME_LEFT: number = ((time * 1000) - (NOW));
      if (TIME_LEFT > 0){
        return TIME_LEFT;
      }
      else{
        return 0;
      }
    }
  }

  protected updateStorageAvailable(): void{
    if ((this.userService.orgId !== 0)&&(this.userService.orgId !== undefined)&&
        (this.userService.organization !== undefined)){
      if ((this.userService.organization.storageTotal !== undefined)&&
          (this.userService.organization.storageUsed !== undefined)){
        // Check units (in KBytes)
        this.storageAvailable = (this.userService.organization.storageTotal - this.userService.organization.storageUsed) * 1024;
      }
      else {
        this.storageAvailable = 0;
      }
    }
    else {
      if ((this.userService.user.storageTotal !== undefined)&&
          (this.userService.user.storageUsed !== undefined)){
        // Check units (in KBytes)
        this.storageAvailable = (this.userService.user.storageTotal - this.userService.user.storageUsed) * 1024;
      }
      else {
        this.storageAvailable = 0;
      }
    }
    console.log('[EventStatusClass] updateStorageAvailable ' + this.storageAvailable);
  }

  protected formatExpirationTimeInterval(time: number | undefined): string {
    let ret: string='';
    console.log('[EventStatusClass] formatExpirationTime ' + time);
    let tmpTime: number = this.getExpirationInterval(time) / 1000; // Convert to seconds
    if ((tmpTime !== undefined)&&(tmpTime > 0)){
      let days: number = 0;
      let hours: number = 0;
      let mins: number = 0;
      let secs: number = 0;

      if (tmpTime > 3600 * 24){   // More than 1 day
        days = Math.floor(tmpTime / (3600 * 24));
        hours = Math.floor( (tmpTime % (3600 * 24)) / 3600);
        ret = days.toFixed() + this.translate.instant('general.shortDays') + ' ' + hours.toFixed() + this.translate.instant('general.shortHours');
      }
      else if (tmpTime > 3600){   // More than 1 hour
        hours = Math.floor(tmpTime / (3600));
        mins = Math.floor( (tmpTime % (3600)) / 60);
        ret = hours.toFixed() + this.translate.instant('general.shortHours') + ' ' + mins.toFixed() + this.translate.instant('general.shortMins');
      }
      else{   // Less than 1 hour
        mins = Math.floor(tmpTime / (60));
        secs = Math.floor(tmpTime % (3600));
        ret = mins.toFixed() + this.translate.instant('general.shortMins') + ' ' + secs.toFixed() + this.translate.instant('general.shortSecs');
      }
    }
    else{
      ret = '0';
    }
    return ret;
  }

  protected formatTotatSize(): string {
    if ((this._event.totalSize !== undefined)){
      let scaledSize: number = this._event.totalSize;
      let units: string[] = [' B',' KB',' MB',' GB'];
      let iterations: number = 0;

      while ((scaledSize > 1024)&&(iterations < units.length-1))
      {
        scaledSize = scaledSize / 1024;
        iterations++;
      }

      return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
    }
    else{
      return '--';
    }
  }
}
